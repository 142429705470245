import React, {useContext, useEffect} from "react"
import HeaderContent from "src/components/header/header-content"
import Icon from "src/components/shared/components/material-icon"
import {useNavigate} from "react-router-dom"
import {ConfigContext} from "src/app"
import {useTranslation} from "react-i18next"

const RideThanks = () => {
  const navigate = useNavigate()
  const {hideMobileMenu, showMobileMenu, isMobile} = useContext(ConfigContext)
  const {t} = useTranslation()
  useEffect(() => {
    hideMobileMenu()
    return () => showMobileMenu()
  }, [hideMobileMenu, showMobileMenu])
  return (
    <>
      <HeaderContent>
        <div className="flex header-content items-center justify-between gap-2 w-full px-4 py-3">
          <div className="text-lg font-semibold md:text-2xl">{t("transfers.action.ride_finished")}</div>
          {isMobile && <Icon icon="close" className="text-2xl" onClick={() => navigate("/rides")} />}
        </div>
      </HeaderContent>

      <div className="-mx-4 -mt-4 md:mx-0 md:mt-0 bg-gray-50">
        <div className="flex flex-col items-center min-h-screen bg-white justify-center p-8">
          <div className="bg-green-100 rounded-full p-2 mb-4">
            <svg width="48" height="48" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
                fill="#52C41A"
              />
              <path
                d="M8.33329 11.3333L13.25 6.41667C13.4027 6.26389 13.5972 6.1875 13.8333 6.1875C14.0694 6.1875 14.2638 6.26389 14.4166 6.41667C14.5694 6.56944 14.6458 6.76389 14.6458 7C14.6458 7.23611 14.5694 7.43056 14.4166 7.58333L8.91663 13.0833C8.74996 13.25 8.55551 13.3333 8.33329 13.3333C8.11107 13.3333 7.91663 13.25 7.74996 13.0833L5.58329 10.9167C5.43051 10.7639 5.35413 10.5694 5.35413 10.3333C5.35413 10.0972 5.43051 9.90278 5.58329 9.75C5.73607 9.59722 5.93051 9.52083 6.16663 9.52083C6.40274 9.52083 6.59718 9.59722 6.74996 9.75L8.33329 11.3333Z"
                fill="white"
              />
            </svg>
          </div>

          <h1 className="text-xl font-bold mb-2 text-center">{t("transfers.action.thank_you")}</h1>
          <p className="text-gray-600 text-center mb-6">{t("transfers.action.order_completed")}</p>

          <button
            className="w-full mt-6 bg-gray-200 text-gray-800 font-medium p-5 rounded-lg hover:bg-gray-300"
            onClick={() => navigate("/rides")}>
            {t("transfers.action.back_to_orders")}
          </button>
        </div>
      </div>
    </>
  )
}

export default RideThanks
