import {Dispatch, SetStateAction, useContext, useEffect, useState} from "react"
import {PopoverOptions} from "src/components/shared/antd-custom"
import {useTranslation} from "react-i18next"
import {ConfigContext} from "src/app"
import {getPrintStyles} from "src/pages/config/documents/config"
import printJS from "print-js"
import documentTemplateService from "src/services/document-template.service"
import {DocumentModel} from "src/models/manager/document"
import {OrderListContext} from "./orders.context"
import loadingService from "../../../services/loading.service"

export interface OutletContextData {
  selection: boolean
  selected: number[]
  setSelected: Dispatch<SetStateAction<number[]>>
}

export default function OrderHeaderComponent() {
  const {t} = useTranslation()
  const {settings, isMobile} = useContext(ConfigContext)
  const {selected, setSelected, selection, setSelection} = useContext(OrderListContext) || {}
  const [templates, setTemplates] = useState<DocumentModel[]>([])

  const onSelectionToggle = () => {
    setSelection((p) => !p)
    setSelected([])
  }

  const onBulkDocumentGeneration = async (template: DocumentModel) => {
    const {documents} = await documentTemplateService.bulkGeneration({
      template: template.id,
      request_ids: selected
    })
    const htmlContent = documents
      .map((doc) => `<div class="ck-content">${doc.content}</div>`)
      .join('<div class="page-break" style="page-break-after:always;"></div>')

    printJS({
      printable: htmlContent,
      type: "raw-html",
      header: "DOCUMENTS",
      style: getPrintStyles(),
      showModal: false,
      onLoadingStart: () => loadingService.setLoading("print"),
      onLoadingEnd: () => loadingService.removeLoading("print"),
      repeatTableHeader: false,
      modalMessage: t("orders.documents.modals.create_document_modal.message")
    })
    onSelectionToggle()
  }

  useEffect(() => {
    const sub = documentTemplateService.documents$.subscribe(setTemplates)
    return () => sub.unsubscribe()
  }, [])

  return (
    settings && (
      <div className="flex justify-between items-center py-3 mx-4 h-16 border-b border-gray-100 gap-1">
        <div className="font-semibold text-[22px] text-test-black">{t("aside.menu.orders")}</div>
        {!isMobile && (
          <div className="flex gap-2 items-center">
            {selection ? (
              <>
                <PopoverOptions
                  overlayInnerStyle={{padding: 0}}
                  trigger="click"
                  placement="bottomRight"
                  options={templates.map((template) => ({
                    key: String(template.id),
                    title: template.name,
                    icon: "description",
                    disabled: selected.length === 0,
                    onClick: () => onBulkDocumentGeneration(template)
                  }))}>
                  <div className="flex items-center gap-2 cursor-pointer bg-white rounded-full shadow-menu py-1 p-3 border-2 border-primary">
                    <span className="font-bold text-xl text-primary">{selected.length}</span>
                    <span className="font-medium text-black">Сгенерировать документ</span>
                  </div>
                </PopoverOptions>
                <div className="btn btn-card" onClick={onSelectionToggle}>
                  {t("common.cancel")}
                </div>
              </>
            ) : (
              <div
                className={`cursor-pointer bg-white rounded-lg shadow-menu p-3 border ${selection ? "border-primary" : "border-gray-100"}`}
                onClick={onSelectionToggle}>
                Создать список документов
              </div>
            )}
          </div>
        )}
      </div>
    )
  )
}
