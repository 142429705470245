import React from "react"
import Icon from "../../../../components/shared/components/material-icon"
import {useTranslation} from "react-i18next"

interface RideLinkProps {
  name?: string
  link?: string
}

const RideLink: React.FC<RideLinkProps> = ({name, link}) => {
  const href = name ? `https://2gis.kz/search/${encodeURIComponent(name)}` : link || "https://2gis.kz/geo/"
  const {t} = useTranslation()
  return (
    <div className="flex gap-2">
      <a href={href} className="text-primary font-medium flex gap-1 items-center" target="_blank" rel="noreferrer">
        <Icon icon="open_in_new" />
        {name || t("transfers.action.open_link")}
      </a>
    </div>
  )
}

export default RideLink
