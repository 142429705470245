import React from "react"
import Icon from "src/components/shared/components/material-icon"
import {useNavigate} from "react-router-dom"
import {useTranslation} from "react-i18next"

const TransferAddressCard = ({addresses}) => {
  const navigate = useNavigate()
  const {t} = useTranslation()
  const truncateLink = (link: string, maxLength: number = 25) => {
    return link.length > maxLength ? `${link.slice(0, maxLength)}...` : link
  }

  return (
    <div className="m-auto max-w-[720px] flex-col flex gap-0.5">
      {addresses &&
        addresses.map((address, index) => (
          <div
            key={index}
            className="flex justify-between items-center gap-3 bg-white flex-1 p-4 md:first:rounded-t-lg md:[&:nth-last-child(2)]:rounded-b-lg"
            onClick={() => navigate(`address/${address.id}`)}>
            <div className="flex flex-col gap-1">
              <div className="font-medium">{address.name}</div>
              <div className="flex gap-2">
                {address.link ? (
                  <a
                    href={address?.link}
                    className="text-primary font-medium flex gap-1 items-center"
                    target="_blank"
                    rel="noreferrer">
                    <Icon icon="link" />
                    {truncateLink(address?.link)}
                  </a>
                ) : (
                  <span className="text-sm text-gray-test-370">{t("transfers.action.no_link_to_map")}</span>
                )}
              </div>
            </div>
            <div>
              <Icon icon="chevron_right" />
            </div>
          </div>
        ))}
    </div>
  )
}

export default TransferAddressCard
