import React from "react"
import {FormProvider} from "react-hook-form"
import FormSelectAsyncPagination from "src/components/shared/inputs/form-select-async.pagination"
import {ClientModel} from "src/models/manager/clients/client.model"
import clientService from "src/services/clients.service"
import {OptionProps, GroupBase} from "react-select"
import {SelectOption} from "src/components/shared/inputs/select/common"
import {useTranslation} from "react-i18next"
import {FormControlDaterange} from "../../../../components/shared/inputs/form-control-daterange"

function ClientSelectOption(props: OptionProps<ClientModel, boolean, GroupBase<ClientModel>>) {
  return (
    <SelectOption {...props}>
      <div className="flex justify-between gap-2 items-center w-full">
        <span className="flex-1">{props.data.name}</span>
        <span
          style={{
            backgroundColor: "var(--color-primary)",
            color: "var(--color-white)",
            borderRadius: 4,
            padding: 6
          }}>
          {props.data.phone}
        </span>
      </div>
    </SelectOption>
  )
}

const TransferMainFilterModal = ({form}: {form: any}) => {
  const {t} = useTranslation()

  const getClient = async (id: number) => {
    const client = await clientService.get(+id)
    return client
  }

  return (
    <FormProvider {...form}>
      <div className="flex gap-6 flex-col">
        <div>
          <FormSelectAsyncPagination<ClientModel>
            name="client"
            label={t("transfers.main.client")}
            listOptions={(params) => clientService.list({...params, skip_loader: true})}
            getValue={getClient}
            filterOption={null}
            isSearchable={true}
            isClearable={true}
            getOptionLabel={(val: ClientModel) => (val ? String(val.name) : null)}
            getOptionValue={(val: ClientModel) => (val ? String(val.id) : null)}
            components={{Option: ClientSelectOption}}
            placeholder={t("common.select.placeholder.client")}
            className="flex-1"
          />
        </div>
        <div className="flex flex-col gap-3">
          <FormControlDaterange
            label={t("transfers.form.date")}
            className="col form-control"
            name_start="start_at__gt"
            name_end="start_at__lte"
          />
        </div>
      </div>
    </FormProvider>
  )
}

export default TransferMainFilterModal
