import printJS from "print-js"
import {SyntheticEvent, useEffect, useState} from "react"
import {GroupBase, OptionProps, components} from "react-select"
import {FormProvider, useForm} from "react-hook-form"
import FormSelectAsync from "src/components/shared/inputs/form-select-async.list"
import {DocumentModel} from "src/models/manager/document"
import {OrderRequestListModel} from "src/models/manager/order/order-request-list.model"
import documentTemplateService from "src/services/document-template.service"
import {getPrintStyles} from "../../config/documents/config"
import {colourStyles} from "src/components/shared/inputs/form-select-color-style"
import Icon from "src/components/shared/components/material-icon"
import {useTranslation} from "react-i18next"
import pdfService from "src/services/pdf.service"
import orderDocumentService from "src/services/order-requests/order-document.service"
import modalService from "src/components/modal/global/modal.service"
import {Link} from "react-router-dom"
import {OrderRequestDocumentModel} from "src/models/manager/order/order-request-document"
import {PopoverOptions} from "src/components/shared/antd-custom"
import i18n from "src/i18n"
import loadingService from "../../../services/loading.service"

const onWebPrint = (doc: OrderRequestDocumentModel) => {
  const htmlContent = `<div class="ck-content">${doc.content}</div>`
  document.title = doc.name

  printJS({
    printable: htmlContent,
    type: "raw-html",
    header: doc.name,
    style: getPrintStyles(),
    showModal: false,
    onLoadingStart: () => loadingService.setLoading("print"),
    onLoadingEnd: () => loadingService.removeLoading("print"),
    repeatTableHeader: false,
    modalMessage: i18n.t("configuration.document.modals.print_modal.message"),
    onError: (e) => console.log(e)
  })
}

function DocumentSelect({order, template}: {order: OrderRequestListModel; template: DocumentModel}) {
  const onDownload = (event: SyntheticEvent) => {
    event.stopPropagation()
    orderDocumentService.post(order.id, {template: template.id, context: {}}).then((doc) => {
      pdfService.generate(doc.name, doc.content)
    })
  }

  const onView = async (event: SyntheticEvent) => {
    event.stopPropagation()
    orderDocumentService.post(order.id, {template: template.id, context: {}}).then((doc) => {
      modalService.open({
        props: {
          size: "lg",
          scrollable: true,
          dialogClassName: "ck-content-modal"
        },
        component: (
          <div className="flex flex-col gap-3 justify-center">
            <div className="flex gap-3 items-center justify-center">
              <span className="text-xl text-center font-bold">{doc.name}</span>
              <div className="flex gap-2 items-center">
                <Link to={`/documents/${template.id}/entity/${doc.id}`} className="btn btn-card">
                  <Icon icon="edit" />
                </Link>
                <button onClick={() => onWebPrint(doc)} className="btn btn-card">
                  <Icon icon="print" />
                </button>
                <button onClick={() => pdfService.generate(doc.name, doc.content)} className="btn btn-card">
                  <Icon icon="download" />
                </button>
              </div>
            </div>
            <div
              className="ck-view mx-auto overflow-auto"
              style={{maxWidth: "100%"}}
              dangerouslySetInnerHTML={{__html: doc.content}}
            />
          </div>
        )
      })
    })
  }

  return (
    <div className="w-full flex items-center gap-2">
      <Icon className="text-[21px]" icon="description" />
      <span className="flex-1 text-start">{template.name}</span>
      <div className="flex gap-2">
        <button onClick={(e) => onDownload(e)} className="btn btn-card">
          <Icon className="text-base" icon="download" />
        </button>
        <button onClick={(e) => onView(e)} className="btn btn-card">
          <Icon className="text-base" icon="visibility" />
        </button>
      </div>
    </div>
  )
}

function DocumentSelectOption(
  props: OptionProps<DocumentModel, boolean, GroupBase<DocumentModel>> & {
    order: OrderRequestListModel
  }
) {
  const onPrint = (event: SyntheticEvent, template: DocumentModel) => {
    event.stopPropagation()
    orderDocumentService.post(props.order.id, {template: template.id, context: {}}).then(onWebPrint)
  }

  const config = {
    ...props,
    innerProps: {
      ...props.innerProps,
      onClick: (e: SyntheticEvent) => onPrint(e, props.data)
    }
  }

  return (
    <components.Option {...config}>
      <DocumentSelect order={props.order} template={props.data} />
    </components.Option>
  )
}

export function OrderCardDocument({order, view = "grid"}: {order: OrderRequestListModel; view?: "table" | "grid"}) {
  const form = useForm<{document: number}>()
  const [templates, setTemplates] = useState<DocumentModel[]>([])
  const {t} = useTranslation()

  useEffect(() => {
    if (view === "table") {
      const sub = documentTemplateService.documents$.subscribe(setTemplates)
      return () => sub.unsubscribe()
    }
  }, [view])

  return view === "table" ? (
    <PopoverOptions
      overlayInnerStyle={{padding: 0}}
      trigger="click"
      placement="bottomRight"
      options={templates.map((template) => ({
        key: String(template.id),
        title: <DocumentSelect order={order} template={template} />
      }))}>
      <Icon icon="description" className="h-full p-3" />
    </PopoverOptions>
  ) : (
    <FormProvider {...form}>
      <div className="order-card-content" onClick={(e) => e.stopPropagation()}>
        <FormSelectAsync<DocumentModel>
          className="col"
          placeholder={t("orders.list.document")}
          name="discount"
          listOptions={() => documentTemplateService.documents$}
          getOptionLabel={(option: DocumentModel) => (option ? option.name : null)}
          getOptionValue={(option: DocumentModel) => (option ? String(option.id) : null)}
          // menuPortalTarget={document.getElementById('vhContent')}
          styles={colourStyles(false)}
          components={{
            Option: (props: OptionProps<DocumentModel, boolean, GroupBase<DocumentModel>>) =>
              DocumentSelectOption({...props, order})
          }}
          isSearchable={false}
          isClearable={false}
          menuPlacement="top"
        />
      </div>
    </FormProvider>
  )
}
