import {Link, useLocation} from "react-router-dom"
import Icon from "src/components/shared/components/material-icon"
import {useContext} from "react"
import {ConfigContext} from "src/app"
import {use100vh} from "react-div-100vh"
import "./styles.scss"

interface SidebarMenu {
  link: string
  label: string
  icon?: string
  className?: string
  disabled?: boolean
}

interface Sidebar {
  header?: JSX.Element
  menus: SidebarMenu[]
}

export function SidebarWrapper({
  options,
  children,
  width = 200
}: {
  options: Sidebar[]
  children: JSX.Element | JSX.Element[] | string
  width?: number
}) {
  const location = useLocation()
  const {isMobile, headerHeight} = useContext(ConfigContext)
  const height = use100vh() - headerHeight

  return (
    <div className="sidebar-wrapper" style={isMobile ? {minHeight: height} : {maxHeight: height, minHeight: height}}>
      <div className="hidden md:flex sidebar-wrapper-menu" style={{width}}>
        {options.map((option, optionIndex) => (
          <div key={optionIndex}>
            {option.header}
            {option.menus.map((menu, index) => (
              <Link
                key={`${optionIndex}-${menu}/${index}`}
                to={menu.link}
                className={`item ${menu.className || "mb-2"} ${location.pathname.startsWith(menu.link) ? "active" : ""}`}>
                {menu.icon && <Icon className="item-icon" icon={menu.icon} />}
                <div className="item-name">{menu.label}</div>
              </Link>
            ))}
          </div>
        ))}
      </div>

      <div className="sidebar-wrapper-content pb-24 md:pb-4">{children}</div>
    </div>
  )
}
