import {LabelModel} from "src/models/label"
import {useContext} from "react"
import {ConfigContext} from "src/app"
import FormSelect, {FormSelectConfig} from "../../inputs/form-select"
import {useFormContext} from "react-hook-form"

type RemovalKeys = "getOptionLabel" | "getOptionValue" | "options"

export function SharedClientTypeSelect(config: Omit<FormSelectConfig<LabelModel>, RemovalKeys>) {
  const {constants, constantsMap} = useContext(ConfigContext)
  const form = useFormContext()
  const value = form.watch(config.name)

  return (
    <FormSelect<LabelModel>
      {...config}
      options={constants.CLIENT_TYPE}
      value={constantsMap.CLIENT_TYPE[Number(value)]}
      getOptionLabel={(option: LabelModel) => (option ? String(option.label) : null)}
      getOptionValue={(option: LabelModel) => (option ? String(option.id) : null)}
    />
  )
}
