export const getOrderStatus = (status: string) => {
  // const {t} = useTranslation()
  switch (status) {
    case "request":
      return {
        text: "transfers.statuses.request",
        bg: "#1890FF"
      }
    // case "in_ride":
    //   return {
    //     text: t("transfers.statuses.in_ride"),
    //     bg: colors.warn
    //   }
    case "inprocess":
      return {
        text: "transfers.statuses.in_work",
        bg: "#52c41a"
      }
    case "completed":
      return {
        text: "transfers.statuses.finished",
        bg: "#a165fd"
      }
    default:
      return {
        text: "Undefined",
        bg: "#b8b8b8"
      }
  }
}
