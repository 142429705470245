import React, {useContext, useEffect} from "react"
import HeaderContent from "src/components/header/header-content"
import Icon from "src/components/shared/components/material-icon"
import {useNavigate} from "react-router-dom"
import {ConfigContext} from "src/app"
import {FormControl} from "src/components/shared/inputs/form-control"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import driverService from "src/services/driver.service"
import {MultiImageInputField} from "src/components/shared/components/multi-image-field"
import {FormControlDatetime} from "src/components/shared/inputs/form-control-datetime"
import {useParams} from "react-router"
import moment from "moment"
import modalService from "src/components/modal/global/modal.service"
import {GroupType} from "src/services/auth.service"
import {SharedExpenseCategorySelect} from "src/components/shared/components/select/expense_category"
import {ConfirmModal} from "../../../../../components/modal/global/confirmModal"

const ExpenseForm = () => {
  const navigate = useNavigate()
  const {hideMobileMenu, showMobileMenu, isMobile, settings} = useContext(ConfigContext)
  const form = useForm()
  const {t} = useTranslation()
  const {driverId, expenseId} = useParams()
  const {formState, watch, handleSubmit, reset, setError} = form
  const values = watch()
  const {profile} = useContext(ConfigContext)
  const isAdmin = profile && profile.group_type === GroupType.ADMIN

  useEffect(() => {
    hideMobileMenu()
    return () => showMobileMenu()
  }, [hideMobileMenu, showMobileMenu])

  const onSubmit = async () => {
    const payload = {...values}
    if (!payload.media || payload.media.length === 0) {
      setError("media", {type: "required", message: "Media is required"})
      return
    }

    const formData = new FormData()
    Object.entries(payload).forEach(([key, value]) => {
      if (key !== "media") {
        formData.append(key, value)
      }
    })
    payload.media.forEach((file: File, index: number) => {
      formData.append(`media[${index}]`, file)
    })

    await driverService.postExpense(formData)
    navigate(-1)
  }

  const onDelete = () => {
    const onConfirm = () => driverService.deleteExpense(+expenseId).then(() => navigate(-1))

    modalService.open({
      component: (
        <ConfirmModal
          message={"Do you want to delete expense?"}
          confirm_text={"Yes, delete"}
          onConfirm={onConfirm}
          delete={true}
        />
      )
    })
  }

  useEffect(() => {
    if (!expenseId) return
    const expenseDetail = driverId
      ? driverService.getExpenseDetail(+driverId, +expenseId)
      : driverService.getExpenseID(+expenseId)

    expenseDetail.then((data) => {
      const startAtMoment = moment(data.expense_date)
      const expense_date = startAtMoment.format("YYYY-MM-DDTHH:mm")
      const formattedMedia = Array.isArray(data.medias)
        ? data.medias.map((item) => (typeof item === "string" ? item : ""))
        : []
      reset({
        ...data,
        expense_date: expense_date,
        media: formattedMedia
      })
    })
  }, [expenseId, reset])

  return (
    <>
      <HeaderContent>
        <div className="flex header-content p-0">
          <div className="flex items-center justify-normal md:justify-between gap-2 w-full px-4 py-3">
            {isMobile && <Icon icon="chevron_left" className="text-2xl" onClick={() => navigate(-1)} />}
            <div className="text-lg font-semibold md:text-2xl">{t("transfers.main.expenses")}</div>
          </div>
        </div>
      </HeaderContent>

      <FormProvider {...form}>
        <div className="-mx-4 -mt-4 md:mx-0 md:mt-0 gap-0.5">
          <div className="m-auto max-w-[720px] bg-white rounded-none md:rounded-lg flex flex-col gap-3 md:shadow flex-1 p-5">
            <FormControl
              name="name"
              type="text"
              label={t("transfers.form.name")}
              rootclassname="flex-1"
              className="form-control"
              placeholder={t("transfers.form.enter_name")}
              disabled={!!expenseId}
              required
              params={{required: t("transfers.form.enter_count_of_people")}}
            />
            <SharedExpenseCategorySelect isClearable={true} label={t("transfers.form.category")} />

            <FormControl
              name="amount"
              type="number"
              label={t("transfers.form.price")}
              rootclassname="flex-1"
              className="form-control form-control-number"
              number_only={+true}
              number_decimal={+false}
              suffix={settings && settings.currency}
              placeholder={t("transfers.form.enter_price")}
              required
              params={{required: t("transfers.form.enter_price")}}
              disabled={!!expenseId}
            />

            <MultiImageInputField
              label={t("common.upload_photo")}
              required
              name="media"
              style={{objectFit: "cover", width: "100%", height: 200, borderRadius: 8}}
              containerStyle={{width: "100%", borderRadius: 8, border: "1px dashed #ccc"}}
              onEdit={!!expenseId}
            />

            <FormControlDatetime
              name="expense_date"
              rootclassname="flex-1"
              label={t("transfers.form.date")}
              className="form-control"
              datetimeConfig={{
                minuteStep: 1,
                showTime: {format: "HH:mm"},
                allowClear: false
              }}
              disabled={!!expenseId}
              required
              params={{required: t("transfers.form.departure_date_and_time")}}
            />
            {expenseId && !isAdmin && (
              <button
                className="btn bg-red-200 text-alert focus:text-alert hover:text-alert text-base font-medium leading-5 py-4"
                onClick={() => onDelete()}>
                <Icon icon="delete" />
                {t("common.delete")}
              </button>
            )}
            {isMobile && !expenseId && (
              <div className="fixed left-0 bottom-0 right-0 flex items-center w-full shadow-menu bg-white min-h-[72px] z-[100]">
                <div className="flex gap-1 flex-col w-full px-4 py-2 md:px-0 md:py-0">
                  <button
                    className="btn btn-primary btn-color-white text-base font-medium leading-5 py-4"
                    onClick={handleSubmit(onSubmit)}
                    disabled={!formState.isDirty}>
                    {t("common.save")}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </FormProvider>
    </>
  )
}

export default ExpenseForm
