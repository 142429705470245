import CurrentTariffCard from "./current-tariff-card"
import TransactionHistory from "./transaction-history"
import {useContext} from "react"
import {ConfigContext} from "src/app"

export default function MyTariff() {
  const {tenant} = useContext(ConfigContext)

  return (
    tenant &&
    tenant.subscription && (
      <div className="w-full flex flex-col gap-3">
        <CurrentTariffCard />
        <TransactionHistory />
      </div>
    )
  )
}
