import React, {useContext} from "react"
import HeaderContent from "src/components/header/header-content"
import DriverForm from "../../transportation/pages/drivers/detail/driver-form"
import Icon from "src/components/shared/components/material-icon"
import {ConfigContext} from "src/app"
import {useNavigate} from "react-router-dom"
import {DriverContext} from "./driver-context"
import {useTranslation} from "react-i18next"

const DriverEditComponent = () => {
  const {isMobile} = useContext(ConfigContext)
  const {driver} = useContext(DriverContext)
  const {t} = useTranslation()
  const navigate = useNavigate()

  return (
    <>
      <HeaderContent>
        <div className="flex items-center justify-between gap-2 w-full px-4 py-3">
          <div className="flex gap-2 items-center">
            {isMobile && <Icon icon="chevron_left" className="text-2xl" onClick={() => navigate(-1)} />}
            {driver && (
              <div className="text-lg font-semibold md:text-2xl">
                {t("common.edit")} - ({driver.first_name} {driver.last_name})
              </div>
            )}
          </div>
        </div>
      </HeaderContent>
      <DriverForm is_put />
    </>
  )
}

export default DriverEditComponent
