import {lazy} from "react"
import {Navigate, RouteObject} from "react-router-dom"
import CustomSuspense from "src/components/custom-suspense"

import {IntegrationComponent} from "src/pages/integration"
import {SmsIntegrationComponent} from "src/pages/integration/sms"
import {WazzupFrameComponent, WazzupLandingComponent} from "src/pages/integration/wazzup"
import {EgovIntegrationLanding} from "src/pages/integration/egov"
import {GPSIntegrationLanding} from "src/pages/integration/gps"
import SchedulesListComponent from "../pages/schedule"
import {ProtectedRoute} from "./guards/protected-route"

const WidgetWrapperComponent = lazy(() => import("src/pages/widget/products/wrapper"))
const WidgetCategoryList = lazy(() => import("src/pages/widget/categories"))
const ProductPublishedList = lazy(() => import("src/pages/widget/products/product-list"))
const WebsiteArticles = lazy(() => import("src/pages/widget/artices/list"))
const WebsiteEvents = lazy(() => import("src/pages/widget/events/list"))

const integrationRoutes: RouteObject[] = [
  {
    path: "integrations",
    element: <ProtectedRoute requiredAccessKey="integrations" element={<IntegrationComponent />} />
  },
  {
    path: "integrations/sms",
    element: <ProtectedRoute requiredAccessKey="integrations.sms" element={<SmsIntegrationComponent />} />
  },
  {
    path: "integrations/gps",
    element: <ProtectedRoute requiredAccessKey="integrations.gps" element={<GPSIntegrationLanding />} />
  },
  {
    path: "integrations/wazzup",
    element: <ProtectedRoute requiredAccessKey="integrations.wazzup" element={<WazzupLandingComponent />} />
  },
  {
    path: "integrations/egov",
    element: <ProtectedRoute requiredAccessKey="integrations.egov" element={<EgovIntegrationLanding />} />
  },
  {
    path: "integrations/widget",
    element: (
      <ProtectedRoute
        requiredAccessKey="integrations.widget"
        element={
          <CustomSuspense>
            <WidgetWrapperComponent />
          </CustomSuspense>
        }
      />
    ),
    children: [
      {path: "", element: <Navigate to="products" replace={false} />},
      {
        path: "products",
        element: (
          <CustomSuspense>
            <ProductPublishedList />
          </CustomSuspense>
        )
      },
      {
        path: "categories",
        element: (
          <CustomSuspense>
            <WidgetCategoryList />
          </CustomSuspense>
        )
      },
      {
        path: "articles",
        element: (
          <CustomSuspense>
            <WebsiteArticles />
          </CustomSuspense>
        )
      },
      {
        path: "events",
        element: (
          <CustomSuspense>
            <WebsiteEvents />
          </CustomSuspense>
        )
      }
    ]
  },
  {path: "integrations/*", element: <>not found</>},
  {path: "wazzup", element: <WazzupFrameComponent />}
]

export default integrationRoutes
