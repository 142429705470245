import {FormProvider, useForm} from "react-hook-form"
import React, {useContext, useEffect, useRef, useState} from "react"
import {EMPTY_LIST, ListModel} from "src/models/common"
import {useCurrentRoute} from "src/hooks/useCurrentRoute"
import {isNil} from "src/utils/isNil"
import useOnScreen from "src/hooks/useOnScreen"
import ridesService from "src/services/rides.service"
import RideCard from "./detail/ride-card"
import {TransferRideModel} from "src/models/manager/transfer/transfer"
import {FormControl} from "src/components/shared/inputs/form-control"
import Icon from "src/components/shared/components/material-icon"
import {FormControlDaterange} from "src/components/shared/inputs/form-control-daterange"
import {useTranslation} from "react-i18next"
import {ConfigContext} from "src/app"
import RideListTableComponent from "./detail/ride-table-component"
import {createPortal} from "react-dom"
import transportationService from "src/services/transportation.service"
import {useNavigate} from "react-router-dom"

export default function RidesFilter(props: {request: string}) {
  const {request} = props
  const currentRoute = useCurrentRoute()
  const form = useForm({
    defaultValues: {
      page: 1,
      pageSize: 10,
      status: request
    }
  })
  const elementRef = useRef<HTMLDivElement>()
  const isOnScreen = useOnScreen(elementRef)
  const {isMobile} = useContext(ConfigContext)
  const {t} = useTranslation()
  const {reset, watch} = form
  const navigate = useNavigate()
  const values = watch()
  const [list, setList] = useState<ListModel<TransferRideModel>>(EMPTY_LIST)

  const onLoad = async (params: any): Promise<void> => {
    const response = await ridesService.list(params)
    setList((prev) => ({
      ...response,
      results: params?.page === 1 ? response.results : [...prev.results, ...response.results]
    }))
  }

  const createTransfer = async () => {
    const response = await transportationService.post({})
    if (response?.id) {
      navigate(`/transportation/${response.id}`)
    }
  }

  useEffect(() => {
    onLoad({...values, status: isNil(request) ? null : request})
    return () => setList(EMPTY_LIST)
  }, [currentRoute])

  useEffect(() => {
    if (isOnScreen && list.next !== false && !!list.next) {
      reset({...values, page: values.page + 1})
    }
  }, [isOnScreen])

  useEffect(() => {
    reset({...values, page: 1})
  }, [])

  return (
    <FormProvider {...form}>
      <div className="-mx-4 -mt-4 md:mx-0 md:mt-0">
        {isMobile ? (
          <div className="flex gap-2 justify-between mb-3 p-2 border-b border-gray-100 shadow bg-white">
            <FormControl
              icon="search"
              rootclassname="w-full"
              className="form-control"
              name="search"
              placeholder={t("common.input.search")}
            />
            <div
              className="min-h-12 min-w-12 rounded-lg border border-gray-200 flex justify-center items-center"
              onClick={() => {}}>
              <Icon icon="filter_list" className="h-5 w-6 text-2xl" />
            </div>
          </div>
        ) : (
          <div className="flex gap-2 justify-between">
            <div className="flex gap-2 mb-3 ">
              <FormControl
                icon="search"
                rootclassname="col-4"
                className="form-control"
                name="search"
                placeholder={t("common.input.search")}
              />
              <FormControlDaterange className="col form-control" name_start="start_at__gt" name_end="start_at__lte" />
            </div>
          </div>
        )}
      </div>

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
        {isMobile && list.results.map((ride) => <RideCard key={ride.id} ride={ride} />)}
        <div ref={elementRef}></div>
      </div>

      {!isMobile && <RideListTableComponent list={list} />}

      {isMobile &&
        createPortal(
          <button
            className="fixed z-[100]  bottom-20 md:bottom-10 right-4 h-14 w-14 rounded-full bg-primary border border-white border-opacity-20 shadow-[0_0_16px_0_rgba(0, 0, 0, 0.16)]"
            style={{padding: 10, overflow: "hidden", width: "100%"}}
            onClick={() => createTransfer()}>
            <Icon icon="add" className={"text-white text-2xl"} />
          </button>,
          document.body
        )}
    </FormProvider>
  )
}
