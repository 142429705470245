import {useContext, useEffect, useState} from "react"
import authService, {ProfileModel} from "src/services/auth.service"
import Icon from "src/components/shared/components/material-icon"
import {Tooltip} from "antd"
import {useTranslation} from "react-i18next"
import "./aside.scss"
import {ConfigContext} from "src/app"

export function CurrentRentalPoint({collapse}: {collapse: boolean}) {
  const {point} = useContext(ConfigContext)
  const [profile, setProfile] = useState<ProfileModel>()
  const {t} = useTranslation()

  useEffect(() => {
    const sub = authService.profile$.subscribe(setProfile)
    return () => sub.unsubscribe()
  }, [])

  const content = (
    <div className="menu-profile mb-2">
      <div className="menu-profile-icon">
        <Icon icon="location_on" className="text-base color-white" />
      </div>
      <div className="menu-profile-name">{point ? <>{point.name}</> : <>{t("aside.menu.no_rental_point")}</>}</div>
    </div>
  )

  return (
    profile &&
    !profile.is_superuser &&
    (collapse ? (
      <Tooltip placement="right" title={point ? point.name : t("aside.menu.no_rental_point")}>
        {content}
      </Tooltip>
    ) : (
      content
    ))
  )
}
