import {PropsWithChildren, useContext} from "react"
import {SharedRentalPointSelect} from "src/components/shared/components/select/rental-point"
import {StylesConfig} from "react-select"
import {ConfigContext} from "src/app"
import {colourStyles} from "src/components/shared/inputs/form-select-color-style"

export function AsyncRentalPoint({
  name,
  label,
  params,
  placeholder,
  isSearchable,
  styles = colourStyles(false),
  className
}: PropsWithChildren<{
  name: string
  label?: string
  params?: any
  placeholder?: string
  isSearchable?: boolean
  styles?: StylesConfig
  className?: string
}>): JSX.Element {
  const {profile} = useContext(ConfigContext)
  if (!profile) return
  if (!profile.is_superuser) return

  return (
    profile &&
    profile.is_superuser && (
      <SharedRentalPointSelect
        className={className ? className : "flex-1"}
        label={label}
        name={name}
        isSearchable={isSearchable || true}
        isClearable={true}
        params={params}
        placeholder={placeholder}
        styles={styles}
      />
    )
  )
}
