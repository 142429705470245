import {CSSProperties, forwardRef, useContext, useEffect, useMemo, useRef, useState} from "react"
import {useLocation, useNavigate} from "react-router-dom"
import {Aside} from "./components/aside/aside"
import Header from "./components/header"
import HeaderPeriodBanner from "./components/header-banner"
import {Spinner} from "./components/shared/spinner/spinner"
import authService from "./services/auth.service"
import loadingService from "./services/loading.service"
import TourWrapper from "./components/onboarding"
import clsx from "clsx"
import {ConfigContext} from "src/app"
import {MobileMenu} from "./components/mobile-menu/mobile-menu"
import {use100vh} from "react-div-100vh"
import {newSubsription, unsubsribe} from "./services/cent"
import {hasNotification} from "./utils/detectNotificationAccess"
import "./App.scss"
import moment from "moment"

const noPaddingRoutes = ["/profile", "/config", "/settings", "/documents", "/geolocation", "/wazzup"]

const Children = forwardRef(({children}: {children: JSX.Element}, ref) => {
  const scrollToRef = (_ref: any) => _ref.current?.scroll({top: 0, behavior: "smooth"})
  const location = useLocation()
  const height = use100vh()
  const {isMobile, headerHeight} = useContext(ConfigContext)
  const contentRef = useRef(null)
  const noPadding = useMemo(
    () => noPaddingRoutes.some((link) => location.pathname.startsWith(link)),
    [location.pathname]
  )
  const scroll = () => scrollToRef(contentRef)

  const style = useMemo(() => {
    let ret: CSSProperties = {padding: 24, paddingBottom: 80}
    if (isMobile) ret = {padding: 16, minHeight: height, paddingTop: headerHeight + 16, paddingBottom: 160}
    if (!isMobile) ret = {...ret, height: height - headerHeight, paddingBottom: 80}
    if (noPadding) ret = {...ret, padding: 0, paddingBottom: 0}
    return ret
  }, [height, location, isMobile, headerHeight])

  useEffect(() => scroll(), [location.pathname])

  return (
    <div ref={contentRef} className={clsx("relative w-full bg-dark-light overflow-x-scroll")} style={style}>
      {children}
    </div>
  )
})

export default function Main({children}: {children: JSX.Element}) {
  const navigate = useNavigate()
  const {constants, isMobile, tenant} = useContext(ConfigContext)
  const [loading, setLoading] = useState<string[]>([])
  const [smallMenu, setSmallMenu] = useState(() => {
    if (localStorage.getItem("aside-menu")) return localStorage.getItem("aside-menu") === "true"
    return false
  })

  const onAside = () =>
    setSmallMenu((p) => {
      localStorage.setItem("aside-menu", String(!p))
      return !p
    })

  useEffect(() => {
    const loadingSub = loadingService.loading$.subscribe(setLoading)
    return () => loadingSub.unsubscribe()
  }, [])

  useEffect(() => {
    const sub = authService.logged$.subscribe((logged) => {
      if (!logged) navigate("/auth")
    })
    return () => sub.unsubscribe()
  }, [navigate])

  useEffect(() => {
    if (!tenant) return
    const timeLeft = moment.duration(moment(tenant.end_at).valueOf() - moment.now(), "milliseconds").as("seconds")
    if (timeLeft < 0) return

    if (hasNotification() && Notification.permission === "default") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Разрешение на уведомления предоставлено.")
        } else if (permission === "denied") {
          console.log("Разрешение на уведомления отклонено.")
        }
      })
    }

    newSubsription(`notification:${tenant.id}`)
    return () => unsubsribe(`notification:${tenant.id}`)
  }, [tenant])

  return (
    <>
      {isMobile ? (
        <Header
          id="vhContent"
          className="relative right-side w-full overflow-y-scroll"
          topchildren={<HeaderPeriodBanner />}>
          <>
            <Children>{children}</Children>
            <MobileMenu />
          </>
        </Header>
      ) : (
        <div
          id="vhContent"
          className={clsx("wrapper big-menu overflow-y-scroll md:overflow-auto", smallMenu && "small-menu")}>
          <Aside collapse={smallMenu} onCollapse={onAside} />
          <div className="relative wrapper-content md:overflow-y-scroll h-screen">
            <Header className="relative right-side" topchildren={<HeaderPeriodBanner />}>
              <Children>{children}</Children>
            </Header>
            <TourWrapper />
          </div>
        </div>
      )}

      {loading.length > 0 && <Spinner className="fixed w-screen h-screen z-[100000] top-0 left-0" />}
    </>
  )
}
