import {useTranslation} from "react-i18next"
import {useContext, useEffect, useMemo, useState} from "react"
import {colourStyles} from "../../inputs/form-select-color-style"
import {useFormContext} from "react-hook-form"
import CreatableSelect from "react-select/creatable"
import expenseService from "../../../../services/expense.service"
import CustomSelect from "../../inputs/select/select"
import {ConfigContext} from "src/app"

interface OptionType {
  value: string | number
  label: string
}

export function SharedExpenseCategorySelect(config) {
  const {t} = useTranslation()
  const [options, setOptions] = useState<OptionType[]>([])
  const {watch, reset} = useFormContext()
  const values = watch()
  const category = watch("category")
  const {label, createable = true, ...rest} = config
  const {constants} = useContext(ConfigContext)

  const createCategory = async (name: string) => {
    try {
      const newCategory = await expenseService.createCategory({name})
      const updatedOptions = [...options, {value: newCategory.id, label: newCategory.name}]
      setOptions(updatedOptions)
      reset({...values, category: newCategory.id})
    } catch (error) {
      console.error("Error creating category:", error)
    }
  }

  const value = useMemo(() => options.find((option) => option?.value === category), [category, options])
  const isCreatable = !!createable
  const SelectComponent = isCreatable ? CreatableSelect : CustomSelect

  const commonProps = {
    placeholder: "Категория",
    ...rest,
    options,
    value,
    onChange: (val: {value: number; label: string}) => reset({...values, category: val?.value}),
    getOptionValue: (option: OptionType) => option?.value,
    getOptionLabel: (option: OptionType) => option?.label,
    styles: colourStyles(false),
    required: true,
    isSearchable: isCreatable
  }

  useEffect(() => {
    const formattedOptions = constants.EXPENSE_CATEGORY.map((item) => ({
      value: item.id,
      label: item.label,
      ...item
    }))
    setOptions(formattedOptions)
  }, [constants])

  return (
    <div className="flex flex-col gap-2">
      {label && <p className="font-normal text-[13px] text-gray-400">{label}</p>}
      <SelectComponent
        {...commonProps}
        {...(isCreatable && {
          onCreateOption: (val) => createCategory(val),
          formatCreateLabel: (val) => <div>Создать "{val}"</div>
        })}
      />
    </div>
  )
}
