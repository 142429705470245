import {MouseEvent, useContext, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import type {ColumnsType} from "antd/es/table"
import {AsyncRentalPoint} from "src/abstract/async-rental-point"
import {FormProvider, useForm} from "react-hook-form"
import {EMPTY_LIST, ListModel, ListParams} from "src/models/common"
import {useDebounce} from "src/hooks/useDebounce"
import modalService from "src/components/modal/global/modal.service"
import {TableRowSelection} from "antd/es/table/interface"
import {FormControl} from "src/components/shared/inputs/form-control"
import {ConfigContext} from "src/app"
import {ControlCheckbox} from "src/components/shared/inputs/control-checkbox"
import {useCurrentRoute} from "src/hooks/useCurrentRoute"
import inventoryGroupService from "src/services/inventory/inventory-group.service"
import {SharedInventoryGroupSelect} from "src/components/shared/components/select/inventory-group"
import {SharedCategorySelect} from "src/components/shared/components/select/category"
import Icon from "src/components/shared/components/material-icon"
import Table from "src/components/shared/antd-custom/table"
import {ImageField} from "src/components/shared/image-loader/image"
import {hexToRgbA} from "src/utils/color/hex-to-rgba"
import maintenanceService from "src/services/maintenance/maintenance.service"
import {
  BaseMaintenanceModel,
  InventoryMaintenanceModel,
  MaintenanceModel
} from "src/models/manager/maintenance/maintenance.model"
import PaginationFormComponent from "src/components/shared/pagination/pagination-form"
import MaintenanceModal from "../maintenance-add.modal"
import {CarTireState, CarTireStateLabel, CarWashState, CarWashStateLabel, tiresLabels, washedLabels} from "../constants"
import OneMaintenanceHistory from "../one-maintenance-history"
import {colorPaletes} from "src/components/shared/constants"
import {SharedInventoryStateSelect} from "src/components/shared/components/select/inventory-state"
import OptionMaintenance from "../details/maintenance-pending"
import MaintenanceFinishModal from "../maintenance-finish.modal"
import MaintenanceExtraPopover from "../details/maintenance-extra"
import clsx from "clsx"

interface MaintenanceParams extends ListParams {
  search: string
  state: string
  rental_point: number
  group: number | string
  client__signed: boolean
  group__category: number
  category: string
}

export default function MaintenanceList() {
  const [list, setList] = useState<ListModel<InventoryMaintenanceModel>>(EMPTY_LIST)
  const {t} = useTranslation()
  const defaultConfig = {page: 1, pageSize: 10}
  const form = useForm<MaintenanceParams>({defaultValues: defaultConfig})
  const {reset, setValue, watch} = form
  const values = watch()
  const activeRoute = useCurrentRoute()
  const searchDebounce = useDebounce(watch("search", ""), 500)
  const {constantsMap} = useContext(ConfigContext)
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([])

  const onLoad = async (params: any): Promise<void> =>
    maintenanceService
      .list({...params})
      .then((res) => {
        setList(res)
      })
      .catch((e) => e.response.status === 404 && setValue("page", 1))

  const newMaintenance = (inventory: InventoryMaintenanceModel) => {
    modalService.open({
      props: {backdrop: true, dialogClassName: "max-w-fit"},
      component: (
        <MaintenanceModal
          inventory={inventory}
          onSubmit={(data) => maintenanceService.postForInventory(inventory.id, data).then(() => setValue("page", 1))}
        />
      )
    })
  }

  const finishMaintenance = (inventory: InventoryMaintenanceModel, maintenance: BaseMaintenanceModel) => {
    modalService.open({
      props: {backdrop: true, dialogClassName: "max-w-fit"},
      component: (
        <MaintenanceFinishModal
          inventory={inventory}
          maintenance={maintenance}
          onSubmit={(data) =>
            maintenanceService.finish(inventory.id, maintenance.id, data).then(() => setValue("page", 1))
          }
        />
      )
    })
  }

  const openMaintanenceHistory = (inventory: InventoryMaintenanceModel) => (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    modalService.open({
      props: {size: "xl", backdrop: true},
      component: <OneMaintenanceHistory inventory={inventory} />
    })
  }

  const rowSelection: TableRowSelection<MaintenanceModel> = {
    selectedRowKeys,
    onChange: (keys: number[]) => setSelectedRowKeys(keys),
    columnWidth: 48,
    columnTitle: (
      <ControlCheckbox
        className="form-check-input"
        checked={selectedRowKeys.length !== 0 && selectedRowKeys.length === list.results.length}
        indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length !== list.results.length}
        onChange={() => setSelectedRowKeys((prev) => (prev.length === 0 ? list.results.map((obj) => obj.id) : []))}
      />
    ),
    renderCell: (val: boolean, inventory: MaintenanceModel) => (
      <ControlCheckbox
        className="form-check-input"
        checked={val}
        onClick={(e) => e.stopPropagation()}
        onChange={() =>
          setSelectedRowKeys((prev) =>
            prev.includes(inventory.id) ? prev.filter((k) => k !== inventory.id) : [...prev, inventory.id]
          )
        }
      />
    )
  }

  const bulkTireUpdate = async (tires: CarTireState) => {
    return Promise.all(selectedRowKeys.map((val) => maintenanceService.extra_update(val, {tires}))).then(() => {
      setSelectedRowKeys([])
      onLoad(values)
    })
  }

  const bulkWashUpdate = async (washed: CarWashState) => {
    return Promise.all(selectedRowKeys.map((val) => maintenanceService.extra_update(val, {washed}))).then(() => {
      setSelectedRowKeys([])
      onLoad(values)
    })
  }

  const columns: ColumnsType<InventoryMaintenanceModel> = [
    {
      width: 64,
      title: "№",
      dataIndex: "id",
      key: "id",
      className: "text-nowrap",
      render: (id) => id
    },
    {
      width: 240,
      title: t("maintenance.automobile"),
      dataIndex: "inventory",
      key: "inventory",
      render: (_, {category, image, name, unique_id}) => (
        <div className="flex gap-3 items-center">
          <ImageField src={image} className="w-10 h-10 rounded-lg" />

          <div className="flex flex-col gap-1">
            <span className="fw-500 link">{name}</span>
            <span>
              {constantsMap.INVENTORY_CATEGORIES[category]?.name} / {unique_id}
            </span>
          </div>
        </div>
      ),
      sorter: true
    },
    {
      width: 144,
      title: t("maintenance.state"),
      dataIndex: "state",
      key: "state",
      render: (state) =>
        state ? (
          <span
            style={{
              backgroundColor: hexToRgbA(constantsMap.INVENTORY_STATE_STATUS[+state].color, 0.2),
              color: constantsMap.INVENTORY_STATE_STATUS[+state].color
            }}
            className="flex justify-center items-center min-h-8 w-full rounded-lg p-2 text-[13px] font-medium text-center">
            {constantsMap.INVENTORY_STATE_STATUS[+state].name}
          </span>
        ) : (
          <span
            style={{backgroundColor: "#EDEEF7", color: "#8997A1"}}
            className="flex justify-center items-center min-h-8 w-full rounded-lg p-2 text-[13px] font-medium text-center">
            {t("maintenance.unchecked")}
          </span>
        ),
      sorter: true
    },
    {
      width: 176,
      title: t("maintenance.scheduled_maintenance"),
      key: "scheduled_maintenance",
      dataIndex: "maintenance_list",
      sorter: true,
      render: (_, inventory) => (
        <OptionMaintenance
          finishMaintenance={finishMaintenance}
          newMaintenance={newMaintenance}
          inventory={inventory}
        />
      )
    },
    {
      width: 180,
      title: t("maintenance.washing"),
      dataIndex: "extra__washed",
      key: "washed",
      sorter: true,
      render: (_, inventory) => (
        <MaintenanceExtraPopover<CarWashState>
          initialState={inventory.extra.washed}
          options={[CarWashState.NOT_WASHED, CarWashState.WASHED]}
          icon="local_car_wash"
          onSubmit={async (washed) => await maintenanceService.extra_update(inventory.id, {washed})}
          label={CarWashStateLabel}
          render={(state, open) => (
            <span
              className={clsx(
                "flex gap-1 items-center cursor-pointer",
                +state === CarWashState.WASHED ? "text-primary" : "text-alert"
              )}>
              <Icon icon="local_car_wash" />
              <span className="flex-1">
                {[CarWashState.NOT_WASHED, CarWashState.WASHED].includes(+state)
                  ? t(CarWashStateLabel[+state])
                  : t("maintenance.not_chosen")}
              </span>
              <Icon icon="keyboard_arrow_down" className={clsx("transition-all", {"rotate-180": open})} />
            </span>
          )}
        />
      )
    },
    {
      width: 120,
      title: t("maintenance.tires"),
      dataIndex: "extra__tires",
      key: "tires",
      render: (_, inventory) => (
        <MaintenanceExtraPopover<CarTireState>
          initialState={inventory.extra.tires}
          options={[CarTireState.SUMMER, CarTireState.WINTER]}
          onSubmit={async (tires) => await maintenanceService.extra_update(inventory.id, {tires})}
          label={CarTireStateLabel}
          render={(state) => (
            <span className="flex gap-1 items-center cursor-pointer text-primary">
              <span className="flex-1">
                {[CarTireState.SUMMER, CarTireState.WINTER].includes(+state)
                  ? t(CarTireStateLabel[+state])
                  : t("maintenance.not_chosen")}
              </span>
              <Icon icon="keyboard_arrow_down" className={clsx("transition-all", {"rotate-180": open})} />
            </span>
          )}
        />
      ),
      sorter: true
    },
    {
      width: 121,
      title: t("maintenance.status"),
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <span
          className="flex justify-center items-center min-h-8 w-full rounded-lg p-2 text-[13px] font-medium text-center"
          style={{color: colorPaletes[status]}}>
          {constantsMap.INVENTORY_STATUS[status]?.label}
        </span>
      ),
      sorter: true
    }
  ]

  useEffect(() => reset({...values, page: 1, search: searchDebounce}), [searchDebounce])

  useEffect(() => {
    onLoad(values)
    const sub = watch((params, {name}) => {
      if (name !== "search") onLoad(params)
    })
    return () => {
      sub.unsubscribe()
      setList(EMPTY_LIST)
    }
  }, [activeRoute])

  return (
    <FormProvider {...form}>
      <div className="flex items-start gap-2 mb-3">
        <FormControl
          name="search"
          rootclassname="col"
          className="form-control "
          icon="search"
          placeholder={t("common.input.search")}
        />
        <SharedInventoryStateSelect
          className="col"
          name="state"
          placeholder={t("maintenance.selects.state_select.all")}
          isSearchable={false}
          isClearable={true}
        />
        <AsyncRentalPoint name="rental_point" placeholder={t("maintenance.all_rental_point")} />
        <SharedInventoryGroupSelect
          className="col"
          name="group"
          placeholder={t("maintenance.all_products")}
          listOptions={(params) => inventoryGroupService.list({...params, type: 0})}
          isSearchable={true}
          isClearable={true}
        />
        <SharedCategorySelect
          className="col"
          name="group__category"
          placeholder={t("maintenance.all_categories")}
          isClearable={true}
          isSearchable={false}
        />
      </div>

      <Table
        title={
          selectedRowKeys.length > 0 &&
          (() => (
            <div className="flex justify-start items-center gap-3">
              <MaintenanceExtraPopover<CarWashState>
                initialState={null}
                options={[CarWashState.NOT_WASHED, CarWashState.WASHED]}
                onSubmit={bulkWashUpdate}
                label={CarWashStateLabel}
                render={(state, open) => (
                  <span
                    className={clsx(
                      "p-2 rounded-lg bg-gray-50 border border-gray-100 flex gap-1 items-center cursor-pointer"
                    )}>
                    <span className="flex-1">
                      {[CarWashState.NOT_WASHED, CarWashState.WASHED].includes(+state)
                        ? t(CarWashStateLabel[+state])
                        : t("maintenance.not_chosen")}
                    </span>
                    <Icon icon="keyboard_arrow_down" className={clsx("transition-all", {"rotate-180": open})} />
                  </span>
                )}
              />
              <MaintenanceExtraPopover<CarTireState>
                initialState={null}
                options={[CarTireState.SUMMER, CarTireState.WINTER]}
                onSubmit={bulkTireUpdate}
                label={CarTireStateLabel}
                render={(state) => (
                  <span className="p-2 rounded-lg bg-gray-50 border border-gray-100 flex gap-1 items-center cursor-pointer">
                    <span className="flex-1">
                      {[CarWashState.NOT_WASHED, CarWashState.WASHED].includes(+state)
                        ? t(CarTireStateLabel[+state])
                        : t("maintenance.not_chosen")}
                    </span>
                    <Icon icon="keyboard_arrow_down" className={clsx("transition-all", {"rotate-180": open})} />
                  </span>
                )}
              />
            </div>
          ))
        }
        rowSelection={rowSelection}
        rowKey={(obj) => obj.id}
        onRow={(group) => ({
          onClick: () => {
            if (selectedRowKeys.length > 0) {
              setSelectedRowKeys((prev) =>
                prev.includes(group.id) ? prev.filter((k) => k !== group.id) : [...prev, group.id]
              )
              return
            }
          }
        })}
        dataSource={list.results}
        columns={columns}
        onHistory={(group) => openMaintanenceHistory(group)}
      />

      <PaginationFormComponent count={list.count} />
    </FormProvider>
  )
}
