import authService, {ProfileModel} from "src/services/auth.service"
import {useEffect, useState} from "react"
import {Outlet} from "react-router"
import HeaderContent from "src/components/header/header-content"
import {SidebarWrapper} from "src/components/shared/components/side-wrapper"
import {useTranslation} from "react-i18next"

export default function ProfileComponent() {
  const [profile, setProfile] = useState<ProfileModel>()
  const {t} = useTranslation()

  useEffect(() => {
    const sub = authService.profile$.subscribe(setProfile)
    return () => sub.unsubscribe()
  }, [])

  return (
    <>
      <HeaderContent>
        <div className="text-2xl font-semibold header-content">{t("profile.account")}</div>
      </HeaderContent>

      <SidebarWrapper
        options={[
          {
            menus: [
              {
                link: "/profile/about",
                icon: "person",
                label: "Профиль"
              },
              {
                link: "/profile/telegram",
                icon: "near_me",
                label: "Telegram"
              },
              {
                link: "/profile/activity",
                icon: "bar_chart_4_bars",
                label: "Моя статистика"
              },
              {
                link: "/profile/subscription",
                icon: "arrow_circle_up",
                label: "Мой тариф"
              }
            ]
          }
        ]}>
        {profile && (
          <div className="bg-transparent border-none shadow-none card flex flex-col p-0 gap-0">
            <Outlet context={{profile}} />
          </div>
        )}
      </SidebarWrapper>
    </>
  )
}
