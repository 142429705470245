import {lazy} from "react"
import {Navigate, RouteObject} from "react-router-dom"
import CustomSuspense from "src/components/custom-suspense"
import ReportClientBonusWrapperComponent from "src/pages/reports/bonuses"
import ReportPayback from "src/pages/reports/inventory"
import InventoryPaybackWrapper from "src/pages/reports/inventory/payback"
import ReportComponent from "src/pages/reports/reports"
import ReportSellsLayout from "src/pages/reports/sales"
import ReportManagerLayout from "src/pages/reports/managers"
import {ProtectedRoute} from "./guards/protected-route"
import ReportRidesComponent from "../pages/reports/rides"
import ReportCarParkComponent from "../pages/reports/cars"

const ReportClientsComponent = lazy(() => import("src/pages/reports/client"))
const InventoryPaybackComponent = lazy(() => import("src/pages/reports/inventory/payback/payback-inventories"))
const ReportSalesComponent = lazy(() => import("src/pages/reports/sales/finance"))
const ReportPenaltiesList = lazy(() => import("src/pages/reports/sales/penalties"))
const ReportUserSubleaseEarnings = lazy(() => import("src/pages/reports/sublease"))
const ReportInventorization = lazy(() => import("src/pages/reports/inventorization"))
const ReportUserServiceEarnings = lazy(() => import("src/pages/reports/services"))
const ReportExpencesComponent = lazy(() => import("src/pages/reports/expenses"))
const ReportDiscountListComponent = lazy(() => import("src/pages/reports/discount"))
const ReportUserSalaryComponent = lazy(() => import("src/pages/reports/managers/salary"))
const ReportUserDriverComponent = lazy(() => import("src/pages/reports/managers/driver"))
const InventoryGroupPaybackComponent = lazy(() => import("src/pages/reports/inventory/payback/payback-groups"))
const InventoryEfficencyComponent = lazy(() => import("src/pages/reports/inventory/efficency"))
const ReportClientBonusHistoryComponent = lazy(() => import("src/pages/reports/bonuses/history"))
const ReportClientBonusBalanceComponent = lazy(() => import("src/pages/reports/bonuses/balance"))
const ReportWorkshiftList = lazy(() => import("src/pages/reports/managers/workshift"))
const ReportTransportationComponent = lazy(() => import("src/pages/reports/sales/transfers"))

export const reportRoutes: RouteObject[] = [
  {
    path: "reports",
    element: <ProtectedRoute requiredAccessKey="reports" element={<ReportComponent />} />,
    children: [
      {path: "", element: <></>},
      {
        path: "inventory",
        element: <ProtectedRoute requiredAccessKey="reports.inventory" element={<ReportPayback />} />,
        children: [
          {
            path: "",
            element: <Navigate to="efficency" replace={false} />
          },
          {
            path: "efficency",
            element: (
              <CustomSuspense>
                <InventoryEfficencyComponent />
              </CustomSuspense>
            )
          },
          {
            path: "payback",
            element: <InventoryPaybackWrapper />,
            children: [
              {
                path: "",
                element: <Navigate to="group" replace={false} />
              },
              {
                path: "group",
                element: (
                  <CustomSuspense>
                    <InventoryGroupPaybackComponent />
                  </CustomSuspense>
                )
              },
              {
                path: "inventory",
                element: (
                  <CustomSuspense>
                    <InventoryPaybackComponent />
                  </CustomSuspense>
                )
              }
            ]
          }
        ]
      },
      {
        path: "sales",
        element: <ProtectedRoute requiredAccessKey="reports.sales" element={<ReportSellsLayout />} />,
        children: [
          {
            path: "",
            element: <Navigate to="main" replace={false} />
          },
          {
            path: "main",
            element: (
              <CustomSuspense>
                <ReportSalesComponent />
              </CustomSuspense>
            )
          },
          {
            path: "penalties",
            element: (
              <CustomSuspense>
                <ReportPenaltiesList />
              </CustomSuspense>
            )
          }
        ]
      },
      {
        path: "transfers",
        element: (
          <CustomSuspense>
            <ReportTransportationComponent />
          </CustomSuspense>
        )
      },
      {
        path: "rides",
        element: (
          <CustomSuspense>
            <ReportRidesComponent />
          </CustomSuspense>
        )
      },
      {
        path: "drivers",
        element: (
          <CustomSuspense>
            <ReportUserDriverComponent />
          </CustomSuspense>
        )
      },
      {
        path: "cars",
        element: (
          <CustomSuspense>
            <ReportCarParkComponent />
          </CustomSuspense>
        )
      },
      {
        path: "expenses",
        element: (
          <ProtectedRoute
            requiredAccessKey="reports.expenses"
            element={
              <CustomSuspense>
                <ReportExpencesComponent />
              </CustomSuspense>
            }
          />
        )
      },
      {
        path: "managers",
        element: <ProtectedRoute requiredAccessKey="reports.managers" element={<ReportManagerLayout />} />,
        children: [
          {
            path: "",
            element: <Navigate to="salary" replace={false} />
          },
          {
            path: "salary",
            element: (
              <CustomSuspense>
                <ReportUserSalaryComponent />
              </CustomSuspense>
            )
          },
          {
            path: "workshifts",
            element: (
              <CustomSuspense>
                <ReportWorkshiftList />
              </CustomSuspense>
            )
          }
        ]
      },
      {
        path: "bonuses",
        element: <ProtectedRoute requiredAccessKey="reports.bonuses" element={<ReportClientBonusWrapperComponent />} />,
        children: [
          {
            path: "",
            element: <Navigate to="balance" replace={false} />
          },
          {
            path: "balance",
            element: (
              <CustomSuspense>
                <ReportClientBonusBalanceComponent />
              </CustomSuspense>
            )
          },
          {
            path: "history",
            element: (
              <CustomSuspense>
                <ReportClientBonusHistoryComponent />
              </CustomSuspense>
            )
          }
        ]
      },
      {
        path: "services",
        element: (
          <ProtectedRoute
            requiredAccessKey="reports.services"
            element={
              <CustomSuspense>
                <ReportUserServiceEarnings />
              </CustomSuspense>
            }
          />
        )
      },
      {
        path: "sublease",
        element: (
          <ProtectedRoute
            requiredAccessKey="reports.sublease"
            element={
              <CustomSuspense>
                <ReportUserSubleaseEarnings />
              </CustomSuspense>
            }
          />
        )
      },
      {
        path: "inventorization",
        element: (
          <ProtectedRoute
            requiredAccessKey="reports.inventorization"
            element={
              <CustomSuspense>
                <ReportInventorization />
              </CustomSuspense>
            }
          />
        )
      },
      {
        path: "clients",
        element: (
          <ProtectedRoute
            requiredAccessKey="reports.clients"
            element={
              <CustomSuspense>
                <ReportClientsComponent />
              </CustomSuspense>
            }
          />
        )
      },
      {
        path: "discounts",
        element: (
          <CustomSuspense>
            <ReportDiscountListComponent />
          </CustomSuspense>
        )
      },
      {
        path: "*",
        element: <ProtectedRoute requiredAccessKey="reports" element={<Navigate to="/reports" replace={false} />} />
      }
    ]
  }
]
