import axios from "axios"
import {ApiAbstract} from "src/abstract/api.abstract"
import {DocumentEntityModel, DocumentModel} from "src/models/manager/document"
import {Observable, shareReplay, from, BehaviorSubject, switchMap, of} from "rxjs"
import {axiosResponce} from "src/interceptor/axios-response"
import {ListModel} from "src/models/common"
import authService from "./auth.service"

class DocumentTemplateService extends ApiAbstract {
  constructor() {
    super("v1/documents")
  }

  listDocuments$ = new BehaviorSubject<void>(null)
  listDocuments = () => this.listDocuments$.next()

  documents$: Observable<DocumentModel[]> = authService.logged$.pipe(
    switchMap((logged) =>
      logged
        ? this.listDocuments$.pipe(
            switchMap(() => from(axios.get<DocumentModel[]>(this.getUrl())).pipe(axiosResponce)),
            shareReplay(1)
          )
        : of([])
    ),
    shareReplay(1)
  )

  async create(payload: DocumentModel): Promise<DocumentModel> {
    const response = await axios.post<any>(this.getUrl(), payload)
    return response.data
  }

  async get(id: number): Promise<DocumentModel> {
    return axios.get<any>(this.getUrl(id)).then((response) => {
      return response.data
    })
  }

  async patch(id: number, payload: DocumentModel): Promise<DocumentModel> {
    const response = await axios.patch<any>(this.getUrl(id), payload)
    return response.data
  }

  async delete(id: number): Promise<undefined> {
    const response = await axios.delete(this.getUrl(id))
    return response.data
  }

  async generate(documentId: number, payload: any): Promise<DocumentEntityModel> {
    const response = await axios.post<DocumentEntityModel>(this.getUrl(`${documentId}/entities`), payload)
    return response.data
  }

  async bulkGeneration(payload: {
    template: number
    request_ids: number[]
  }): Promise<{documents: DocumentEntityModel[]}> {
    const response = await axios.post<{documents: DocumentEntityModel[]}>(this.getUrl("bulk_generation"), payload)
    return response.data
  }

  async listClientDocuments(params: any): Promise<ListModel<DocumentEntityModel>> {
    const response = await axios.get<ListModel<DocumentEntityModel>>(this.getUrl("entities"), {params})
    return response.data
  }

  async getEntity(documentId: number, id: number): Promise<DocumentEntityModel> {
    const response = await axios.get<DocumentEntityModel>(this.getUrl(`${documentId}/entities/${id}`))
    return response.data
  }

  async patchEntity(documentId: number, id: number, payload: any): Promise<DocumentEntityModel> {
    const response = await axios.patch<DocumentEntityModel>(this.getUrl(`${documentId}/entities/${id}`), payload)
    return response.data
  }

  async deleteEntity(documentId: number, id: number): Promise<undefined> {
    const response = await axios.delete(this.getUrl(`${documentId}/entities/${id}`))
    return response.data
  }
}

const documentTemplateService = new DocumentTemplateService()
export default documentTemplateService
