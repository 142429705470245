import {useContext} from "react"
import {Navigate, Outlet, RouteObject} from "react-router-dom"
import {ToastContainer} from "react-toastify"
import {GlobalModal} from "src/components/modal/global/globalModal"
import AuthComponent from "src/pages/auth/auth"
import SchedulesListComponent from "src/pages/schedule"
import {clientRoutes} from "src/routers/client"
import {configRoutes} from "src/routers/configuration"
import {inventoryRoutes} from "src/routers/inventory"
import {orderRoutes} from "src/routers/order"
import {settingRoutes} from "src/routers/settings"
import {transferRoutes} from "src/routers/transfer"
import {Spotlight} from "src/components/onboarding/spotlight"
import {GeolocationComponent, DeviceListComponent, DeviceTripComponent, TripListComponent} from "src/pages/geolocation"
import {ConfigContext} from "src/app"
import Main from "src/main"
import {Application} from "src/app"
import integrationRoutes from "./integration"
import {ProtectedRoute} from "./guards/protected-route"
import {reportRoutes} from "./report"

const MainRedirect = () => {
  const {tenantConfigs} = useContext(ConfigContext)
  if (tenantConfigs.mainRoute) return <Navigate to={tenantConfigs.mainRoute} />
}
export const routes: RouteObject[] = [
  {
    path: "",
    element: (
      <Application>
        <Outlet />
        <Spotlight />
        <GlobalModal />
        <ToastContainer
          position="bottom-right"
          autoClose={3500}
          hideProgressBar={true}
          newestOnTop={true}
          draggable={false}
          theme="light"
        />
      </Application>
    ),
    children: [
      {
        path: "",
        element: (
          <Main>
            <Outlet />
          </Main>
        ),
        errorElement: (
          <Main>
            <div className="flex items-center justify-center p-5">
              <div className="mb-0 text-2xl font-medium p-5">Error</div>
            </div>
          </Main>
        ),
        children: [
          {path: "", element: <MainRedirect />},
          {
            path: "calendar",
            element: <ProtectedRoute requiredAccessKey="calendar" element={<SchedulesListComponent />} />
          },
          {
            path: "geolocation",
            element: <ProtectedRoute requiredAccessKey="geolocation" element={<GeolocationComponent />} />,
            children: [
              {
                path: "",
                element: <DeviceListComponent />
              },
              {
                path: ":deviceId/trips",
                element: <TripListComponent />,
                children: [{path: ":tripId", element: <DeviceTripComponent />}]
              }
            ]
          },

          ...settingRoutes,
          ...configRoutes,
          ...reportRoutes,
          ...inventoryRoutes,
          ...clientRoutes,
          ...orderRoutes,
          ...integrationRoutes,
          ...transferRoutes
        ]
      },
      {
        path: "auth",
        element: <AuthComponent />
      }
    ]
  },
  {
    path: "*",
    element: ""
  }
]
