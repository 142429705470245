import React, {useContext, useEffect, useRef, useState} from "react"
import {useForm} from "react-hook-form"
import useOnScreen from "src/hooks/useOnScreen"
import {useTranslation} from "react-i18next"
import {ConfigContext} from "src/app"
import {useNavigate} from "react-router-dom"
import {EMPTY_LIST, ListModel} from "src/models/common"
import driverService from "src/services/driver.service"
import HeaderContent from "src/components/header/header-content"
import Icon from "src/components/shared/components/material-icon"
import {Drivers} from "src/models/manager/transfer/drivers"

const TransportationDrivers = () => {
  const form = useForm({
    defaultValues: {
      page: 1,
      pageSize: 10
    }
  })
  const elementRef = useRef<HTMLDivElement>()
  const isOnScreen = useOnScreen(elementRef)
  const {reset, watch} = form
  const values = watch()
  const navigate = useNavigate()
  const [list, setList] = useState<ListModel<Drivers>>(EMPTY_LIST)
  const {t} = useTranslation()
  const onLoad = async (params: any): Promise<void> => {
    const response = await driverService.listDriver(params)
    setList((prev) => ({
      ...response,
      results: params?.page === 1 ? response.results : [...prev.results, ...response.results]
    }))
  }

  useEffect(() => {
    onLoad({...values})
  }, [])

  useEffect(() => {
    if (isOnScreen && list.next !== false && !!list.next) {
      const newValues = {
        ...values,
        page: values.page + 1
      }
      reset(newValues)
      onLoad(newValues)
    }
  }, [isOnScreen])

  return (
    <>
      <HeaderContent>
        <div className="flex items-center justify-between gap-2 w-full px-4 py-3">
          <div className="text-lg font-semibold md:text-2xl">{t("transfers.main.driver_expenses")}</div>
        </div>
      </HeaderContent>
      <div className="-mx-4 -mt-3 md:mx-0 md:mt-0">
        <div className="m-auto max-w-[720px] flex flex-col gap-0.5">
          {list.results.map((driver) => (
            <div
              key={driver.id}
              className="flex justify-between items-center gap-3 bg-white flex-1 p-4 md:first:rounded-t-lg md:[&:nth-last-child(2)]:rounded-b-lg"
              onClick={() => navigate(`${driver.id}/expenses`)}>
              <div className="font-semibold">{`${driver.first_name} ${driver.last_name}`}</div>
              <Icon icon={"chevron_right"} />
            </div>
          ))}
          <div ref={elementRef}></div>
        </div>
      </div>
    </>
  )
}

export default TransportationDrivers
