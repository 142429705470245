export interface ListModel<T> {
  results: T[]
  page: number
  count: number
  next: string | boolean

  sum?: number
  max?: number
  min?: number
  avg?: number

  ride_amount?: number
  ride_count?: number
  ride_duration?: string
  ride_duration_avg?: string
  transfer_count?: number
}

export const EMPTY_LIST: ListModel<any> = {
  results: [],
  count: 0,
  next: false,
  page: 0
}

export interface ListParams {
  page: number
  pageSize: number
  ordering?: string
  [key: string]: any
}
