import {useTranslation} from "react-i18next"
import {InventoryGroupModel} from "src/models/manager/inventory/inventory-group.model"
import FormSelectAsyncPagination, {FormSelectAsyncConfig} from "../../inputs/form-select-async.pagination"
import inventoryGroupService from "src/services/inventory/inventory-group.service"

type RemovalKeys = "getValue" | "getOptionLabel" | "getOptionValue"

export function SharedInventoryGroupSelect(config: Omit<FormSelectAsyncConfig<InventoryGroupModel>, RemovalKeys>) {
  const {t} = useTranslation()
  return (
    <FormSelectAsyncPagination<InventoryGroupModel>
      placeholder={t("common.select.placeholder.group")}
      {...config}
      name={config.name}
      listOptions={(params) => inventoryGroupService.list(params)}
      getValue={(id) => inventoryGroupService.get(+id)}
      getOptionLabel={(val: InventoryGroupModel) => (val ? val.name : undefined)}
      getOptionValue={(val: InventoryGroupModel) => (val ? String(val.id) : undefined)}
    />
  )
}
