import {useTranslation} from "react-i18next"
import {Outlet, useNavigate} from "react-router-dom"
import {Segmented} from "src/components/shared/antd-custom"
import {useCurrentRoute} from "src/hooks/useCurrentRoute"
import {useContext} from "react"
import {ConfigContext} from "src/app"
import {FormProvider, useForm} from "react-hook-form"
import {endOfMonth, startOfMonth} from "src/components/shared/constants"
import useQueryParams from "src/hooks/useQuertParams"
import {FilterWrapper} from "src/components/shared/components/filter-wrapper"

export default function ReportSellsLayout() {
  const navigate = useNavigate()
  const page = useCurrentRoute()
  const {t} = useTranslation()
  const {isMobile} = useContext(ConfigContext)
  const {searchParams} = useQueryParams()

  const form = useForm({
    defaultValues: {
      page: 1,
      pageSize: 10,
      start_at: startOfMonth.format("YYYY-MM-DD"),
      end_at: endOfMonth.format("YYYY-MM-DD"),
      ...searchParams
    }
  })

  return (
    <FormProvider {...form}>
      <FilterWrapper
        header={
          <Segmented
            value={page}
            className="md:flex-1 bg-gray-200 self-center rounded-lg mr-auto shadow border border-gray-200"
            onChange={(option) => navigate(option)}
            options={[
              {
                label: t("metrics.finance.pages.main"),
                value: "main",
                className: "md:p-1 md:py-[7px] font-medium text-black md:h-auto text-center min-w-24"
              },
              {
                label: t("metrics.finance.pages.penalties"),
                value: "penalties",
                className: "md:p-1 md:py-[7px] font-medium text-black md:h-auto text-center min-w-24"
              }
            ]}
            {...(isMobile && {block: true})}
          />
        }>
        <Outlet />
      </FilterWrapper>
    </FormProvider>
  )
}
