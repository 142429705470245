import {useMemo} from "react"

const generatePastelColor = () => {
  const red = Math.floor(Math.random() * 128 + 127) // Оттенок от 127 до 255
  const green = Math.floor(Math.random() * 128 + 127)
  const blue = Math.floor(Math.random() * 128 + 127)
  return `rgb(${red}, ${green}, ${blue})`
}

const defaultStyle = (size: number) => ({
  width: size,
  height: size,
  borderRadius: "50%",
  color: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: size * 0.4,
  fontWeight: "bold"
})

const Avatar = ({name = "", size = 20}: {name: string; size?: number}) => {
  const {initials, style} = useMemo(() => {
    const randomColor = generatePastelColor()
    let ret = {initials: "AB", style: {...defaultStyle(size), backgroundColor: randomColor}}

    const parts = name.split(" ")
    if (parts.length === 0) return ret
    if (parts.length === 1) return {...ret, initials: parts[0][0].toUpperCase()}

    return {...ret, initials: parts[0][0].toUpperCase() + parts[1][0].toUpperCase()}
  }, [name])

  return <div style={style}>{initials}</div>
}

export default Avatar
