import {useContext, useEffect, useState} from "react"
import {useFormContext} from "react-hook-form"
import {AsyncRentalPoint} from "src/abstract/async-rental-point"
import {FormControl} from "src/components/shared/inputs/form-control"
import {useDebounce} from "src/hooks/useDebounce"
import {ListParams} from "src/models/common"
import {SharedCategorySelect} from "src/components/shared/components/select/category"
import {useTranslation} from "react-i18next"
import {CustomFieldFilterList} from "src/pages/settings/custom-field/field-filter"
import {ConfigContext} from "src/app"
import Icon from "../material-icon"
import {CustomModal} from "../../../modal/global/customModal"
import {TenantType} from "src/services/tenant.service"

export interface InventoryGroupFilterForm extends ListParams {
  rental_point: number
  search: string
  category: number
  type: number
  archived: boolean
}

export default function InventoryGroupListFilterComponent() {
  const form = useFormContext<InventoryGroupFilterForm>()
  const [showFilters, setShowFilters] = useState(false)
  const {reset, watch} = form
  const {t} = useTranslation()
  const values = watch()
  const {isMobile, tenant} = useContext(ConfigContext)
  const accessExceptTransfer = tenant && tenant.type_code === TenantType.TRANSFER
  const searchDebounce = useDebounce(watch("search"), 500)
  useEffect(() => reset({...values, page: 1, search: searchDebounce}), [searchDebounce])

  return (
    <>
      <div className={`flex gap-2 ${isMobile ? "bg-white py-2 px-4 mb-[5px] rounded-lg" : "mb-3"}`}>
        {!isMobile && (
          <>
            <div className={`${accessExceptTransfer && "hidden"} flex gap-2 col-3`}>
              <SharedCategorySelect
                className="col"
                placeholder={t("common.select.category")}
                name="category"
                isClearable={true}
                isSearchable={false}
              />
            </div>
            <AsyncRentalPoint className={accessExceptTransfer && "hidden"} name="rental_point" />
            <div className="col"></div>
          </>
        )}

        <FormControl
          icon="search"
          rootclassname={isMobile ? "w-full" : "col-3"}
          name="search"
          className="form-control"
          placeholder={t("common.input.search")}
        />

        {isMobile && (
          <div
            className={`${accessExceptTransfer && "hidden"} min-h-12 min-w-12 rounded-lg border border-gray-200 flex justify-center items-center`}
            onClick={() => setShowFilters(true)}>
            <Icon icon={"filter_list"} className={"h-5 w-6 text-2xl"} />
          </div>
        )}
      </div>

      <CustomModal backdropClick={true} show={showFilters} onHide={() => setShowFilters(false)}>
        <div className="flex flex-col gap-3">
          <SharedCategorySelect
            className="col"
            label={t("common.select.category")}
            placeholder={t("common.select.category")}
            name="category"
            isClearable={true}
            isSearchable={false}
          />
          <AsyncRentalPoint name="rental_point" label={t("common.select.rental_point")} />
        </div>
      </CustomModal>

      <CustomFieldFilterList className="mb-3" prefix="extra__" type="inventory" />
    </>
  )
}
