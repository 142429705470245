import React, {useContext, useEffect, useRef, useState} from "react"
import {Empty} from "antd"
import toDateTime from "src/utils/date-time"
import {toPrice} from "src/utils/price"
import Icon from "src/components/shared/components/material-icon"
import {EMPTY_LIST, ListModel} from "src/models/common"
import useOnScreen from "src/hooks/useOnScreen"
import {useForm} from "react-hook-form"
import {useNavigate} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {DriverContext} from "./driver-context"

const DriverRidesComponent = () => {
  const form = useForm({
    defaultValues: {
      page: 1,
      pageSize: 10
    }
  })
  const [list, setList] = useState<ListModel<any>>(EMPTY_LIST)
  const elementRef = useRef<HTMLDivElement>()
  const isOnScreen = useOnScreen(elementRef)
  const navigate = useNavigate()
  const {t} = useTranslation()
  const {getDriverDetail} = useContext(DriverContext)
  const {reset, watch} = form
  const values = watch()

  useEffect(() => {
    if (isOnScreen && list.next !== false && !!list.next) {
      const newValues = {
        ...values,
        page: values.page + 1
      }
      reset(newValues)
      getDriverDetail(newValues).then((response) => {
        setList((prev) => ({
          ...response,
          results: newValues.page === 1 ? response.results : [...prev.results, ...response.results]
        }))
      })
    }
  }, [isOnScreen])

  useEffect(() => {
    getDriverDetail(values).then(setList)
  }, [])

  return (
    <>
      {list.results.length === 0 ? (
        <div className="w-full bg-white md:first:rounded-t-lg">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{margin: "8px auto"}} />
        </div>
      ) : (
        list.results.map((ride) => (
          <div
            key={ride.id}
            className="w-full bg-white md:first:rounded-t-lg"
            onClick={() => navigate(`/rides/${ride.id}`)}>
            <div className="flex justify-between items-center gap-4 p-1">
              <div className="flex flex-col gap-2 border-gray-200">
                <div className="flex gap-2 font-medium text-gray-700">
                  <span>№ {ride.id}</span>
                </div>

                <span className="font-medium text-gray-400">
                  {toDateTime(ride.created_at)} · {toPrice(+ride.price)}
                </span>
              </div>

              <Icon icon="chevron_right" />
            </div>
          </div>
        ))
      )}
      <div ref={elementRef}></div>
    </>
  )
}

export default DriverRidesComponent
