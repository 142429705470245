import {useTranslation} from "react-i18next"
import {FormProvider, useForm} from "react-hook-form"
import modalService from "src/components/modal/global/modal.service"
import {SharedDiscountSelect} from "src/components/shared/components/select/discount"

export default function SaleInventoryDiscountList({
  discount,
  onChange
}: {
  discount?: number
  onChange: (payload: {discount: number}) => void
}) {
  const {t} = useTranslation()
  const form = useForm({defaultValues: {discount}})
  const {handleSubmit, formState} = form
  const onSubmit = handleSubmit(onChange)

  return (
    <FormProvider {...form}>
      <div className="text-2xl font-semibold mb-4">{t("sale.edit.discount_title")}</div>
      <SharedDiscountSelect
        className="col mb-3"
        label={t("common.select.discount")}
        name="discount"
        type="discount"
        isClearable
      />
      <div className="flex gap-2">
        <button className="btn btn-bg-light p-3 col" onClick={() => modalService.closeModal()}>
          {t("common.cancel")}
        </button>
        <button className="btn btn-primary btn-color-white col" onClick={onSubmit} disabled={!formState.isValid}>
          {t("common.save")}
        </button>
      </div>
    </FormProvider>
  )
}
