import {Link, useNavigate} from "react-router-dom"
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react"
import authService, {GroupType, ProfileModel} from "src/services/auth.service"
import Icon from "src/components/shared/components/material-icon"
import orderService from "src/services/order-requests/order.service"
import workshiftService, {WorkshiftModel} from "src/services/workshift.service"
import WorkshiftTimer from "../workshift/workshift-timer"
import {firstValueFrom, switchMap} from "rxjs"
import {CurrentRentalPoint} from "./rental-point"
import {useTranslation} from "react-i18next"
import {isNil} from "src/utils/isNil"
import {ConfigContext} from "src/app"
import clsx from "clsx"
import {trackAmplitudeData} from "src/services/amplitude"
import LinkWrapper from "./linkWrapper/linkWrapper"
import moment from "moment-timezone"
import subscriptionModalService from "src/services/subscription.service"
import notificationService from "src/services/notification.service"
import {Popover} from "antd"
import NotificationPopUp from "./notification/list"
import {isProd} from "src/utils/isProd"
import iconMini from "src/assets/icons/icon-mini.svg"
import iconLight from "src/assets/icons/icon-light.svg"
import "./aside.scss"
import hasAccess from "../../utils/hasAccess"
import transportationService from "../../services/transportation.service"
import {TenantType} from "../../services/tenant.service"

const getClassName = (percent: number) => {
  if (percent < 20) {
    return {
      bar: "bg-alert",
      text: "color-red-300"
    }
  } else if (percent > 20 && percent < 80) {
    return {
      bar: "bg-warn-400",
      text: "color-warn-400"
    }
  } else {
    return {
      bar: "bg-accept",
      text: "color-green-500"
    }
  }
}

function SubscriptionAside({collapse}: {collapse: boolean}) {
  const {t} = useTranslation()
  const {tenant} = useContext(ConfigContext)

  const {_totalDays, _daysLeft, _percent, bar, text} = useMemo(() => {
    if (!tenant)
      return {
        _totalDays: 0,
        _daysLeft: 0,
        _percent: 0,
        bar: "bg-gray-300",
        text: "bg-accept"
      }

    const duration = moment(tenant.end_at).diff(tenant.start_at, "days")
    const left = moment(tenant.end_at).diff(moment(), "days")
    const percent = Math.max((100 * left) / duration, 5)

    return {
      _totalDays: duration,
      _daysLeft: left,
      _percent: percent,
      ...getClassName(percent)
    }
  }, [tenant])

  return (
    _totalDays === 14 && (
      <div
        className={
          collapse
            ? "flex bg-gray-800 rounded-lg p-3 gap-4 items-center border border-dark-light"
            : "flex flex-col bg-gray-800 rounded-lg p-3 gap-4 border border-dark-light"
        }>
        {!collapse && (
          <div className="text-sm font-semibold">
            {t("aside.limit")}
            <span className={`ml-1 ${text}`}>{`${_daysLeft}/${_totalDays}`}</span>
          </div>
        )}
        <div className="w-full h-[7px] relative overflow-hidden rounded-lg bg-gray-500">
          <div className={`h-full ${bar}`} style={{width: `${_percent}%`}}></div>
        </div>
        {!collapse && (
          <button
            className="btn btn-primary btn-color-white justify-start gap-1 font-semibold text-nowrap, text-xs"
            style={{padding: 10, overflow: "hidden", width: "100%"}}
            onClick={() => subscriptionModalService.show$.next({show: true, type: null})}>
            {t("aside.update_plan")} <Icon icon="east" className="text-lg" />
          </button>
        )}
      </div>
    )
  )
}

export function Aside({collapse, onCollapse}: {collapse: boolean; onCollapse: () => void}) {
  const scrollToRef = (ref: any) => ref.current?.scroll({top: 0, behavior: "smooth"})
  const ref = useRef(null)
  const {
    settings,
    integrationMap,
    tenant,
    tenantConfigs: {accesses}
  } = useContext(ConfigContext)
  const [workshift, setWorkshift] = useState<WorkshiftModel>()
  const [profile, setProfile] = useState<ProfileModel>()
  const [opacity, setOpacity] = useState(0.3)
  const [popoverVisible, setPopoverVisible] = useState(false)
  const [open, setOpen] = useState(false)
  const [count, setCount] = useState<number>(0)
  const navigate = useNavigate()
  const {t} = useTranslation()

  const has_perms = useCallback(
    (key: string) => {
      if (!profile) return false
      if (profile.is_superuser) return true
      return profile.perms.some((perm) => perm.includes(key))
    },
    [profile]
  )

  const isNotManager = profile && profile.group_type !== GroupType.MANAGER

  const createOrder = () => {
    firstValueFrom(
      authService.profile$.pipe(
        switchMap((profile) => {
          if (isProd) trackAmplitudeData("Create Order")
          return orderService
            .post({
              rental_point: profile.points.length > 0 ? profile.points[0] : null
            })
            .then((res) => navigate(`/orders/${res.id}/all`))
        })
      )
    )
  }

  const createTransfer = async () => {
    const response = await transportationService.post({})
    if (response?.id) navigate(`/transportation/${response.id}`)
  }

  const accessCreateOrder = () => {
    if (accesses.transportation) {
      return createTransfer()
    }
    return createOrder()
  }

  useEffect(() => {
    if (tenant && tenant.type_code !== TenantType.TRANSFER) {
      const subscription = notificationService.count$.subscribe(setCount)
      return () => subscription.unsubscribe()
    }
  }, [])

  useEffect(() => {
    const sub = authService.profile$.subscribe(setProfile)
    scrollToRef(ref)
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    const sub = workshiftService.workshift$.subscribe(setWorkshift)
    return () => sub.unsubscribe()
  }, [])

  const useWorkshift = settings && settings.workshift
  const workshiftStarted = workshift && !isNil(workshift.start_at)
  const is_superuser = profile && profile.is_superuser

  const contentAccess = useWorkshift ? workshiftStarted || is_superuser : true
  const handleLinkClick = () => setPopoverVisible(!popoverVisible)
  const handleOpenChange = (newOpen: boolean) => setOpen(newOpen)
  const onHide = () => setOpen(false)

  return (
    <aside className="scrollbar-hide h-screen" ref={ref}>
      <div className="company-icon">
        <div className="py-7">
          {collapse ? (
            <img src={iconMini} draggable={false} />
          ) : (
            <img src={iconLight} width="145" height="24" draggable={false} />
          )}
        </div>

        {accesses.orders && (
          <div className="py-8 px-3 w-100">
            <button
              className="btn btn-primary btn-color-white justify-start gap-2 font-semibold text-nowrap w-100 h-10 p-[10px]"
              onClick={createOrder}>
              <Icon icon="add" />
              {!collapse && t("orders.new_order")}
            </button>
          </div>
        )}

        {accesses.transportation && profile && (profile.group_type === GroupType.MANAGER || profile.is_superuser) && (
          <div className="py-8 px-3 w-100">
            <button
              className="btn btn-primary btn-color-white justify-start gap-2 font-semibold text-nowrap w-100 h-10 p-[10px]"
              onClick={createTransfer}>
              <Icon icon="add" />
              {!collapse && t("orders.transfer.new")}
            </button>
          </div>
        )}
      </div>

      <div
        className={clsx("collapse-btn shadow z-[1000]", !collapse && "active")}
        style={{opacity}}
        onMouseEnter={() => setOpacity(1)}
        onMouseLeave={() => setTimeout(() => setOpacity(0.3), 1500)}
        onClick={onCollapse}>
        <Icon className="cursor-pointer text-2xl color-gray-400" icon="chevron_right" />
      </div>

      {profile && (
        <div className="menu overflow-scroll scrollbar-hide">
          <div className="flex flex-col gap-4 items-stretch w-full">
            <div className={!collapse && "menu-block"}>
              {accesses.orders && has_perms("orderrequest") && (
                <LinkWrapper
                  collapse={collapse}
                  route="orders"
                  icon="list_alt"
                  text={t("aside.menu.orders")}
                  isSection={false}
                />
              )}
              {accesses.orders &&
                tenant &&
                ["Tobe", "JaiGoi", "yama", "procateam"].includes(tenant.name) &&
                has_perms("orderrequest") && (
                  <LinkWrapper
                    collapse={collapse}
                    route="plus_orders"
                    icon="list_alt"
                    text="Yume Plus"
                    isSection={false}
                  />
                )}
              {accesses.calendar && has_perms("inventoryschedule") && (
                <LinkWrapper
                  collapse={collapse}
                  route="calendar"
                  icon="calendar_month"
                  text={t("aside.menu.schedules")}
                  isSection={false}
                />
              )}
              {accesses.transportation &&
                ([GroupType.ADMIN, GroupType.DRIVER].includes(profile.group_type) || profile.is_superuser) && (
                  <LinkWrapper
                    collapse={collapse}
                    icon="list"
                    route="rides"
                    text={t("orders.transfer.all_order")}
                    isSection={false}
                  />
                )}
              {/*{accesses.transportation && (GroupType.MANAGER === profile.group_type || profile.is_superuser) && (*/}
              {/*  <LinkWrapper*/}
              {/*    collapse={collapse}*/}
              {/*    icon="directions_car"*/}
              {/*    route="transportation"*/}
              {/*    text={t("transfers.main.transportation")}*/}
              {/*    isSection={false}*/}
              {/*  />*/}
              {/*)}*/}
              {tenant && tenant.type_code !== TenantType.TRANSFER && (
                <Popover
                  content={<NotificationPopUp onHide={onHide} />}
                  trigger="click"
                  open={open}
                  arrow={false}
                  onOpenChange={handleOpenChange}
                  visible={popoverVisible}
                  onVisibleChange={setPopoverVisible}
                  placement="right"
                  overlayInnerStyle={{
                    padding: 0,
                    marginLeft: "20px",
                    marginTop: "10px"
                  }}>
                  <div onClick={handleLinkClick} className={clsx("menu-item mb-2")}>
                    <div className="relative">
                      <Icon className="menu-item-icon" icon="notifications" />
                      {collapse && count > 0 && (
                        <div className="absolute top-[-5px] right-[-3px] min-w-4 h-4 bg-red-500 text-[8px] text-white rounded-full flex items-center justify-center">
                          {count}
                        </div>
                      )}
                    </div>
                    {!collapse && (
                      <>
                        <div className="menu-item-name">{t("settings.sections.notifications")}</div>
                        {count > 0 && (
                          <div
                            className={`w-5 h-5 bg-red-500 ${count.toString().length > 2 ? "text-[8px]" : "text-[10px]"}  rounded-full flex items-center justify-center`}>
                            {count.toString().length > 2 ? "99+" : count}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </Popover>
              )}
            </div>

            {accesses.groups &&
              (has_perms("view_client") ||
                has_perms("inventorygroup") ||
                has_perms("inventoryset") ||
                has_perms("view_service")) && (
                <div className={!collapse && "menu-block"}>
                  {accesses.groups && has_perms("inventorygroup") && (
                    <LinkWrapper
                      route="groups"
                      icon="inventory_2"
                      text={
                        tenant && tenant.type_code === TenantType.TRANSFER
                          ? t("transfers.form.cars")
                          : t("aside.menu.groups")
                      }
                      isSection={!!(accesses.groups.sales || accesses.groups.sets || accesses.groups.services)}
                      collapse={collapse}
                      subRoutes={[
                        accesses.groups.sales &&
                          has_perms("inventorygroup") && {
                            route: "sales",
                            icon: "storefront",
                            text: t("aside.menu.shop")
                          },
                        accesses.groups.sets &&
                          has_perms("inventoryset") && {
                            route: "inventory-sets",
                            icon: "dataset",
                            text: t("aside.menu.sets")
                          },
                        accesses.groups.services &&
                          has_perms("view_service") && {
                            route: "services",
                            icon: "design_services",
                            text: t("aside.menu.services")
                          }
                      ].filter(Boolean)}
                    />
                  )}
                  {accesses.clients && has_perms("view_client") && (
                    <LinkWrapper collapse={collapse} route="clients" icon="group" text={t("aside.menu.clients")} />
                  )}
                </div>
              )}

            {(accesses.reports || accesses.inventorization || accesses.maintenance) &&
              (has_perms("metrics") || has_perms("inventoryinventorization")) && (
                <div className={!collapse && "menu-block"}>
                  {hasAccess(accesses, "transportation.expenses") && isNotManager && (
                    <LinkWrapper
                      collapse={collapse}
                      icon="insert_chart"
                      text={"Expenses"}
                      route="drivers"
                      isSection={false}
                    />
                  )}
                  {hasAccess(accesses, "transportation.cities") && isNotManager && (
                    <LinkWrapper
                      collapse={collapse}
                      icon="location_on"
                      text={t("orders.transfer.destinations")}
                      route="cities"
                      isSection={false}
                    />
                  )}
                  {accesses.reports && has_perms("metrics") && (
                    <LinkWrapper collapse={collapse} route="reports" icon="analytics" text={t("aside.menu.metrics")} />
                  )}
                  {accesses.maintenance && (
                    <LinkWrapper
                      collapse={collapse}
                      route="maintenance"
                      icon="inventory"
                      text={t("maintenance.maintenance_abr")}
                    />
                  )}
                  {accesses.inventorization && has_perms("inventoryinventorization") && (
                    <LinkWrapper
                      collapse={collapse}
                      route="inventorization"
                      icon="inventory"
                      text={t("aside.menu.inventorization")}
                    />
                  )}
                </div>
              )}

            <div className={!collapse && "menu-block menu-block--last"}>
              {accesses.integrations && profile && profile.is_superuser && (
                <LinkWrapper
                  route="integrations"
                  icon="toys_and_games"
                  text={t("aside.menu.integrations")}
                  isSection={
                    !!(accesses.integrations.gps || accesses.integrations.wazzup || accesses.integrations.widget)
                  }
                  collapse={collapse}
                  subRoutes={[
                    accesses.integrations.gps &&
                      integrationMap["gps"] &&
                      integrationMap["gps"].connected && {
                        route: "geolocation",
                        icon: "map",
                        text: t("aside.menu.gps")
                      },
                    accesses.integrations.wazzup &&
                      integrationMap["wazzup"] &&
                      integrationMap["wazzup"].connected && {
                        route: "wazzup",
                        icon: "forum",
                        text: "Wazzup"
                      },
                    accesses.integrations.widget &&
                      integrationMap["widget"] &&
                      integrationMap["widget"].connected && {
                        route: "integrations/widget",
                        icon: "widgets",
                        text: t("aside.menu.widget")
                      }
                  ].filter(Boolean)}
                />
              )}
              {accesses.users && profile && profile.is_superuser && (
                <LinkWrapper collapse={collapse} route="users" icon="manage_accounts" text={t("aside.menu.users")} />
              )}
              {accesses.config && (
                <LinkWrapper
                  collapse={collapse}
                  route="config"
                  icon="page_info"
                  text={t("aside.menu.configurations")}
                />
              )}
              {accesses.settings && profile && profile.is_superuser && (
                <LinkWrapper collapse={collapse} route="settings" icon="settings" text={t("aside.menu.settings")} />
              )}
            </div>

            <a
              className={clsx(
                "flex gap-2 p-3 items-center cursor-pointer border border-gray-700 rounded-lg",
                collapse ? "justify-center" : "justify-content-between"
              )}
              href="https://wa.me/+7779479990"
              target="_blank"
              rel="noreferrer">
              <div className="flex gap-2 items-center">
                <Icon className={`${collapse} && 'flex items-center'`} icon="help" />
                {!collapse && <div>Support</div>}
              </div>
              {!collapse && <Icon icon="open_in_new" />}
            </a>

            <SubscriptionAside collapse={collapse} />

            <div>
              <CurrentRentalPoint collapse={collapse} />

              {workshift && contentAccess && <WorkshiftTimer collapse={collapse} workshift={workshift} />}

              {profile && (
                <Link to="/profile" className="menu-profile">
                  <div className="menu-profile-icon">
                    <Icon icon="person" className="text-base color-white" />
                  </div>
                  <div className="menu-profile-name">
                    {profile.first_name} {profile.last_name}
                  </div>
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
    </aside>
  )
}
