import {createContext, Dispatch, ReactNode, SetStateAction, useState} from "react"

interface OrderListContextParams {
  selection: boolean
  setSelection: Dispatch<SetStateAction<boolean>>
  selected: number[]
  setSelected: Dispatch<SetStateAction<number[]>>
}

export const OrderListContext = createContext<OrderListContextParams>(undefined)

export default function OrderListProviderComponent({children}: {children: ReactNode}) {
  const [selection, setSelection] = useState<boolean>(false)
  const [selected, setSelected] = useState<number[]>([])

  return (
    <OrderListContext.Provider value={{selection, setSelection, selected, setSelected}}>
      {children}
    </OrderListContext.Provider>
  )
}
