import {Navigate, Outlet, useNavigate} from "react-router"
import {TabConfig, TabPanel, TabPanelConfig} from "src/components/shared/tabs/tabs"
import {useCurrentRoute} from "src/hooks/useCurrentRoute"
import HeaderContent from "src/components/header/header-content"
import {useCallback, useContext, useEffect, useState} from "react"
import authService, {ProfileModel} from "src/services/auth.service"
import {useTranslation} from "react-i18next"
import {ConfigContext} from "src/app"
import clsx from "clsx"
import {useLocation} from "react-router-dom"
import hasAccess from "../../utils/hasAccess"

function TabRender({route, label, disabled = false}: {route: string; label: string; disabled?: boolean}) {
  const {isMobile} = useContext(ConfigContext)
  const location = useLocation()
  const active = location.pathname.includes(route)
  return isMobile ? (
    <span
      className={clsx(
        "min-h-10 flex items-center justify-center gap-2 rounded-[32px] p-2 px-3 cursor-pointer",
        active ? "bg-test-black" : "bg-gray-test-70",
        disabled && "!bg-gray-400"
      )}>
      <span className={clsx("font-medium text-[13px] text-nowrap", active ? "text-white" : "text-test-black")}>
        {label}
      </span>
    </span>
  ) : (
    <span
      className={clsx("tab-nav-link text-nowrap font-medium text-[13px]", {
        active,
        "bg-[#f8f9fd]": disabled
      })}>
      {label}
    </span>
  )
}

export default function ReportComponent() {
  const {t} = useTranslation()
  const {profile, tenantConfigs} = useContext(ConfigContext)
  const activeRoute = useCurrentRoute(3)
  const navigate = useNavigate()
  const location = useLocation()

  const hasPerm = useCallback(
    (key: string) => {
      if (!profile) return false
      if (profile.is_superuser) return true
      return profile.perms.some((perm) => perm.includes(key) || perm.includes("metricsmodel"))
    },
    [profile]
  )

  const {
    isMobile,
    tenant,
    tenantConfigs: {accesses}
  } = useContext(ConfigContext)

  const tabsConfig: TabConfig = profile &&
    tenant && {
      items: [
        {
          key: "sales",
          hidden: typeof accesses.reports === "object" && !accesses.reports.sales,
          label: () => (
            <TabRender route="sales" label={t("metrics.pages.sales")} disabled={!hasPerm("metricsfinance")} />
          )
        },
        {
          key: "transfers",
          hidden: typeof accesses.reports === "object" && !accesses.reports.transfers,
          label: () => <TabRender route="transfers" label={t("transfers.main.transportation")} />
        },
        {
          key: "expenses",
          hidden: typeof accesses.reports === "object" && !accesses.reports.expenses,
          label: () => (
            <TabRender route="expenses" label={t("metrics.pages.expenses")} disabled={!hasPerm("metricsexpenses")} />
          )
        },
        {
          key: "clients",
          hidden: typeof accesses.reports === "object" && !accesses.reports.clients,
          label: () => (
            <TabRender route="clients" label={t("metrics.pages.clients")} disabled={!hasPerm("metricsclient")} />
          )
        },
        {
          key: "drivers",
          hidden: typeof accesses.reports === "object" && !accesses.reports.drivers,
          label: () => <TabRender route="drivers" label={t("transfers.main.driver")} />
        },
        {
          key: "rides",
          hidden: typeof accesses.reports === "object" && !accesses.reports.rides,
          label: () => <TabRender route="rides" label={t("transfers.main.rides")} />
        },
        // {
        //   key: "cars",
        //   hidden: typeof accesses.reports === "object" && !accesses.reports.drivers,
        //   label: () => <TabRender route="cars" label={"Car park"} />
        // },
        {
          key: "managers",
          hidden: typeof accesses.reports === "object" && !accesses.reports.managers,
          label: () => (
            <TabRender route="managers" label={t("metrics.pages.managers")} disabled={!hasPerm("metricsmanager")} />
          )
        },
        {
          key: "services",
          hidden: typeof accesses.reports === "object" && !accesses.reports.services,
          label: () => (
            <TabRender route="services" label={t("metrics.pages.services")} disabled={!hasPerm("metricsservice")} />
          )
        },
        {
          key: "discounts",
          hidden: typeof accesses.reports === "object" && !accesses.reports.discounts,
          label: () => <TabRender route="discount" label="Скидки" disabled={false} />
        },
        {
          key: "sublease",
          hidden: typeof accesses.reports === "object" && !accesses.reports.sublease,
          label: () => (
            <TabRender route="sublease" label={t("metrics.pages.sublease")} disabled={!hasPerm("metricssublease")} />
          )
        },
        {
          key: "inventory",
          hidden: typeof accesses.reports === "object" && !accesses.reports.inventory,
          label: () => (
            <TabRender route="inventory" label={t("metrics.pages.inventory")} disabled={!hasPerm("metricsinventory")} />
          )
        },
        {
          key: "inventorization",
          hidden: typeof accesses.reports === "object" && !accesses.reports.inventorization,
          label: () => <TabRender route="inventorization" label={t("metrics.pages.inventorization")} disabled={true} />,
          disabled: true
        },
        {
          key: "bonuses",
          hidden: typeof accesses.reports === "object" && !accesses.reports.bonuses,
          label: () => (
            <TabRender route="bonuses" label={t("metrics.pages.bonuses")} disabled={!hasPerm("metricsbonus")} />
          )
        }
      ].filter((tab) => hasAccess(accesses, `reports.${tab.key}`)),
      onClick: (config: TabPanelConfig) => !config.disabled && navigate(`/reports/${config.key}`, {relative: "path"}),
      activeTab: activeRoute,
      style: {padding: "0 16px", paddingTop: 8},
      isMobile: isMobile
    }

  if (location.pathname === "/reports" || location.pathname === "/reports/")
    return <Navigate to={`/reports/${Object.keys(tenantConfigs.accesses.reports)[0]}`} replace={false} />

  return (
    <>
      <HeaderContent>
        <div className="flex pt-[18.5px] mx-4 font-semibold text-[22px] text-test-black h-16 border-b border-gray-100">
          {t("aside.menu.metrics")}
        </div>
        <TabPanel {...tabsConfig} />
      </HeaderContent>
      <Outlet />
    </>
  )
}
