import React, {useEffect} from "react"
import Icon from "src/components/shared/components/material-icon"
import clsx from "clsx"
import {Link} from "react-router-dom"
import {useCurrentRoute} from "src/hooks/useCurrentRoute"
import {Tooltip} from "antd"

type LinkWrapperProps = {
  route?: string
  icon: string
  custom_icon?: JSX.Element
  text: string
  collapse?: boolean
  subRoutes?: {route: string; icon: string; text: string}[]
  isSection?: boolean
  onClick?: () => void
}

const LinkWrapper: React.FC<LinkWrapperProps> = ({
  route,
  icon,
  custom_icon,
  text,
  subRoutes,
  isSection,
  collapse,
  onClick
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const currentRoute = useCurrentRoute(2)
  const toggleSubMenu = () => setIsOpen((p) => !p)

  return (
    <>
      {isSection ? (
        <Tooltip placement="right" title={text} open={!collapse ? false : undefined}>
          <Link
            to={`/${route}`}
            onClick={toggleSubMenu}
            className={clsx("menu-item mb-2", {
              "active-menu": collapse ? currentRoute.startsWith(route) : isOpen
            })}>
            {custom_icon ? custom_icon : <Icon className="menu-item-icon" icon={icon} />}
            <div className="menu-item-name">{text}</div>

            {isSection && (
              <Icon className={clsx("menu-item-arrow", isOpen ? "rotate-180" : "")} icon="keyboard_arrow_down" />
            )}
          </Link>
        </Tooltip>
      ) : route ? (
        <Tooltip placement="right" title={text} open={!collapse ? false : undefined}>
          <Link to={`/${route}`} className={clsx("menu-item mb-2", currentRoute.startsWith(route) && "active")}>
            {custom_icon ? custom_icon : <Icon className="menu-item-icon" icon={icon} />}
            <div className="menu-item-name">{text}</div>
          </Link>
        </Tooltip>
      ) : (
        <Tooltip placement="right" title={text} open={!collapse ? false : undefined}>
          <div onClick={onClick} className={clsx("menu-item mb-2", isSection && isOpen && "active")}>
            {custom_icon ? custom_icon : <Icon className="menu-item-icon" icon={icon} />}
            <div className="menu-item-name">{text}</div>
          </div>
        </Tooltip>
      )}

      {isSection &&
        isOpen &&
        subRoutes &&
        subRoutes.map((sub, index) => (
          <Tooltip key={index} placement="right" title={sub.text} open={!collapse ? false : undefined}>
            <Link
              to={`/${sub.route}`}
              className={clsx("menu-item mb-2", {
                active: currentRoute.includes(sub.route),
                "ml-[10px] min-w-10": !collapse
              })}>
              {custom_icon ? custom_icon : <Icon className="menu-item-icon" icon={sub.icon} />}
              <div className="menu-item-name">{sub.text}</div>
            </Link>
          </Tooltip>
        ))}
    </>
  )
}

export default LinkWrapper
