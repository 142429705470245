import {ApiAbstract} from "../abstract/api.abstract"
import axios from "axios"
import {ListModel} from "../models/common"
import {ExpenseCategoryModel, ExpensesModel} from "../pages/reports/expenses/types"
import appService from "./app.service"

export class ExpenseService extends ApiAbstract {
  constructor() {
    super("v1/crm/expenses")
  }

  public async list(params?: any): Promise<ListModel<ExpensesModel>> {
    const response = await axios.get(this.getUrl(), {params})
    return response.data
  }

  public async post(payload?: any): Promise<ListModel<ExpensesModel>> {
    const response = await axios.post(this.getUrl(), payload)
    return response.data
  }

  public async delete(ids): Promise<ListModel<ExpensesModel>> {
    const response = await axios.post(this.getUrl("bulk_delete"), {ids: ids})
    return response.data
  }

  public async createCategory(payload?: any): Promise<ExpenseCategoryModel> {
    const response = await axios.post(this.getUrl("categories"), payload)
    appService.loadConstants()
    return response.data
  }
}

const expensesService = new ExpenseService()
export default expensesService
