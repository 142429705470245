import {useContext} from "react"
import {useTranslation} from "react-i18next"
import {Outlet, useNavigate} from "react-router-dom"
import {ConfigContext} from "src/app"
import {Segmented} from "src/components/shared/antd-custom"
import {FilterWrapper} from "src/components/shared/components/filter-wrapper"
import {useCurrentRoute} from "src/hooks/useCurrentRoute"

export default function ReportManagerLayout() {
  const {isMobile} = useContext(ConfigContext)
  const {t} = useTranslation()
  const navigate = useNavigate()
  const page = useCurrentRoute()

  return (
    <FilterWrapper
      header={
        <Segmented
          value={page}
          className="bg-gray-200 self-center rounded-lg mr-auto shadow border border-gray-200"
          onChange={(option) => navigate(option)}
          {...(isMobile && {block: true})}
          style={{overflowX: "visible"}}
          block={true}
          options={[
            {
              label: t("metrics.managers.pages.salary"),
              value: "salary",
              className: "md:p-1 md:py-[7px] font-medium text-black md:h-auto text-center min-w-32"
            },
            {
              label: t("metrics.managers.pages.workshifts"),
              value: "workshifts",
              className: "md:p-1 md:py-[7px] font-medium text-black md:h-auto text-center min-w-24"
            }
          ]}
        />
      }>
      <Outlet />
    </FilterWrapper>
  )
}
