import clsx from "clsx"
import {createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useRef, useState} from "react"
import {ConfigContext} from "src/app"
import Icon from "./material-icon"

interface FilterContextModel {
  filter: ReactNode
  setFilter: Dispatch<SetStateAction<ReactNode>>
  modal: boolean
  setModal: Dispatch<SetStateAction<boolean>>
}

export const FilterContext = createContext<FilterContextModel>(null)

export function FilterWrapper({header, children}: {header?: ReactNode; children: ReactNode}) {
  const ref = useRef()
  const {isMobile, headerHeight} = useContext(ConfigContext)
  const [filter, setFilter] = useState<ReactNode>(undefined)
  const [modal, setModal] = useState<boolean>(false)
  const [height, setHeight] = useState<number>(52)

  useEffect(() => {
    if (!ref.current) return
    const resizeObserver = new ResizeObserver(() => {
      const _height = (ref.current as any)?.clientHeight
      if (height !== _height) setHeight(_height)
    })
    resizeObserver.observe(ref.current)
    return () => resizeObserver.disconnect()
  }, [ref])

  return (
    <FilterContext.Provider value={{filter, setFilter, modal, setModal}}>
      <div
        ref={ref}
        style={isMobile ? {top: headerHeight, left: 0} : {}}
        className={clsx(
          "fixed md:relative flex flex-wrap items-center gap-3",
          "bg-white md:bg-transparent border-b border-gray-100 md:border-none",
          "w-full md:w-fit mb-0 md:mb-3 px-4 py-2 md:p-0 md:h-auto z-[1001]"
        )}>
        {header}

        {isMobile ? (
          <button className="btn btn-card px-2 py-1 self-stretch" onClick={() => setModal(true)}>
            <Icon icon="filter_list" />
          </button>
        ) : (
          filter
        )}
      </div>

      <div style={{paddingTop: isMobile ? height : 0}}>{children}</div>
    </FilterContext.Provider>
  )
}
