import {useEffect} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useNavigate, useSearchParams} from "react-router-dom"
import {FormControlPassword} from "src/components/shared/inputs/form-control-password"
import {FormControl} from "src/components/shared/inputs/form-control"
import authService from "src/services/auth.service"
import logo from "src/assets/images/yume.cloud.png"
import logoDark from "src/assets/icons/icon-dark.svg"
import {useTranslation} from "react-i18next"
import tokenService from "src/services/token.service"
import "./auth.scss"

type FormData = {
  username: string
  password: string
}

function AuthComponent() {
  const form = useForm<FormData>()
  const {t} = useTranslation()
  const {handleSubmit, formState, setError} = form
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const crossToken = searchParams.get("token")

  const onSubmit = handleSubmit(({username, password}) => {
    authService.login(username, password).catch(() => {
      setError("username", {message: ""})
      setError("password", {message: ""})
    })
  })

  const onCrossTokenLogin = async () => {
    const token = await authService.crossTokenLogin(crossToken)
    tokenService.setToken(token)
    authService.getProfile()
  }

  useEffect(() => {
    const sub = authService.logged$.subscribe((logged) => {
      if (logged) navigate("/orders/all")
    })
    return () => sub.unsubscribe()
  }, [navigate])

  useEffect(() => {
    if (crossToken) onCrossTokenLogin()
  }, [crossToken])

  return (
    <FormProvider {...form}>
      <div className="w-full flex flex-col items-center gap-16 md:gap-[120px] bg-dark-light h-screen">
        <div className="flex flex-col items-center gap-3 md:gap-4 mt-10 md:mt-20">
          <img src={logoDark} draggable={false} alt="yume cloud" className="auth-icon" />
          <div className="label text-base md:text-[18px]">{t("auth.label")}</div>
        </div>

        <form className="auth" onSubmit={onSubmit}>
          <div className="flex flex-col">
            <FormControl
              label={t("auth.username")}
              placeholder={t("auth.placeholder.username")}
              name="username"
              className="form-control w-full mb-3"
              params={{required: true}}
            />
            <FormControlPassword
              label={t("auth.password")}
              placeholder={t("auth.placeholder.password")}
              className="form-control w-full mb-3"
              name="password"
              params={{required: true, minLength: 8}}
            />
          </div>

          <button disabled={!formState.isValid} className="btn btn-color-white btn-primary w-full auth__submit-btn">
            {t("auth.login")}
          </button>
        </form>
      </div>
    </FormProvider>
  )
}

export default AuthComponent
