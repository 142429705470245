import Table from "src/components/shared/antd-custom/table"
import type {ColumnsType} from "antd/es/table"
import {useContext, useEffect, useState} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {Link, useNavigate} from "react-router-dom"
import PaginationFormComponent from "src/components/shared/pagination/pagination-form"
import useQueryParams from "src/hooks/useQuertParams"
import {EMPTY_LIST, ListModel} from "src/models/common"
import {
  InventorySetItemModelV2,
  InventorySetModel,
  InventorySetPriceModel
} from "src/models/manager/inventory/inventory-set.model"
import inventorySetService from "src/services/inventory/inventory-set.service"
import {hexToRgbA} from "src/utils/color/hex-to-rgba"
import objectFilter from "src/utils/object.filter"
import {toPrice} from "src/utils/price"
import Icon from "src/components/shared/components/material-icon"
import HeaderContent from "src/components/header/header-content"
import ProductSetFilterComponent, {ProductSetFilterModel} from "./filter"
import {colorPaletes} from "src/components/shared/constants"
import {TableRowSelection} from "antd/es/table/interface"
import {ControlCheckbox} from "src/components/shared/inputs/control-checkbox"
import modalService from "src/components/modal/global/modal.service"
import {ConfirmModal} from "src/components/modal/global/confirmModal"
import {FormControlEntity} from "src/components/shared/inputs/form-control-entity"
import {useTranslation} from "react-i18next"
import {ConfigContext} from "src/app"
import {OrderContext} from "../../orders/details/context/order-context"

function InventorySetAddCell({
  group,
  onSelect
}: {
  group: InventorySetModel
  onSelect: (group: InventorySetModel, count?: number) => void
}) {
  const {t} = useTranslation()
  const form = useForm({defaultValues: {count: 1}})
  const onSubmit = form.handleSubmit((payload) => onSelect(group, payload.count))
  const {isMobile} = useContext(ConfigContext)
  return (
    <FormProvider {...form}>
      <div className="flex gap-2 align-center">
        {isMobile ? (
          <button
            className={`btn btn-black gap-1 px-4 py-3 ${isMobile && "bg-primary bg-opacity-20 rounded-lg h-9 w-9"}`}
            onClick={onSubmit}>
            <Icon icon="add" className={"md:text-white text-primary"} />
          </button>
        ) : (
          <>
            <FormControlEntity name="count" maxcount={50} rootclassname="flex justify-center items-center" />
            <button className="btn btn-primary color-white" onClick={onSubmit}>
              {t("common.add")}
            </button>
          </>
        )}
      </div>
    </FormProvider>
  )
}

export default function ProductSetList({needActions = false}: {needActions?: boolean}) {
  const {t} = useTranslation()
  const {searchParams, setSearchParams} = useQueryParams()
  const archived = searchParams.archived ? searchParams.archived.toLowerCase() === "true" : false
  const {settings, constantsMap, isMobile, hideMobileMenu, showMobileMenu, pointId} = useContext(ConfigContext)
  const [list, setList] = useState<ListModel<InventorySetModel>>(EMPTY_LIST)
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([])
  const {onInventorySetFilter, orderForm, inventoriesIds} = useContext(OrderContext) || {}

  const form = useForm<ProductSetFilterModel>({
    defaultValues: {
      page: 1,
      pageSize: 10,
      rental_point: pointId,
      search: "",
      ...searchParams,
      start_at: orderForm?.watch("rent_start"),
      end_at: orderForm?.watch("rent_end"),
      exclude_ids: inventoriesIds?.join(","),
      archived
    }
  })
  const {watch, reset, setValue} = form
  const navigate = useNavigate()
  const values = watch()
  const isArchived = Boolean(values.archived)

  const mobileColumns: ColumnsType<InventorySetModel> = settings
    ? [
        {
          width: 318,
          title: t("groups.table.name"),
          dataIndex: "name",
          key: "name",
          render: (_, set) => {
            const {id, name, category, unique_id} = set
            const isFree = set.items.every((group) => group.available_cnt >= group.count)
            const status = isFree ? 0 : 2
            const color = colorPaletes[status]

            return (
              <div className="flex flex-col gap-2">
                <p className="font-medium text-[15px] text-test-black">{name}</p>
                <div className={"flex gap-1"}>
                  <p className="font-normal text-[13px] text-gray-test-370">№{id}</p>
                  <p className="font-normal text-[13px] text-gray-test-370">•</p>
                  <p className="font-normal text-[13px] text-gray-test-370">{category}</p>
                  <p className="font-normal text-[13px] text-gray-test-370">•</p>
                  <p className="font-normal text-[13px] text-gray-test-370">{unique_id}</p>
                  <p className="font-normal text-[13px] text-gray-test-370">•</p>
                  <span className="flex justify-center items-center text-[13px] font-normal" style={{color}}>
                    {constantsMap.INVENTORY_STATUS[status]?.label}
                  </span>
                </div>
              </div>
            )
          }
        },
        {
          width: 32,
          title: "",
          key: "group_action",
          hidden: needActions,
          render: (_, group) => (
            <InventorySetAddCell
              group={group}
              onSelect={(group, count) => {
                onInventorySetFilter(group, count).then((ids: number[]) => {
                  if (!ids) return
                  const excludeIds = values?.exclude_ids || ""
                  reset({
                    ...values,
                    exclude_ids: [...(excludeIds !== "" ? excludeIds.split(",") : []), ...ids].join(",")
                  })
                })
              }}
            />
          )
        }
      ]
    : []

  const columns: ColumnsType<InventorySetModel> = settings
    ? [
        {
          width: 60,
          title: "№",
          key: "id",
          dataIndex: "id",
          render: (id) => id
        },
        {
          width: "100%",
          title: t("set.table.name"),
          dataIndex: "name",
          key: "name",
          render: (name) => name,
          sorter: true
        },
        {
          width: 120,
          title: t("set.table.category"),
          dataIndex: "category",
          key: "category",
          render: (category) => constantsMap.INVENTORY_CATEGORIES[category]?.name,
          sorter: true
        },
        {
          width: 120,
          title: t("set.table.unique_id"),
          dataIndex: "unique_id",
          key: "unique_id",
          render: (unique_id) => unique_id,
          sorter: true
        },
        {
          width: 120,
          title: t("set.table.status"),
          key: "status",
          render: (_, set) => {
            const isFree = set.items.every((group) => group.available_cnt >= group.count)
            const status = isFree ? 0 : 2
            const color = colorPaletes[status]
            return (
              <span
                className="flex justify-center items-center h-8 w-full rounded-lg p-2 text-[13px] font-medium"
                style={{color, backgroundColor: hexToRgbA(color, 0.2)}}>
                {constantsMap.INVENTORY_STATUS[status]?.label}
              </span>
            )
          },
          sorter: true
        },
        {
          width: 180,
          title: t("set.table.prices"),
          dataIndex: "prices",
          key: "prices",
          render: (prices: InventorySetPriceModel[]) => (
            <div className="flex gap-1 flex-col">
              {prices.map((price) => (
                <li key={price.id} className="flex items-center justify-between gap-2">
                  <span className="overflow line-clamp-1">
                    {price.name ? price.name : constantsMap.TARIF_TIME_PERIODS[price.period_id]?.name}
                  </span>
                  <span className="text-nowrap btn py-1 px-2 text-sm font-semibold btn-icon">
                    {toPrice(price.price)}
                  </span>
                </li>
              ))}
            </div>
          )
        },
        {
          width: 180,
          title: t("set.table.items"),
          key: "items",
          dataIndex: "items",
          render: (items: InventorySetItemModelV2[]) => (
            <div className="flex gap-1 flex-col">
              {items.map((group) => (
                <li className="flex items-center justify-between gap-2" key={group.id}>
                  <span className="overflow line-clamp-1">{group.group_name}</span>
                  <span className="text-nowrap btn py-1 px-2 text-[13px] btn-icon">
                    {group.inventories_cnt - group.available_cnt} / {group.inventories_cnt}
                  </span>
                </li>
              ))}
            </div>
          )
        },
        ...settings.custom_fields.inventory_set
          .filter((field) => field.table)
          .map((field) => ({
            width: 64,
            title: field.label,
            key: field.name,
            render: (_, set) => set && set.extra && set.extra[field.name]
          })),
        {
          width: 64,
          title: "",
          key: "action",
          hidden: needActions,
          render: (_, group) => (
            <InventorySetAddCell
              group={group}
              onSelect={(group, count) => {
                onInventorySetFilter(group, count).then((ids: number[]) => {
                  if (!ids) return
                  const excludeIds = values?.exclude_ids || ""
                  reset({
                    ...values,
                    exclude_ids: [...(excludeIds !== "" ? excludeIds.split(",") : []), ...ids].join(",")
                  })
                })
              }}
            />
          )
        }
      ]
    : []

  const listSets = async (params: any) =>
    inventorySetService
      .list(params)
      .then(setList)
      .catch((e) => e.response.status === 404 && setValue("page", 1))

  const onMultiInventorySetArchiveModal = async () => {
    const onConfirm = () =>
      Promise.all(selectedRowKeys.map((id) => inventorySetService.archive(id)))
        .then(() => listSets(values))
        .then(() => setSelectedRowKeys([]))
    modalService.open({
      component: (
        <ConfirmModal
          delete={!isArchived}
          confirm_text={t(isArchived ? "common.archive.return" : "common.archive.archive")}
          message={t(isArchived ? "common.archive.to_return.set" : "common.archive.to_archive")}
          onConfirm={onConfirm}
        />
      )
    })
  }

  const rowSelection: TableRowSelection<InventorySetModel> = {
    selectedRowKeys,
    onChange: (keys: number[]) => setSelectedRowKeys(keys),
    columnWidth: 48,
    columnTitle: (
      <ControlCheckbox
        className="form-check-input"
        checked={selectedRowKeys.length !== 0 && selectedRowKeys.length === list.results.length}
        indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length !== list.results.length}
        onChange={() => setSelectedRowKeys((prev) => (prev.length === 0 ? list.results.map((obj) => obj.id) : []))}
      />
    ),
    renderCell: (val: boolean, inventory: InventorySetModel) => (
      <ControlCheckbox
        className="form-check-input"
        checked={val}
        onClick={(e) => e.stopPropagation()}
        onChange={() =>
          setSelectedRowKeys((prev) =>
            prev.includes(inventory.id) ? prev.filter((k) => k !== inventory.id) : [...prev, inventory.id]
          )
        }
      />
    )
  }

  useEffect(() => {
    listSets(values)
    const sub = watch((params, {name}) => {
      if (name !== "search") listSets(params)
      const query = objectFilter(params)
      if (needActions) setSearchParams(query)
    })
    return () => {
      sub.unsubscribe()
      setList(EMPTY_LIST)
    }
  }, [])

  useEffect(() => {
    if (isMobile) hideMobileMenu()
    return () => showMobileMenu()
  }, [isMobile, hideMobileMenu, showMobileMenu])

  return (
    <FormProvider {...form}>
      <div className={isMobile && "-m-4"}>
        {/*<div className={' gap-1 py-5 header-content border-0'}>*/}
        <HeaderContent>
          <div className="flex items-center md:justify-between justify-start md:gap-2 gap-1 header-content">
            {isMobile && <Icon icon={"chevron_left"} className={"text-2xl"} onClick={() => navigate(-1)} />}
            <div className="font-bold text-xl text-test-black">{t("set.header")}</div>
            {!isMobile && (
              <Link to="add" className="btn btn-primary btn-color-white justify-start gap-2 font-semibold text-nowrap">
                <Icon className="text-lg" icon="add" />
                {t("set.add")}
              </Link>
            )}
          </div>
        </HeaderContent>

        <ProductSetFilterComponent />

        <Table
          showHeader={!isMobile}
          className={isMobile && "!rounded-0 !border-0"}
          title={
            needActions
              ? () => (
                  <div className="flex gap-2 items-start justify-end">
                    <div className="flex gap-3">
                      {selectedRowKeys.length > 0 && (
                        <>
                          <button className="btn btn-card gap-2" onClick={onMultiInventorySetArchiveModal}>
                            <Icon icon="archive" />
                            <span className="font-medium text-[13px]">
                              {t(isArchived ? "common.archive.return" : "common.archive.archive")}
                            </span>
                          </button>
                          <div className="vr color-gray-200" style={{margin: "12px 0"}}></div>
                        </>
                      )}
                      <button
                        className={`btn btn-card ${isArchived ? "light" : ""} gap-2`}
                        onClick={() => {
                          setValue("archived", !isArchived)
                          setSelectedRowKeys([])
                        }}>
                        <Icon icon="package_2" />
                        {t("common.archive.label")}
                      </button>
                    </div>
                  </div>
                )
              : undefined
          }
          rowKey={(obj) => obj.id}
          rowSelection={needActions ? rowSelection : undefined}
          dataSource={list.results}
          columns={isMobile ? mobileColumns : columns}
          onNavigate={needActions ? (set) => navigate(`/inventory-sets/${set.id}`) : undefined}
        />
        <PaginationFormComponent className="mt-4" count={list.count} />
      </div>
    </FormProvider>
  )
}
