import {useContext, useEffect, useState} from "react"
import TransportationHeader from "../../details/ui/headers/transportation-header"
import HeaderContent from "src/components/header/header-content"
import ClientForm from "../../details/select/client-selection"
import AddTransfer from "./detail/add-transfer"
import {ConfigContext} from "src/app"
import {useParams} from "react-router"
import transportationService from "src/services/transportation.service"
import {Transfer, TransferDetail} from "src/models/manager/transfer/transfer"
import DriverSelection from "../../details/select/driver-selection"
import {FormProvider, useForm} from "react-hook-form"
import {GroupType} from "src/services/auth.service"
import {useTranslation} from "react-i18next"

interface TransferForm extends Omit<Transfer, "client"> {
  client: number
}

const TransportationCreateGuest = () => {
  const {profile} = useContext(ConfigContext)
  const {hideMobileMenu, showMobileMenu, isMobile} = useContext(ConfigContext)
  const [transfer, setTransfer] = useState<TransferDetail>(null)
  const {id} = useParams()
  const {t} = useTranslation()
  const isAdmin = profile && profile.group_type === GroupType.ADMIN
  const form = useForm<TransferForm>()
  const {reset, watch, handleSubmit} = form

  const onSubmit = handleSubmit(({client, driver}) => {
    transportationService.patch(+id, {
      client: client,
      driver: driver
    })
  })

  const isValid = isAdmin ? watch("client") && watch("driver") : watch("client")

  useEffect(() => {
    hideMobileMenu()
    return () => showMobileMenu()
  }, [hideMobileMenu, showMobileMenu])

  useEffect(() => {
    transportationService.get(id).then((res) => {
      setTransfer(res)
      reset({...res, client: res.client?.id})
    })
    return () =>
      reset({
        client: null,
        driver: null
      })
  }, [id])

  const deleteRide = (id: number, rideId: number) => {
    transportationService.deleteRides(+id, +rideId).then(() => transportationService.get(id).then(setTransfer))
  }

  return (
    <FormProvider {...form}>
      <HeaderContent>
        <TransportationHeader onSubmit={onSubmit} />
      </HeaderContent>

      <div className="-mx-4 -mt-4 md:mx-0 md:mt-0 gap-0.5">
        <div className="m-auto max-w-[720px] flex flex-col gap-0.5 md:gap-6">
          {isAdmin && <DriverSelection />}
          <ClientForm rideId={+id} />
          {transfer && <AddTransfer isAdmin={isAdmin} transfer={transfer} deleteRide={deleteRide} />}
        </div>

        {isMobile && (
          <div className="fixed left-0 bottom-0 right-0 flex items-center w-full shadow-menu bg-white min-h-[72px] z-[100]">
            <div className="flex gap-1 flex-col w-full px-4 py-2 md:px-0 md:py-0">
              <button
                disabled={!isValid}
                className="btn btn-primary btn-color-white text-base font-medium leading-5 py-4"
                onClick={onSubmit}>
                {t("common.save")}
              </button>
            </div>
          </div>
        )}
      </div>
    </FormProvider>
  )
}

export default TransportationCreateGuest
