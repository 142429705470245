import {lazy} from "react"
import {Navigate, RouteObject} from "react-router-dom"
import CustomSuspense from "src/components/custom-suspense"
import UserPermissionComponent from "src/pages/accounts"
import ProfileEditComponent from "src/pages/profile/edit"
import ProfileComponent from "src/pages/profile"
import ProfileActivityComponent from "src/pages/profile/activity"
import MaintenanceList from "../pages/maintenance/list/maintenance-list"
import MaintenanceHistory from "../pages/maintenance/list/maintenance-history"
import MyTariff from "../pages/profile/my-tariff/my-tariff"
import {ProtectedRoute} from "./guards/protected-route"
import ConfigTelegramComponent from "src/pages/config/telegram"

const ConfigListComponent = lazy(() => import("src/pages/config/config"))
const ConfigClientTickComponent = lazy(() => import("src/pages/config/config-client-ticks"))
const ConfigDiscountComponent = lazy(() => import("src/pages/config/config-discounts"))
const ConfigInventoryCategoryComponent = lazy(() => import("src/pages/config/config-category"))
const ConfigPaymentTypeComponent = lazy(() => import("src/pages/config/config-payment-type"))
const ConfigRentalDurationComponent = lazy(() => import("src/pages/config/config-rental-duriation"))
const ConfigRentalPointsComponent = lazy(() => import("src/pages/config/config-rental-points"))
const ConfigServicesComponent = lazy(() => import("src/pages/service/list"))
const TarifListComponent = lazy(() => import("src/pages/config/config-tarifs"))
const DocumentList = lazy(() => import("src/pages/config/documents/list"))
const DocumentTemplate = lazy(() => import("src/pages/config/documents/template"))
const UserPermissionGroupEdit = lazy(() => import("src/pages/accounts/group/group-edit"))
const UserStaffEdit = lazy(() => import("src/pages/accounts/user/user-edit"))
const InventorizationComponent = lazy(() => import("src/pages/inventorization/inventorization"))
const InventorizationHistoryComponent = lazy(() => import("src/pages/inventorization/inventorization-history"))
const InventorizationTaskListComponent = lazy(() => import("src/pages/inventorization/inventorization-tasklist"))
const MaintenanceComponent = lazy(() => import("src/pages/maintenance/maintenance"))
const ServiceComponent = lazy(() => import("src/pages/service/edit"))
const UserList = lazy(() => import("src/pages/accounts/user/list"))
const UserPermissionGroupList = lazy(() => import("src/pages/accounts/group/list"))
const ConfigClientAttactionMethodComponent = lazy(() => import("src/pages/config/config-client-attraction-methods"))

export const configRoutes: RouteObject[] = [
  {
    path: "services",
    element: (
      <ProtectedRoute
        requiredAccessKey="groups.services"
        element={
          <CustomSuspense>
            <ConfigServicesComponent />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "services/add",
    element: <ProtectedRoute requiredAccessKey="groups.services" element={<ServiceComponent />} />
  },
  {
    path: "services/:id/edit",
    element: <ProtectedRoute requiredAccessKey="groups.services" element={<ServiceComponent />} />
  },
  {
    path: "config/documents/add",
    element: (
      <ProtectedRoute
        requiredAccessKey="config.documents"
        element={
          <CustomSuspense>
            <DocumentTemplate />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "config/documents/:id",
    element: (
      <ProtectedRoute
        requiredAccessKey="config.documents"
        element={
          <CustomSuspense>
            <DocumentTemplate />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "config",
    element: (
      <ProtectedRoute
        requiredAccessKey="config"
        element={
          <CustomSuspense>
            <ConfigListComponent />
          </CustomSuspense>
        }
      />
    ),
    children: [
      {
        path: "",
        element: <ProtectedRoute requiredAccessKey="config" element={<Navigate to="rental-points" replace={true} />} />
      },
      {
        path: "rental-points",
        element: (
          <ProtectedRoute
            requiredAccessKey="config.rental-points"
            element={
              <CustomSuspense>
                <ConfigRentalPointsComponent />
              </CustomSuspense>
            }
          />
        )
      },
      {
        path: "lease-terms",
        element: (
          <ProtectedRoute
            requiredAccessKey="config.lease-terms"
            element={
              <CustomSuspense>
                <ConfigRentalDurationComponent />
              </CustomSuspense>
            }
          />
        )
      },
      {
        path: "discounts",
        element: (
          <ProtectedRoute
            requiredAccessKey="config.discounts"
            element={
              <CustomSuspense>
                <ConfigDiscountComponent />
              </CustomSuspense>
            }
          />
        )
      },
      {
        path: "client-ticks",
        element: (
          <ProtectedRoute
            requiredAccessKey="config.client-ticks"
            element={
              <CustomSuspense>
                <ConfigClientTickComponent />
              </CustomSuspense>
            }
          />
        )
      },
      {
        path: "inventory-category",
        element: (
          <ProtectedRoute
            requiredAccessKey="config.inventory-category"
            element={
              <CustomSuspense>
                <ConfigInventoryCategoryComponent />
              </CustomSuspense>
            }
          />
        )
      },
      {
        path: "attraction-methods",
        element: (
          <ProtectedRoute
            requiredAccessKey="config.attraction-methods"
            element={
              <CustomSuspense>
                <ConfigClientAttactionMethodComponent />
              </CustomSuspense>
            }
          />
        )
      },
      {
        path: "services",
        element: (
          <ProtectedRoute
            requiredAccessKey="config.services"
            element={
              <CustomSuspense>
                <ConfigServicesComponent />
              </CustomSuspense>
            }
          />
        )
      },
      {
        path: "payment-types",
        element: (
          <ProtectedRoute
            requiredAccessKey="config.payment-types"
            element={
              <CustomSuspense>
                <ConfigPaymentTypeComponent />
              </CustomSuspense>
            }
          />
        )
      },
      {
        path: "tarifs",
        element: (
          <ProtectedRoute
            requiredAccessKey="config.tarifs"
            element={
              <CustomSuspense>
                <TarifListComponent />
              </CustomSuspense>
            }
          />
        )
      },
      {
        path: "documents",
        element: (
          <ProtectedRoute
            requiredAccessKey="config.documents"
            element={
              <CustomSuspense>
                <DocumentList />
              </CustomSuspense>
            }
          />
        )
      },
      {
        path: "*",
        element: <Navigate to="rental-points" replace={true} />
      }
    ]
  },
  {
    path: "inventorization",
    element: (
      <ProtectedRoute
        requiredAccessKey="inventorization"
        element={
          <CustomSuspense>
            <InventorizationComponent />
          </CustomSuspense>
        }
      />
    ),
    children: [
      {
        path: "",
        element: <Navigate to="tasklist" relative="route" replace={true} />
      },
      {
        path: "tasklist",
        element: (
          <CustomSuspense>
            <InventorizationTaskListComponent />
          </CustomSuspense>
        )
      },
      {
        path: "history",
        element: (
          <CustomSuspense>
            <InventorizationHistoryComponent params={{}} />
          </CustomSuspense>
        )
      }
    ]
  },
  {
    path: "maintenance",
    element: (
      <ProtectedRoute
        requiredAccessKey="maintenance"
        element={
          <CustomSuspense>
            <MaintenanceComponent />
          </CustomSuspense>
        }
      />
    ),
    children: [
      {
        path: "",
        element: <Navigate to="list" relative="route" replace={true} />
      },
      {
        path: "list",
        element: (
          <CustomSuspense>
            <MaintenanceList />
          </CustomSuspense>
        )
      },
      {
        path: "list/history",
        element: (
          <CustomSuspense>
            <MaintenanceHistory />
          </CustomSuspense>
        )
      }
    ]
  },
  {
    path: "users",
    element: <ProtectedRoute requiredAccessKey="users" element={<UserPermissionComponent />} />,
    children: [
      {
        path: "",
        element: <ProtectedRoute requiredAccessKey="users" element={<Navigate to="main" replace={true} />} />
      },
      {
        path: "main",
        element: (
          <ProtectedRoute
            requiredAccessKey="users.main"
            element={
              <CustomSuspense>
                <UserList params={{is_staff: true}} addLink="/users/add" />
              </CustomSuspense>
            }
          />
        )
      },
      {
        path: "sublease",
        element: (
          <ProtectedRoute
            requiredAccessKey="users.sublease"
            element={
              <CustomSuspense>
                <UserList params={{is_sublease: true}} addLink="/users/sublease/add" />
              </CustomSuspense>
            }
          />
        )
      },
      {
        path: "roles",
        element: (
          <ProtectedRoute
            requiredAccessKey="users.roles"
            element={
              <CustomSuspense>
                <UserPermissionGroupList />
              </CustomSuspense>
            }
          />
        )
      }
    ]
  },
  {
    path: "users/add",
    element: (
      <ProtectedRoute
        requiredAccessKey="users"
        element={
          <CustomSuspense>
            <UserStaffEdit back="/users" params={{is_staff: true}} />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "users/:id",
    element: (
      <ProtectedRoute
        requiredAccessKey="users"
        element={
          <CustomSuspense>
            <UserStaffEdit back="/users" params={{is_staff: true}} />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "users/sublease/add",
    element: (
      <ProtectedRoute
        requiredAccessKey="users"
        element={
          <CustomSuspense>
            <UserStaffEdit back="/users/sublease" params={{is_sublease: true}} />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "users/sublease/:id",
    element: (
      <ProtectedRoute
        requiredAccessKey="users"
        element={
          <CustomSuspense>
            <UserStaffEdit back="/users/sublease" params={{is_sublease: true}} />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "users/roles/:id",
    element: (
      <ProtectedRoute
        requiredAccessKey="users"
        element={
          <CustomSuspense>
            <UserPermissionGroupEdit />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "users/roles/add",
    element: (
      <ProtectedRoute
        requiredAccessKey="users"
        element={
          <CustomSuspense>
            <UserPermissionGroupEdit />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "profile",
    element: <ProfileComponent />,
    children: [
      {
        path: "",
        element: <Navigate to="about" replace={true} />
      },
      {path: "about", element: <ProfileEditComponent />},
      {path: "activity", element: <ProfileActivityComponent />},
      {path: "telegram", element: <ConfigTelegramComponent />},
      {path: "subscription", element: <MyTariff />}
    ]
  }
]
