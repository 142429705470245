import {useContext, useMemo} from "react"
import {ConfigContext} from "src/app"
import {useTranslation} from "react-i18next"
import Icon from "../../../components/shared/components/material-icon"
import toDate from "../../../utils/date"
import subscriptionModalService from "../../../services/subscription.service"

function Permission({permitted, children}: {permitted: boolean; children: JSX.Element | string}) {
  return (
    <div className="flex gap-2 items-center text-[15px]">
      {permitted ? <Icon icon="check" className="color-green" /> : <Icon icon="close" className="color-red" />}
      {children}
    </div>
  )
}
export default function CurrentTariffCard() {
  const {tenant} = useContext(ConfigContext)
  const {limits, subscription, start_at, end_at, period} = tenant
  const {i18n, t} = useTranslation()

  const totalPeriod = useMemo(() => {
    if (!tenant) return null
    if (!period) return null
    return `${period.months} ${t("common.times_abb.month")} ${period.days} ${t("common.times_abb.day")}`
  }, [tenant])

  return (
    period &&
    subscription && (
      <div className="flex flex-col gap-6 w-full p-6 bg-white card md:shadow md:border-gray-100">
        <div className="flex flex-col gap-2">
          <p className="font-bold text-[32px] text-test-black">{subscription.name}</p>
          <p className="font-normal text-[13px] text-gray-400">Текущий тариф</p>
        </div>

        <div className="flex gap-3 w-full justify-between">
          <div className="grid grid-cols-2 gap-y-3 row justify-between flex-wrap w-4/5">
            {limits && (
              <>
                <Permission permitted={true}>
                  {limits.rental_point !== "unlimited" ? (
                    <span>
                      <span className="font-semibold">
                        {limits.rental_point} {t("profile.subscription.tarif.rental_point1")}
                        {i18n.language === "ru" && limits.rental_point > 1 && "а"}
                      </span>{" "}
                      {t("profile.subscription.tarif.rental_point2")}
                      {i18n.language === "en" && limits.rental_point > 1 && "s"}
                    </span>
                  ) : (
                    <span>
                      Пункты проката
                      <span className="font-semibold"> без лимита</span>
                    </span>
                  )}
                </Permission>
                <Permission permitted={true}>
                  {limits.staff !== "unlimited" ? (
                    <span>
                      <span className="font-semibold">
                        {limits.staff}{" "}
                        {limits.staff > 1
                          ? t("profile.subscription.tarif.accounts")
                          : t("profile.subscription.tarif.account")}
                      </span>{" "}
                      {limits.staff > 1
                        ? t("profile.subscription.tarif.for_staff")
                        : t("profile.subscription.tarif.for_staffs")}
                    </span>
                  ) : (
                    <span>
                      Аккаунты
                      <span className="font-semibold"> без лимита</span>
                    </span>
                  )}
                </Permission>
                <Permission permitted={true}>
                  {limits.inventory_group === "unlimited" ? (
                    <span>
                      {t("profile.subscription.tarif.product_count")}{" "}
                      <span className="font-semibold">{t("profile.subscription.tarif.unlimited")}</span>
                    </span>
                  ) : (
                    <span>
                      <span className="font-semibold">
                        {limits.inventory_group}{" "}
                        {+limits.inventory_group > 1
                          ? t("profile.subscription.tarif.position")
                          : t("profile.subscription.tarif.positions")}{" "}
                      </span>{" "}
                      {+limits.inventory_group > 1
                        ? t("profile.subscription.tarif.product")
                        : t("profile.subscription.tarif.products")}
                    </span>
                  )}
                </Permission>
                <Permission permitted={true}>
                  {limits.request === "unlimited" ? (
                    <span>
                      {t("profile.subscription.tarif.rent_count")}{" "}
                      <span className="font-semibold">{t("profile.subscription.tarif.unlimited")}</span>
                    </span>
                  ) : (
                    <span>
                      <span className="font-semibold">
                        {limits.request}{" "}
                        {+limits.request > 1
                          ? t("profile.subscription.tarif.rent")
                          : t("profile.subscription.tarif.rentals")}
                      </span>{" "}
                      {t("profile.subscription.tarif.monthly")}
                    </span>
                  )}
                </Permission>
                <Permission
                  permitted={limits.sale}
                  children={t("profile.subscription.tarif.accounting_rent_and_prod")}
                />
                <Permission permitted={limits.sale} children={t("profile.subscription.tarif.accounting_sells")} />
                <Permission permitted={limits.document} children={t("profile.subscription.tarif.document")} />
                <Permission permitted={limits.metrics} children={t("profile.subscription.tarif.metrics")} />
                <Permission permitted={limits.bonus} children={t("profile.subscription.tarif.bonus")} />
                <Permission permitted={limits.custom_fields} children={t("profile.subscription.tarif.custom_fields")} />
              </>
            )}
          </div>
          <div className="flex flex-col gap-4 w-1/5 font-medium text-[13px]">
            <button className="btn btn-primary btn-color-white h-12 max-w-[223px]">Оплатить</button>
            <button
              onClick={() => subscriptionModalService.setShow({show: true, type: null})}
              className="btn bg-gray-test-70 hover:bg-gray-100 h-12 max-w-[223px]">
              Сменить тариф
            </button>
          </div>
        </div>

        <hr className="color-gray-300 w-full" />

        <div className="flex gap-12 text-[13px]">
          <div className="flex flex-col gap-2">
            <p className="font-normal text-gray-400">Дата приобретения</p>
            <p className="font-medium text-test-black">{toDate(start_at)}</p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-normal text-gray-400">Действительно до</p>
            <p className="font-medium text-test-black">{toDate(end_at)}</p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-normal text-gray-400">Период подписки</p>
            <p className="font-medium text-test-black">{totalPeriod}</p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-normal text-gray-400">Оплаченная сумма</p>
            <p className="font-medium text-test-black">{Math.trunc(period.price) + " " + period.currency}</p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-normal text-gray-400">Способ оплаты</p>
            <p className="font-medium text-test-black"></p>
          </div>
        </div>

        <div className="flex gap-2 text-gray-test-350 text-xl items-center">
          <Icon icon="info" className="text-xl text-gray-test-350" />
          <p className="font-normal text-[13px] text-gray-test-350">Есть автоматическое продление тарифа</p>
        </div>
      </div>
    )
  )
}
