import {useTranslation} from "react-i18next"
import {Outlet} from "react-router"
import HeaderContent from "src/components/header/header-content"

export default function UserPermissionComponent() {
  const {t} = useTranslation()

  return (
    <>
      <HeaderContent>
        <div className="text-2xl font-semibold header-content">{t("users.header")}</div>
      </HeaderContent>

      <Outlet />
    </>
  )
}
