import React, {useContext, useEffect, useState} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {FormControlDaterange} from "src/components/shared/inputs/form-control-daterange"
import {useTranslation} from "react-i18next"
import {ConfigContext} from "src/app"
import metricsService from "src/services/metrics/products-parts.service"
import {ListParams} from "src/models/common"
import Icon from "src/components/shared/components/material-icon"
import {InventoryGroupEarningAggregateModel} from "../../../models/manager/metrics/payback-product"
import CarListTableComponent from "./list"

interface Filter extends ListParams {
  start_at: string
  end_at: string
  type: number
}

function Diff({
  diff,
  className = "text-xs font-medium",
  icon = true
}: {
  diff: number
  className?: string
  icon?: boolean
}) {
  const progress = diff >= 0
  const iconWrapperStyle = {
    padding: 2,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "content"
  }
  return (
    <span className="flex gap-1 items-center">
      {icon && (
        <>
          {progress ? (
            <span className="bg-accept" style={iconWrapperStyle}>
              <Icon icon="arrow_upward_alt" className="text-xs color-white font-bold" />
            </span>
          ) : (
            <span className="bg-primary" style={iconWrapperStyle}>
              <Icon icon="arrow_downward_alt" className="text-xs color-white font-bold" />
            </span>
          )}
        </>
      )}
      <span className={`${className} ${progress ? "color-green" : "color-red"}`}>
        {progress && "+"}
        {diff}%
      </span>
    </span>
  )
}

const ReportCarParkComponent = () => {
  const {t} = useTranslation()
  const {isMobile} = useContext(ConfigContext)
  const form = useForm<Filter>({
    defaultValues: {
      page: 1,
      pageSize: 10,
      search: ""
    }
  })
  const [reportStats, setReportStats] = useState<InventoryGroupEarningAggregateModel>(null)

  const getRidesInfo = async () => {
    metricsService.listInventoryGroupPaybackGeneral().then(setReportStats)
  }

  useEffect(() => {
    getRidesInfo()
  }, [])
  return (
    <FormProvider {...form}>
      <div className="max-w-[400px] mb-4 md:mb-6">
        <FormControlDaterange className="form-control" name_start="start_at" name_end="end_at" />
      </div>

      {reportStats && (
        <div className="card-wrapper shadow max-w-[400px] mb-4 md:mb-6">
          <div className={isMobile ? "w-full flex items-center" : "row"}>
            <div className={isMobile ? " mb-2" : "col mb-2"}>
              <div className="text-[13px] color-gray-300 mb-2">{t("metrics.finance.count_title")}</div>
              <div className="flex md:flex-row flex-col gap-2 md:items-end items-start">
                <span className="text-lg leading-5 font-bold color-black">{reportStats.inventory_cnt}</span>
              </div>
            </div>

            {isMobile && <span className="border-r border-gray-200 h-4 mx-4" />}
            <div className={isMobile ? " mb-2" : "col mb-2"}>
              <div className="text-[13px] color-gray-300 mb-2">{t("metrics.finance.earning_avg")}</div>
              <div className="flex md:flex-row flex-col gap-2 md:items-end items-start">
                {/*<span className="text-lg leading-5 font-bold color-black">{toPrice(+reportStats.price_avg)}</span>*/}
              </div>
            </div>
          </div>
        </div>
      )}

      <CarListTableComponent form={form} />
    </FormProvider>
  )
}

export default ReportCarParkComponent
