import React, {useContext, useEffect, useState} from "react"
import PointStepper from "../../../details/ui/point-stepper"
import {TransferRideModel} from "src/models/manager/transfer/transfer"
import ridesService from "src/services/rides.service"
import {useParams} from "react-router"
import HeaderContent from "src/components/header/header-content"
import Icon from "src/components/shared/components/material-icon"
import {ConfigContext} from "src/app"
import {useNavigate} from "react-router-dom"
import {useTranslation} from "react-i18next"

const TransferStepper = ({className}: {className?: string}) => {
  const {rideId} = useParams()
  const [ride, setRide] = useState<TransferRideModel>(null)
  const {t} = useTranslation()
  const {hideMobileMenu, showMobileMenu, isMobile} = useContext(ConfigContext)
  useEffect(() => {
    hideMobileMenu()
    return () => showMobileMenu()
  }, [hideMobileMenu, showMobileMenu])

  useEffect(() => {
    ridesService.get(+rideId).then(setRide)
  }, [])

  return (
    <>
      <PointStepper ride={ride} className={className} />
    </>
  )
}

export default TransferStepper
