import React, {useContext, useEffect, useState} from "react"
import inventoriesService from "src/services/inventory/inventory.service"
import clientService from "src/services/clients.service"
import driverService from "src/services/driver.service"
import HeaderContent from "src/components/header/header-content"
import Icon from "src/components/shared/components/material-icon"
import {ConfigContext} from "src/app"
import {useTranslation} from "react-i18next"
import {useNavigate} from "react-router-dom"

const TransportationDatabase = () => {
  const [cars, setCars] = useState(null)
  const [clients, setClients] = useState(null)
  const {isMobile} = useContext(ConfigContext)
  const {t} = useTranslation()
  const navigate = useNavigate()
  const [drivers, setDrivers] = useState(null)
  useEffect(() => {
    inventoriesService.list().then(setCars)
    clientService.list().then(setClients)
    driverService.listDriver().then(setDrivers)
  }, [])

  const data = [
    {title: "Водители", data: drivers, route: "driver"},
    {title: "Клиенты", data: clients, route: "client"},
    {title: "Машины", data: cars, route: "car"}
  ]

  return (
    <>
      <HeaderContent>
        <div className="flex items-center justify-between gap-2 w-full px-4 py-3">
          <div className="flex gap-2 items-center">
            {isMobile && <Icon icon="chevron_left" className="text-2xl" onClick={() => navigate(-1)} />}
            <div className="text-lg font-semibold md:text-2xl">DataBase</div>
          </div>
        </div>
      </HeaderContent>

      <div className="-mx-4 -mt-3 md:mx-0 md:mt-0">
        <div className="max-w-[768px] m-auto flex flex-col gap-0.5">
          {data.map((section) => (
            <div
              key={section.route}
              className="flex justify-between items-center gap-3 bg-white flex-1 p-4 md:first:rounded-t-lg md:[&:nth-last-child(2)]:rounded-b-lg"
              onClick={() => navigate(`${section.route}/`)}>
              <div className="flex flex-col gap-1">
                <div className="font-medium">{section.title}</div>
                <div className="text-primary">{section?.data?.count} в базе</div>
              </div>
              <div>
                <Icon icon="chevron_right" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default TransportationDatabase
