import axios from "axios"
import {ApiAbstract} from "../../abstract/api.abstract"
import {OrderRequestPaymentModel} from "src/models/manager/order/order-request-payment"
import {BehaviorSubject, Observable, from, shareReplay, switchMap} from "rxjs"
import {axiosResponce} from "src/interceptor/axios-response"
import {OrderRequestRefundModel} from "src/models/manager/order/order-request-refund"

export class ManageOrderPaymentsService extends ApiAbstract {
  constructor() {
    super("v1/crm/requests")
  }

  private listPayments$: BehaviorSubject<number> = new BehaviorSubject(null)

  public listPayments = (orderId: number) => this.listPayments$.next(orderId)

  public payments$: Observable<OrderRequestPaymentModel[]> = this.listPayments$.pipe(
    switchMap((orderId) =>
      from(axios.get<OrderRequestPaymentModel>(this.getUrl(`${orderId}/payments`))).pipe(axiosResponce)
    ),
    shareReplay(1)
  )

  public async post(orderId: number, payload: any): Promise<OrderRequestPaymentModel> {
    const response = await axios.post(this.getUrl(`${orderId}/payments`), payload)
    this.listPayments(orderId)
    return response.data
  }

  public async createRefund(orderId: number, payload: any): Promise<any> {
    const response = await axios.post(this.getUrl(`${orderId}/refunds`), payload)
    this.listPayments(orderId)
    return response.data
  }

  public async delete(orderId: number, id: number): Promise<undefined> {
    const response = await axios.delete(this.getUrl(`${orderId}/payments/${id}`))
    this.listPayments(orderId)
    return response.data
  }
}

export default new ManageOrderPaymentsService()
