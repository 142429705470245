import {InventoryStateStatus} from "src/models/manager/constants"
import {useTranslation} from "react-i18next"
import {useContext} from "react"
import {ConfigContext} from "src/app"
import {useFormContext} from "react-hook-form"
import FormSelect, {FormSelectConfig} from "../../inputs/form-select"

type RemovalKeys = "getOptionLabel" | "getOptionValue" | "options"

export function SharedInventoryStateSelect(config: Omit<FormSelectConfig<InventoryStateStatus>, RemovalKeys>) {
  const {t} = useTranslation()
  const {constants, constantsMap} = useContext(ConfigContext)
  const form = useFormContext()
  const value = form.watch(config.name)

  return (
    <FormSelect<InventoryStateStatus>
      {...config}
      options={constants.INVENTORY_STATE_STATUS}
      value={constantsMap.INVENTORY_STATE_STATUS[+value]}
      placeholder={t("common.select.state_status")}
      getOptionLabel={(option: InventoryStateStatus) => (option ? String(option.name) : null)}
      getOptionValue={(option: InventoryStateStatus) => (option ? String(option.id) : null)}
    />
  )
}
