import React, {useEffect} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {FormControl} from "src/components/shared/inputs/form-control"
import modalService from "src/components/modal/global/modal.service"
import transportationService from "src/services/transportation.service"
import {useTranslation} from "react-i18next"

interface TransferCityModalProps {
  fetchCities: any
  is_put?: boolean
  currentCity?: any
  cityId?: any
}

const TransferCityModal = ({fetchCities, cityId, currentCity}: TransferCityModalProps) => {
  const form = useForm()
  const {t} = useTranslation()
  useEffect(() => {
    if (cityId && currentCity) {
      form.setValue("name", currentCity.name)
    }
  }, [cityId, currentCity, form])

  const onSubmit = async (payload: any) => {
    if (cityId) {
      await transportationService.patchCity(+cityId, payload)
    } else {
      await transportationService.postCity(payload)
    }
    fetchCities()
    modalService.closeModal()
  }

  return (
    <FormProvider {...form}>
      <div className="flex flex-col gap-6">
        <div className="text-lg font-semibold md:text-2xl">{cityId ? currentCity.name : "New city"}</div>
        <div>
          <FormControl
            name="name"
            type="text"
            label={t("transfers.main.city")}
            required
            params={{required: true}}
            rootclassname="flex-1"
            className="form-control"
          />
        </div>
        <button
          type="submit"
          className="w-full btn btn-primary btn-color-white text-base font-medium leading-5 py-4 mt-4"
          onClick={() => form.handleSubmit(onSubmit)()}>
          {t("common.save")}
        </button>
      </div>
    </FormProvider>
  )
}

export default TransferCityModal
