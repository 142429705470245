import {App, ConfigProvider} from "antd"
import ruRU from "antd/locale/ru_RU"
import enUS from "antd/locale/en_US"
import {initializeApp} from "firebase/app"
import ReactDOM from "react-dom/client"
import {RouterProvider, createBrowserRouter} from "react-router-dom"
import {I18nextProvider, useTranslation} from "react-i18next"
import moment from "moment-timezone"
import i18n from "./i18n"
import "src/interceptor"
import "react-toastify/dist/ReactToastify.css"
import "moment/locale/ru"
import "./index.scss"
import {isProd} from "./utils/isProd"
import * as Sentry from "@sentry/react"
import {routes} from "./routers"

moment.tz.setDefault("Asia/Tashkent")
moment.locale("ru")

if (isProd) {
  Sentry.init({
    dsn: "https://d88b8aa05ef4e7a09e36b03eaec25c8f@o4508378058129408.ingest.de.sentry.io/4508378060554320",
    ignoreErrors: [
      "CKEditorError",
      "Request failed with status code 401",
      "Request failed with status code 403",
      "Request failed with status code 404"
    ],
    integrations:
      window.innerWidth >= 768
        ? [
            Sentry.feedbackIntegration({
              colorScheme: "light",
              isNameRequired: true,
              isEmailRequired: false,
              showBranding: false,
              triggerLabel: "Сообщить об ошибке",
              triggerAriaLabel: "Сообщить об ошибке",
              formTitle: "Сообщить об ошибке",
              submitButtonLabel: "Отправить отчет об ошибке",
              cancelButtonLabel: "Отмена",
              confirmButtonLabel: "Подтвердить",
              addScreenshotButtonLabel: "Добавить снимок экрана",
              removeScreenshotButtonLabel: "Удалить снимок экрана",
              nameLabel: "Имя",
              namePlaceholder: "Ваше имя",
              emailLabel: "Электронная почта",
              emailPlaceholder: "example@email.com",
              isRequiredLabel: "(обязательно)",
              messageLabel: "Описание",
              messagePlaceholder: "В чем ошибка? Что вы ожидали?",
              successMessageText: "Спасибо за ваш отчет!"
            })
          ]
        : []
  })

  const firebaseConfig = {
    apiKey: "AIzaSyAhiRARLU6Tf43VJyeQe1gLvGORkIwJxj0",
    authDomain: "yume-admin.firebaseapp.com",
    projectId: "yume-admin",
    storageBucket: "yume-admin.appspot.com",
    messagingSenderId: "195215475313",
    appId: "1:195215475313:web:458baeb90d54a23cd091d0"
  }

  initializeApp(firebaseConfig)
}

const root = ReactDOM.createRoot(document.getElementById("root"))

const router = createBrowserRouter(routes)

const antLocale = {
  ru: ruRU,
  en: enUS
}

function ConfigWrapper() {
  const {i18n} = useTranslation()
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#000000",
          colorPrimaryBg: "#e2e2e2",
          fontSize: 14,
          borderRadius: 8,
          colorInfo: "#000000",
          lineHeight: 1.32,
          fontFamily:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;'
        },
        components: {
          Table: {},
          Checkbox: {
            controlInteractiveSize: 24,
            borderRadius: 6,
            borderRadiusLG: 6,
            borderRadiusXS: 6,
            borderRadiusSM: 6,
            colorBorder: "var(--color-gray-200)",
            colorBgContainer: "var(--color-white)",
            colorBgContainerDisabled: "var(--color-gray-100)"
          }
        }
      }}
      locale={antLocale[i18n.language] || ruRU}>
      <App>
        <RouterProvider router={router} />
      </App>
    </ConfigProvider>
  )
}

root.render(
  <I18nextProvider i18n={i18n}>
    <ConfigWrapper />
  </I18nextProvider>
)
