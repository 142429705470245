import {ClientTickModel} from "src/models/manager/constants"
import {FormSelectAsyncConfig} from "../../inputs/form-select-async.list"
import appService from "src/services/app.service"
import GenericEntityService from "src/services/generic-entity.service"
import {ConfigAddOrEdit} from "src/pages/config/config-add-or-edit"
import modalService, {ExtendedModalConfig} from "src/components/modal/global/modal.service"
import {FormControl} from "../../inputs/form-control"
import {FormControlColor} from "../../inputs/form-control-color"
import {hexToRgbA} from "src/utils/color/hex-to-rgba"
import {colourStyles} from "../../inputs/form-select-color-style"
import {StylesConfig} from "react-select"
import {useTranslation} from "react-i18next"
import {useContext} from "react"
import {ConfigContext} from "src/app"
import FormSelect from "../../inputs/form-select"
import {useFormContext} from "react-hook-form"

type RemovalKeys = "listOptions" | "getValue" | "getOptionLabel" | "getOptionValue" | "isMulti" | "modalProps"

export const styles: StylesConfig<ClientTickModel, true> = {
  ...colourStyles(false),
  option: (styles, props) => {
    const {data, isDisabled, isFocused, isSelected} = props
    const color = () => {
      if (isDisabled) return hexToRgbA(data.code, 0.4)
      if (isSelected) return hexToRgbA(data.code)
      if (isFocused) return hexToRgbA(data.code, 1)
      return hexToRgbA(data.code)
    }
    return {
      ...colourStyles(false).option(styles, props),
      fontWeight: 500,
      color: color(),
      "&:focus": {
        color: color()
      },
      "&:hover": {
        color: color()
      }
    }
  }
}

export function SharedClientTickSelect(config: Omit<FormSelectAsyncConfig<ClientTickModel>, RemovalKeys>) {
  const {t} = useTranslation()
  const form = useFormContext()
  const {constants, constantsMap} = useContext(ConfigContext)
  const value = form.watch(config.name, [])?.map((val: number) => (val ? constantsMap.CLIENT_TICKS[+val] : null))
  const service = new GenericEntityService<ClientTickModel>("v1/crm/clients/ticks")

  const onCreate = async (payload: any) => {
    payload.comment = payload.comment || null
    const res = await service.post(payload)
    appService.loadConstants()
    return res
  }

  const modalProps: ExtendedModalConfig = {
    text: t("configuration.client_ticks.new_rating"),
    props: {backdrop: true, dialogClassName: "w-[480px]"},
    component: (
      <ConfigAddOrEdit<ClientTickModel>
        buttonTitle="Добавить"
        onSubmit={(payload) => onCreate(payload).then(() => modalService.closeModal())}
        title="Новый рейтинга для клиента"
        mode="add">
        <div className="flex flex-1 gap-2">
          <FormControl
            name="name"
            rootclassname="col"
            className="form-control"
            label="Название"
            placeholder="Название"
            required={true}
            params={{required: true}}
          />
          <FormControlColor label="Цвет" rootclassname="col-2" name="code" />
        </div>
      </ConfigAddOrEdit>
    )
  }

  return (
    <FormSelect<ClientTickModel>
      {...config}
      modalProps={modalProps}
      options={constants.CLIENT_TICKS}
      value={value}
      getOptionLabel={(option: ClientTickModel) => (option ? String(option.name) : null)}
      getOptionValue={(option: ClientTickModel) => (option ? String(option.id) : null)}
      styles={styles}
      isMulti
    />
  )
}
