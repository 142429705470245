import {CategoryModel} from "src/models/manager/constants"
import appService from "src/services/app.service"
import {LabelModel} from "src/models/label"
import GenericEntityService from "src/services/generic-entity.service"
import {ConfigAddOrEdit} from "src/pages/config/config-add-or-edit"
import modalService, {ExtendedModalConfig} from "src/components/modal/global/modal.service"
import {FormControl} from "../../inputs/form-control"
import {useTranslation} from "react-i18next"
import {useFormContext} from "react-hook-form"
import FormSelect, {FormSelectConfig} from "../../inputs/form-select"
import {useContext} from "react"
import {ConfigContext} from "src/app"

type RemovalKeys = "getOptionLabel" | "getOptionValue" | "options" | "modalProps"

interface ClientSelectConfig extends Omit<FormSelectConfig<LabelModel>, RemovalKeys> {
  addOption?: boolean
}

export function SharedClientAttractionSelect(config: ClientSelectConfig) {
  const {t} = useTranslation()
  const {constants, constantsMap} = useContext(ConfigContext)
  const service = new GenericEntityService<LabelModel>("v1/crm/attraction_methods")
  const form = useFormContext()
  const value = form.watch(config.name)

  const onCreate = async (payload) => {
    payload.comment = payload.comment || null
    const res = await service.post(payload)
    appService.loadConstants()
    return res
  }

  const modalProps: ExtendedModalConfig = {
    text: t("configuration.attraction_method.header_add"),
    props: {size: "lg", backdrop: true, dialogClassName: "w-[480px]"},
    component: (
      <ConfigAddOrEdit<CategoryModel>
        onSubmit={(inventory) => onCreate(inventory).then(() => modalService.closeModal())}
        buttonTitle={t("common.add")}
        title={t("configuration.attraction_method.header_add")}
        mode="add">
        <FormControl
          name="name"
          label={t("common.input.name")}
          rootclassname="col"
          className="form-control"
          params={{required: true}}
          required
        />
      </ConfigAddOrEdit>
    )
  }

  return (
    <FormSelect<LabelModel>
      {...config}
      modalProps={config.addOption && modalProps}
      options={constants.CLIENT_ATTRACTION_METHOD}
      value={constantsMap.CLIENT_ATTRACTION_METHOD[+value]}
      getOptionLabel={(option: LabelModel) => (option ? String(option.label) : null)}
      getOptionValue={(option: LabelModel) => (option ? String(option.id) : null)}
    />
  )
}
