import {TenantType} from "src/services/tenant.service"

function createAccess<T extends string>(keys: T[]): Record<T, boolean> {
  return keys.reduce(
    (acc, key) => {
      acc[key] = true
      return acc
    },
    {} as Record<T, boolean>
  )
}

type ConfigKeys =
  | "rental-points"
  | "lease-terms"
  | "discounts"
  | "client-ticks"
  | "inventory-category"
  | "attraction-methods"
  | "services"
  | "payment-types"
  | "tarifs"
  | "documents"

type ReportKeys =
  | "inventory"
  | "sales"
  | "expenses"
  | "managers"
  | "bonuses"
  | "services"
  | "sublease"
  | "inventorization"
  | "clients"
  | "discounts"
  | "drivers"
  | "cars"
  | "transfers"
  | "rides"

type SettingKeys =
  | "main"
  | "rent"
  | "interface"
  | "penalty"
  | "inventory-state"
  | "order-status"
  | "custom-fields"
  | "bonus"
  | "notifications"
  | "managers"
  | "wazzup"

type GroupKeys = "sales" | "sets" | "services"

type IntegrationKeys = "sms" | "gps" | "wazzup" | "widget" | "egov"

type TransferKeys = "all" | "expenses" | "destinations" | "cities"

export type Accesses = {
  orders: false | {}
  calendar: false | {}
  clients: false | {}
  reports: Record<ReportKeys, boolean>
  inventorization: false | {}
  geolocation: false | {}
  maintenance: false | {}
  transportation: false | Record<TransferKeys, {}>
  rides: false | {}
  groups: false | Record<GroupKeys, {}>
  integrations: false | Record<IntegrationKeys, {}>
  users:
    | false
    | {
        main: boolean
        sublease: boolean
        group_type: boolean
        roles: {
          permissionGroups: {
            config: boolean
            client: boolean
            product: boolean
            set: boolean
            inventorization: boolean
            service: boolean
            order: boolean
            document: boolean
            schedule: boolean
            metrics: boolean
          }
        }
      }
  config: false | Record<ConfigKeys, {}>
  settings: false | Record<SettingKeys, {}>
}

export interface TenantTypeConfigs {
  accesses: Accesses
  type: TenantType
  mainRoute: string
}

export type TenantTypes = Record<TenantType, TenantTypeConfigs>

const defaultAccesses: Accesses = {
  orders: true,
  calendar: true,
  groups: {
    sales: true,
    services: true,
    sets: true
  },
  clients: true,
  reports: createAccess<ReportKeys>([
    "sales",
    "inventory",
    "expenses",
    "clients",
    "managers",
    "bonuses",
    "discounts",
    "services",
    "sublease",
    "inventorization"
  ]),
  inventorization: true,
  maintenance: false,
  transportation: false,
  geolocation: true,
  rides: true,
  integrations: {
    sms: true,
    gps: true,
    wazzup: true,
    widget: true,
    egov: true
  },
  users: {
    main: true,
    sublease: true,
    group_type: false,
    roles: {
      permissionGroups: {
        config: true,
        client: true,
        product: true,
        set: true,
        inventorization: true,
        service: true,
        order: true,
        document: true,
        schedule: true,
        metrics: true
      }
    }
  },
  config: {
    "rental-points": true,
    "lease-terms": true,
    discounts: true,
    "client-ticks": true,
    "inventory-category": true,
    "attraction-methods": true,
    services: true,
    "payment-types": true,
    tarifs: true,
    documents: true
  },
  settings: {
    main: true,
    rent: true,
    interface: true,
    penalty: true,
    "inventory-state": true,
    "order-status": true,
    "custom-fields": true,
    bonus: true,
    notifications: true,
    managers: true,
    wazzup: true
  }
}

const tenantsFunctionality: TenantTypes = {
  transfer: {
    accesses: {
      orders: false,
      calendar: false,
      clients: true,
      reports: createAccess<ReportKeys>(["transfers", "drivers", "rides", "clients", "cars"]),
      transportation: {
        all: true,
        expenses: true,
        destinations: true,
        cities: true
      },
      geolocation: false,
      rides: true,
      inventorization: false,
      maintenance: false,
      groups: {
        sales: false,
        services: false,
        sets: false
      },
      integrations: false,
      users: {
        main: true,
        sublease: true,
        group_type: true,
        roles: {
          permissionGroups: {
            config: true,
            client: true,
            product: true,
            set: true,
            inventorization: true,
            service: true,
            order: true,
            document: true,
            schedule: true,
            metrics: true
          }
        }
      },
      config: {
        "rental-points": true,
        "lease-terms": false,
        discounts: false,
        "client-ticks": true,
        "inventory-category": true,
        "attraction-methods": true,
        services: false,
        "payment-types": false,
        tarifs: false,
        documents: false
      },
      settings: {
        main: true,
        rent: true,
        interface: true,
        penalty: true,
        "inventory-state": true,
        "order-status": true,
        "custom-fields": true,
        bonus: true,
        notifications: true,
        managers: true,
        wazzup: true
      }
    },
    type: TenantType.TRANSFER,
    mainRoute: "/rides"
  },
  sport: {
    accesses: defaultAccesses,
    type: TenantType.SPORT,
    mainRoute: "/orders"
  },
  auto: {
    accesses: {...defaultAccesses, maintenance: true},
    type: TenantType.AUTO,
    mainRoute: "/orders"
  },
  default: {
    accesses: defaultAccesses,
    type: TenantType.DEFAULT,
    mainRoute: "/orders"
  }
}

export default tenantsFunctionality
