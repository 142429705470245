import {useTranslation} from "react-i18next"
import FormSelectAsyncPagination, {FormSelectAsyncConfig} from "../../inputs/form-select-async.pagination"
import {ProfileModel} from "src/services/auth.service"
import usersService from "src/services/users"
import {useState} from "react"
import modalService, {ExtendedModalConfig} from "../../../modal/global/modal.service"
import NewSubleaseModal from "../../../modal/new-sublease-modal"
import {useFormContext} from "react-hook-form"

type RemovalKeys = "listOptions" | "getValue" | "getOptionLabel" | "getOptionValue"

export interface SharedProfileProps extends Omit<FormSelectAsyncConfig<ProfileModel>, RemovalKeys> {
  listParams?: any
  addOption?: boolean
}
export function SharedProfileSelect(config: SharedProfileProps) {
  const {t} = useTranslation()
  const [sublease, setSublease] = useState<ProfileModel>()
  const {setValue} = useFormContext()

  const modalProps: ExtendedModalConfig = {
    text: "Новый партнер",
    className: "gap-2",
    props: {dialogClassName: "w-[640px]"},
    component: (
      <NewSubleaseModal
        onUserAdded={(newSublease) => {
          setSublease(newSublease)
          setValue("sublease_user", +newSublease.id, {shouldDirty: true})
          modalService.closeModal()
        }}
      />
    )
  }

  const getSublease = async (id: number) => {
    const sublease = await usersService.get(+id)
    setSublease(sublease)
    return sublease
  }

  return (
    <FormSelectAsyncPagination<ProfileModel>
      placeholder={t("common.select.placeholder.group")}
      {...config}
      name={config.name}
      modalProps={config.addOption && modalProps}
      getValue={getSublease}
      listOptions={(params) => usersService.list({...params, ...config.listParams})}
      getOptionLabel={(option: ProfileModel) => (option ? `${option.first_name} ${option.last_name}` : null)}
      getOptionValue={(option: ProfileModel) => (option ? String(option.id) : null)}
    />
  )
}
