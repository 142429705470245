import axios from "axios"
import {ApiAbstract} from "src/abstract/api.abstract"
import {ListModel} from "src/models/common"
import {TransferAddress, TransferRideModel} from "../models/manager/transfer/transfer"

export class RidesService extends ApiAbstract {
  constructor() {
    super("v1/crm/rides")
  }
  //rides
  public async list(params?: any): Promise<ListModel<any>> {
    const response = await axios.get(this.getUrl(), {params})
    return response.data
  }
  public async get(id?: number): Promise<any> {
    const response = await axios.get(this.getUrl(id))
    return response.data
  }
  public async count(): Promise<any> {
    const response = await axios.get(this.getUrl("count"))
    return response.data
  }
  public async rideStart(id?: number): Promise<TransferRideModel> {
    const response = await axios.post(this.getUrl(`${id}/start`))
    return response.data
  }
  public async rideFinish(id?: number): Promise<any> {
    const response = await axios.post(this.getUrl(`${id}/finish`))
    return response.data
  }
  //driver
  public async listDriver(params?: any): Promise<ListModel<TransferAddress>> {
    const response = await axios.get(this.getUrl(`addresses`), {params})
    return response.data
  }
}

const ridesService = new RidesService()
export default ridesService
