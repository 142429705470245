import {forwardRef} from "react"
import Select, {components, Props} from "react-select"
import {isNil} from "src/utils/isNil"
import makeAnimated from "react-select/animated"
import {SelectMenu, SelectOption as Option} from "./common"
import {ExtendedModalConfig} from "src/components/modal/global/modal.service"
import {colourStyles} from "../form-select-color-style"
const animatedComponents = makeAnimated()

interface ExtendedProps extends Props<any> {
  onModal?: () => void
  label?: string
  name?: string
  required?: boolean
  rootclassname?: string
  modalProps?: ExtendedModalConfig
}

const CustomSelect = forwardRef(
  ({name, label, onModal, modalProps, rootclassname, required, ...config}: ExtendedProps) => {
    return (
      <div className={rootclassname}>
        {label && (
          <div className="flex gap-2 mb-2">
            <label className="overflow line-clamp-1 text-[13px] color-gray-400">{label}</label>
            {required && <div className="form-required"></div>}
          </div>
        )}
        <Select
          styles={colourStyles(false)}
          {...config}
          name={name}
          // menuPortalTarget={document.getElementById('vhContent')}
          components={{
            ...animatedComponents,
            Menu: !isNil(onModal)
              ? (params) => <SelectMenu {...params} onModal={onModal} modalProps={modalProps} />
              : components.Menu,
            Option,
            ...config.components
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              danger: "rgb(248, 52, 52)",
              dangerLight: "rgb(248, 52, 52)",
              primary: "rgb(161, 101, 253, 1)",
              primary25: "rgb(161, 101, 253, 0.08)",
              primary50: "rgb(161, 101, 253, 0.20)",
              primary75: "rgb(161, 101, 253, 0.8)"
            }
          })}
        />
      </div>
    )
  }
)

export default CustomSelect
