import axios from "axios"
import {ApiAbstract} from "src/abstract/api.abstract"
import {ListModel} from "src/models/common"
import {Transfer, TransferAddress, TransferCity, TransferDetail} from "../models/manager/transfer/transfer"

export class TransportationService extends ApiAbstract {
  constructor() {
    super("v1/crm/transfers")
  }
  // transfers
  public async list(params?: any): Promise<ListModel<Transfer>> {
    const response = await axios.get(this.getUrl(), {params})
    return response.data
  }
  public async get(id?: any): Promise<TransferDetail> {
    const response = await axios.get(this.getUrl(id))
    return response.data
  }
  public async post(payload: any): Promise<any> {
    const response = await axios.post(this.getUrl(), payload)
    return response.data
  }
  public async patch(id: number, payload: any): Promise<any> {
    const response = await axios.patch(this.getUrl(id), payload)
    return response.data
  }
  public async delete(id: number): Promise<any> {
    const response = await axios.delete(this.getUrl(id))
    return response.data
  }
  // transfer/addresses
  public async listAddress(params?: any): Promise<ListModel<TransferAddress>> {
    const response = await axios.get(this.getUrl(`addresses`), {params})
    return response.data
  }
  public async getAddress(id?: any): Promise<TransferAddress> {
    const response = await axios.get(this.getUrl(`addresses/${id}`))
    return response.data
  }
  public async postAddress(payload?: any): Promise<TransferAddress> {
    const response = await axios.post(this.getUrl(`addresses`), payload)
    return response.data
  }
  public async putAddress(addressId: number, payload?: any): Promise<ListModel<any>> {
    const response = await axios.put(this.getUrl(`addresses/${addressId}`), payload)
    return response.data
  }
  public async deleteAddress(addressId: number): Promise<any> {
    const response = await axios.delete(this.getUrl(`addresses/${addressId}`))
    return response.data
  }
  // transfer/cities
  public async listCity(): Promise<TransferCity[]> {
    const response = await axios.get(this.getUrl(`cities`))
    return response.data
  }
  public async getCity(cityId?: number): Promise<TransferCity> {
    const response = await axios.get(this.getUrl(`cities/${cityId}`))
    return response.data
  }
  public async postCity(payload?: any): Promise<any> {
    const response = await axios.post(this.getUrl(`cities`), payload)
    return response.data
  }
  public async patchCity(id: number, payload?: any): Promise<any> {
    const response = await axios.patch(this.getUrl(`cities/${id}`), payload)
    return response.data
  }
  public async deleteCity(id: number): Promise<any> {
    const response = await axios.delete(this.getUrl(`cities/${id}`))
    return response.data
  }
  // transfer/id/rides
  public async getRide(id: number, rideId: number): Promise<any> {
    const response = await axios.get(this.getUrl(`${id}/rides/${rideId}`))
    return response.data
  }
  public async postRides(id: number, payload?: any): Promise<any> {
    const response = await axios.post(this.getUrl(`${id}/rides`), payload)
    return response.data
  }
  public async putRides(id: number, rideId: number, payload?: any): Promise<any> {
    const response = await axios.put(this.getUrl(`${id}/rides/${rideId}`), payload)
    return response.data
  }
  public async deleteRides(id: number, rideId: number, payload?: any): Promise<any> {
    const response = await axios.delete(this.getUrl(`${id}/rides/${rideId}`), payload)
    return response.data
  }
}

const transportationService = new TransportationService()
export default transportationService
