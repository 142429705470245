import axios from "axios"
import {BehaviorSubject, Observable, from, of, shareReplay, switchMap} from "rxjs"
import {axiosResponce} from "../interceptor/axios-response"
import authService from "./auth.service"

export interface WorkshiftModel {
  id: number
  start_at: string
  end_at: string | null
  profile: string
  point: string
  point_address: string
  order_count: number
  order_amount: number
  payment_count: number
  payment_amount: number
  order_penalty_count: number
  order_penalty_amount: number
  extra: any
  payments?: Array<{type: number; name: string; count: number; amount: number}>
  services?: Array<{count: number; name: string; price: string; service: number}>
}

class WorkshiftService {
  refreshing: boolean = false

  getWorkshift$ = new BehaviorSubject<void>(null)
  getWorkshift = () => this.getWorkshift$.next()

  workshift$: Observable<WorkshiftModel> = authService.profile$.pipe(
    switchMap((profile) =>
      profile
        ? this.getWorkshift$.pipe(
            switchMap(() => from(axios.get<WorkshiftModel>("/v1/crm/workshift/")).pipe(axiosResponce)),
            shareReplay(1)
          )
        : of(undefined)
    ),
    shareReplay(1)
  )

  public async get(id: number): Promise<WorkshiftModel> {
    const res = await axios.get(`/v1/crm/workshift/${id}/`)
    this.getWorkshift()
    return res.data
  }
  public async start(): Promise<WorkshiftModel> {
    const res = await axios.get("/v1/crm/workshift/start/")
    this.getWorkshift()
    return res.data
  }
  public async end(): Promise<WorkshiftModel> {
    const res = await axios.get("/v1/crm/workshift/end/")
    this.getWorkshift()
    return res.data
  }
}

export default new WorkshiftService()
