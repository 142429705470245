import {InputHTMLAttributes, useEffect, useState} from "react"
import {Controller, RegisterOptions, useFormContext} from "react-hook-form"
import moment from "moment"
import clsx from "clsx"
import InputMask from "react-input-mask"
import {isNil} from "src/utils/isNil"
import {useTranslation} from "react-i18next"

interface FormControlConfig extends InputHTMLAttributes<HTMLInputElement> {
  id?: any
  rootclassname?: string
  name: string
  params?: RegisterOptions<any, any>
  label?: string
  className?: string
}

const formParams = {
  shouldDirty: true,
  shouldTouch: true,
  shouldValidate: true
}

export function FormControlDate(config: FormControlConfig) {
  const {
    watch,
    setValue,
    setError,
    clearErrors,
    formState: {errors}
  } = useFormContext()
  const error = errors[config.name]
  const className = clsx("w-full", config.className, error && "invalid")
  const value = watch(config.name)
  const {t} = useTranslation()
  const [val, setVal] = useState<string>(value ? moment(value, "YYYY-MM-DD").format("DD-MM-YYYY") : "")

  const onChange = (date: string) => {
    if (isNil(date)) {
      setVal(null)
      setValue(config.name, null, formParams)
      clearErrors(config.name)
      return
    }

    if (typeof date === "string" && date.includes("_")) {
      setVal(null)
      setValue(config.name, null, formParams)
      clearErrors(config.name)
      return
    } else if (typeof date === "string") {
      setVal(date)
      const dateMoment = moment(date, "DD-MM-YYYY", true)
      const isValid = dateMoment.isValid()

      if (isValid) {
        setValue(config.name, dateMoment.format("YYYY-MM-DD"), formParams)
        clearErrors(config.name)
      } else {
        setError(config.name, {message: "Неправильный формат даты"})
      }
    }
  }

  useEffect(() => {
    if (value) {
      setVal(moment(value, "YYYY-MM-DD").format("DD-MM-YYYY"))
    }
  }, [value])

  return (
    <div className={clsx("w-full", config.rootclassname)}>
      {config.label && (
        <div className="flex gap-2 mb-2">
          <label className="overflow line-clamp-1 text-[13px] color-gray-400">{config.label}</label>
          {config.required && <div className="form-required"></div>}
        </div>
      )}
      <Controller
        name={config.name}
        rules={config.params}
        render={({fieldState}) => (
          <div className="relative">
            <InputMask
              value={val}
              mask="99-99-9999"
              placeholder={t("common.input.placeholder.format")}
              className={clsx(className, "block", fieldState.error && "border border-alert")}
              disabled={config.disabled}
              onChange={(e) => onChange(e.target.value)}
              autoComplete="off"
            />
            {/* <div className="absolute right-0 top-0 bottom-0 p-4 pl-0 flex items-center justify-center">
              <Icon className="text-xl" icon="calendar_month" />
            </div> */}
            {fieldState.error && <span className="text-alert text-[12px]">{fieldState.error?.message}</span>}
          </div>
        )}
      />
    </div>
  )
}
