import React from "react"
import {LabelModel} from "src/models/label"
import FormSelectAsyncPagination, {FormSelectAsyncConfig} from "../../inputs/form-select-async.pagination"
import driverService from "src/services/driver.service"
import {useTranslation} from "react-i18next"
import {ExtendedModalConfig} from "src/components/modal/global/modal.service"
import DriverForm from "src/pages/transportation/pages/drivers/detail/driver-form"
import usersService from "src/services/users"
import {BehaviorSubject} from "rxjs"

type RemovalKeys = "listOptions" | "getValue" | "getOptionLabel" | "getOptionValue"
const onCreate$: BehaviorSubject<void> = new BehaviorSubject<void>(null)

interface TransferDriverConfig extends Omit<FormSelectAsyncConfig<LabelModel>, RemovalKeys> {
  addOption?: boolean
}

const TransferDriver: React.FC<TransferDriverConfig> = (config) => {
  const {t} = useTranslation()

  const getDriver = async (id: number) => {
    const driver = await driverService.getDriver(+id)
    return driver
  }

  const modalProps: ExtendedModalConfig = {
    text: "New Driver",
    component: <DriverForm onCreate={onCreate$} />
  }

  return (
    <FormSelectAsyncPagination<any>
      placeholder={t("common.select.placeholder.group")}
      {...config}
      modalProps={config.addOption && modalProps}
      getValue={getDriver}
      listOptions={(params) => usersService.list({...params, is_driver: true})}
      getOptionLabel={(option: any) => (option ? `${option.first_name} ${option.last_name}` : null)}
      getOptionValue={(option: any) => (option ? String(option.id) : null)}
      onCreate={onCreate$}
    />
  )
}

export default TransferDriver
