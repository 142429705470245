import {ReactNode} from "react"
import {useForm, FormProvider, DeepPartial} from "react-hook-form"
import clsx from "clsx"
import modalService from "../../components/modal/global/modal.service"

type EditProps<TFormState> = {mode: "edit"; formValues: DeepPartial<TFormState>}
type AddProps = {mode: "add"; formValues?: never}

type CommonProps<TFormState> = {
  children: ReactNode
  onSubmit: (payload: TFormState) => void
  title?: string
  buttonTitle?: string
  buttonsContainer?: string
  cancelBtn?: boolean
}

export function ConfigAddOrEdit<TFormSate>({
  title,
  buttonTitle,
  mode,
  formValues,
  onSubmit,
  children,
  buttonsContainer,
  cancelBtn = false
}: CommonProps<TFormSate> & (EditProps<TFormSate> | AddProps)) {
  const form = useForm<TFormSate>({
    defaultValues: mode === "edit" ? formValues : undefined
  })

  const handleSubmit = async () => {
    if (!(await form.trigger())) return
    const values = form.getValues()
    onSubmit(values)
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="text-xl font-bold">{title}</div>
      <FormProvider {...form}>
        <div className="flex-col flex gap-4">
          {children}
          <div className={clsx("flex gap-2 align-self-end", buttonsContainer)}>
            <button
              onClick={handleSubmit}
              disabled={!form.formState.isValid}
              className="btn btn-primary btn-color-white">
              {buttonTitle}
            </button>
            {cancelBtn && (
              <button onClick={() => modalService.closeModal()} className="btn btn-icon ">
                Cancel
              </button>
            )}
          </div>
        </div>
      </FormProvider>
    </div>
  )
}
