export interface MessageModalConfig {
  title?: string
  message: string
  confirm?: string
  onConfirm?: () => void
}

export const MessageModal = (config: MessageModalConfig) => {
  return (
    <div className="flex gap-4 flex-col">
      {config.title && <div className="flex font-semibold text-lg justify-center">{config.title}</div>}
      {config.message && (
        <div className="flex justify-center" style={{textAlign: "center"}}>
          <span dangerouslySetInnerHTML={{__html: config.message}}></span>
        </div>
      )}
      {config.confirm && config.onConfirm && (
        <button className="btn btn-primary btn-color-white" onClick={config.onConfirm}>
          {config.confirm}
        </button>
      )}
    </div>
  )
}
