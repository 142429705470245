import React, {useContext, useEffect, useState} from "react"
import {FormProvider} from "react-hook-form"
import Table from "src/components/shared/antd-custom/table"
import Icon from "src/components/shared/components/material-icon"
import {FormControl} from "src/components/shared/inputs/form-control"
import PaginationFormComponent from "src/components/shared/pagination/pagination-form"
import {ConfigContext} from "src/app"
import {useTranslation} from "react-i18next"
import metricsExportService from "src/services/metrics/export.service"
import toDate from "src/utils/date"
import {EMPTY_LIST, ListModel} from "src/models/common"
import {Transfer} from "src/models/manager/transfer/transfer"
import metricsService from "src/services/metrics/products-parts.service"
import {useDebounce} from "src/hooks/useDebounce"
import type {ColumnsType} from "antd/es/table"
import toDateTime from "src/utils/date-time"
import {toPrice} from "src/utils/price"
import {InventoryEarningModel} from "src/models/manager/metrics/payback-inventory"
import {useNavigate} from "react-router-dom"

const CarListTableComponent = ({form}: {form: any}) => {
  const [list, setList] = useState<ListModel<InventoryEarningModel>>(EMPTY_LIST)
  const {watch, setValue, reset, handleSubmit} = form
  const values = watch()
  const {isMobile} = useContext(ConfigContext)
  const {t} = useTranslation()
  const searchDebounce = useDebounce(watch("search"), 500)
  const navigate = useNavigate()

  const onExcel = handleSubmit(
    async (params) =>
      await metricsExportService.uploadTransactions(
        `${t("reports.sales.finance.transactions")} (${toDate(params.start_at)} - ${toDate(params.end_at)})`,
        params
      )
  )

  const listPayback = (params: any) =>
    metricsService
      .listInventoryPayback({...params})
      .then(setList)
      .catch((e) => e.response.status === 404 && setValue("page", 1))

  useEffect(() => reset({...values, search: searchDebounce, page: 1}), [searchDebounce])

  useEffect(() => {
    listPayback(values)

    const sub = watch((params: any) => {
      listPayback(params)
    })

    return () => {
      sub.unsubscribe()
      setList(EMPTY_LIST)
    }
  }, [])

  const mobileColumns: ColumnsType<any> = [
    {
      dataIndex: "id",
      key: "id",
      width: "auto",
      render: (_, {id, name, buy_price, created_at}) => {
        return (
          <div className="flex flex-col gap-4" onClick={() => navigate(`/car-info/${id}`)}>
            <div className="flex justify-between items-center gap-4 p-1">
              <div>
                <div className="flex flex-col gap-2 border-gray-200">
                  <div className="flex gap-2 font-medium text-gray-700">
                    <span>№ {id}</span>
                    <span> · </span>
                    <span className="text-gray-800 font-semibold">{name}</span>
                  </div>

                  <span className="font-medium text-gray-400">
                    {created_at && toDateTime(created_at)} · {toPrice(+buy_price)}
                  </span>
                </div>
              </div>

              <Icon icon="chevron_right" />
            </div>
          </div>
        )
      }
    }
  ]

  const columns: ColumnsType<Transfer> = []

  return (
    <FormProvider {...form}>
      <Table
        title={() => (
          <div className="flex md:flex-row flex-col flex-wrap md:items-center items-start md:gap-2 gap-4 justify-between">
            <div className="flex items-center justify-between md:justify-start gap-3 w-full">
              <h4 className="mb-0 md:text-xl text-[18px] font-bold">{t("metrics.finance.transactions.title")}</h4>
              <div className="flex md:hidden gap-2 items-center" onClick={onExcel}>
                <Icon icon="download" className="text-primary text-[22px]" />
                <p className="text-[15px] font-medium text-primary">{t("common.export")}</p>
              </div>
            </div>
            <div className="flex gap-4 w-full md:w-max">
              <FormControl
                icon="search"
                rootclassname="col"
                className="form-control"
                name="search"
                placeholder={t("common.input.search")}
              />
              {isMobile && (
                <div
                  className="min-h-12 min-w-12 rounded-lg border border-gray-200 flex justify-center items-center"
                  onClick={() => {}}>
                  <Icon icon="filter_list" className="h-5 w-6 text-2xl" />
                </div>
              )}
            </div>
          </div>
        )}
        rowKey={(obj) => obj.id}
        columns={isMobile ? mobileColumns : columns}
        dataSource={list.results}
        autoScroll={false}
        showHeader={!isMobile}
        className={isMobile && "!rounded-0 !border-0"}
        rootClassName="w-full"
      />
      <PaginationFormComponent count={list.count} />
    </FormProvider>
  )
}

export default CarListTableComponent
