import React from "react"
import TransferDriver from "src/components/shared/components/select/transfer-driver"
import {useTranslation} from "react-i18next"
import {FormProvider} from "react-hook-form"
import {ClientModel} from "src/models/manager/clients/client.model"
import clientService from "src/services/clients.service"
import FormSelectAsyncPagination from "src/components/shared/inputs/form-select-async.pagination"
import {FormControlDaterange} from "src/components/shared/inputs/form-control-daterange"

const TransferMetricsModal = ({form}: {form: any}) => {
  const {t} = useTranslation()

  const getClient = async (id: number): Promise<ClientModel | null> => {
    const fetchedClient = await clientService.get(id)

    return fetchedClient
  }

  return (
    <FormProvider {...form}>
      <div className=" flex gap-6 flex-col">
        <TransferDriver
          className="col"
          label={t("transfers.main.driver")}
          placeholder={t("transfers.form.choose_driver")}
          name="driver"
          isClearable={true}
          isSearchable={true}
        />

        <FormSelectAsyncPagination<ClientModel>
          name="client"
          listOptions={(params) => clientService.list({...params, skip_loader: true})}
          getValue={getClient}
          filterOption={null}
          isSearchable={true}
          isClearable={false}
          getOptionLabel={(val: ClientModel) => (val ? String(val.name) : null)}
          getOptionValue={(val: ClientModel) => (val ? String(val.id) : null)}
          placeholder={t("common.select.placeholder.client")}
          className="flex-1"
        />

        <FormControlDaterange className="col form-control" name_start="start_at" name_end="end_at" />
      </div>
    </FormProvider>
  )
}

export default TransferMetricsModal
