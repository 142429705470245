import {Accesses, TenantTypeConfigs} from "../mock/functionality-access"

export default function hasAccess(
  accesses: Accesses,
  accessKey: keyof TenantTypeConfigs["accesses"] | string
): boolean {
  const accessPath = accessKey.split(".")

  let currentAccess: Accesses = accesses
  for (const key of accessPath) {
    if (!currentAccess || !(key in currentAccess)) {
      return false
    }
    currentAccess = currentAccess[key]
  }

  return !!currentAccess
}
