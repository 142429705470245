import {lazy} from "react"
import {Navigate, RouteObject} from "react-router-dom"
import CustomSuspense from "src/components/custom-suspense"
import {InventoryGroupInventoriesBulkCreate} from "src/pages/group/edit/inventories-bulk-create"
import SaleFormComponent from "src/pages/sale/edit/form"
import {SaleInventoryList} from "src/pages/sale/edit/inventories/inventories"
import SaleWrapperComponent from "src/pages/sale/wrapper"
import {ProtectedRoute} from "./guards/protected-route"

const InventoryDetailComponent = lazy(() => import("src/pages/inventories/detail/inventory-detail"))
const InventoryGroupInventorizationComponent = lazy(
  () => import("src/pages/group/detail/inventory-group-inventorization")
)
const InventoryInventorizationListComponent = lazy(
  () => import("src/pages/inventories/detail/inventory-inventorization")
)
const InventoryRentListComponent = lazy(() => import("src/pages/inventories/detail/inventory-rents"))
const InventoryTarifListComponent = lazy(() => import("src/pages/inventories/detail/inventory-tarifs"))
const InventoryEditComponent = lazy(() => import("src/pages/inventories/edit/inventory-edit"))
const InventoryGroupDetailComponent = lazy(() => import("src/pages/group/detail/inventory-group"))
const InventoryGroupImagesComponent = lazy(() => import("src/pages/group/detail/inventory-group-images"))
const InventoryGroupInventoriesComponent = lazy(() => import("src/pages/group/detail/inventory-group-inventories"))
const InventoryGroupRentsComponent = lazy(() => import("src/pages/group/detail/inventory-group-rents"))
const InventoryGroupSalesComponent = lazy(() => import("src/pages/group/detail/inventory-group-sales"))
const InventoryGroupEditComponent = lazy(() => import("src/pages/group/edit"))
const InventoryGroupListComponent = lazy(() => import("src/pages/group/list"))
const InventoryGroupTarifsComponent = lazy(() => import("src/pages/group/detail/inventory-group-tarifs"))
const SaleListComponent = lazy(() => import("src/pages/sale/list"))
const SaleHistoryComponent = lazy(() => import("src/pages/sale/history"))
const SaleEditComponent = lazy(() => import("src/pages/sale/edit"))
const ProductSetListComponent = lazy(() => import("src/pages/set/list"))
const ProductSetEditComponent = lazy(() => import("src/pages/set/edit"))

export const inventoryRoutes: RouteObject[] = [
  {
    path: "groups",
    element: (
      <ProtectedRoute
        requiredAccessKey="groups"
        element={
          <CustomSuspense>
            <InventoryGroupListComponent />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "groups/add",
    element: (
      <ProtectedRoute
        requiredAccessKey="groups"
        element={
          <CustomSuspense>
            <InventoryGroupEditComponent />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "groups/:id/edit",
    element: (
      <ProtectedRoute
        requiredAccessKey="groups"
        element={
          <CustomSuspense>
            <InventoryGroupEditComponent />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "groups/:id",
    element: (
      <ProtectedRoute
        requiredAccessKey="groups"
        element={
          <CustomSuspense>
            <InventoryGroupDetailComponent />
          </CustomSuspense>
        }
      />
    ),
    children: [
      {
        path: "",
        element: <Navigate to="inventories" replace={true} />
      },
      {
        path: "inventories",
        element: (
          <CustomSuspense>
            <InventoryGroupInventoriesComponent />
          </CustomSuspense>
        )
      },
      {
        path: "images",
        element: (
          <CustomSuspense>
            <InventoryGroupImagesComponent />
          </CustomSuspense>
        )
      },
      {
        path: "inventorization",
        element: (
          <CustomSuspense>
            <InventoryGroupInventorizationComponent />
          </CustomSuspense>
        )
      },
      {
        path: "rents",
        element: (
          <CustomSuspense>
            <InventoryGroupRentsComponent />
          </CustomSuspense>
        )
      },
      {
        path: "sales",
        element: (
          <CustomSuspense>
            <InventoryGroupSalesComponent />
          </CustomSuspense>
        )
      },
      {
        path: "tarifs",
        element: (
          <CustomSuspense>
            <InventoryGroupTarifsComponent />
          </CustomSuspense>
        )
      }
    ]
  },
  {
    path: "sales",
    element: <ProtectedRoute requiredAccessKey="groups.sales" element={<SaleWrapperComponent />} />,
    children: [
      {
        path: "",
        element: (
          <CustomSuspense>
            <SaleListComponent />
          </CustomSuspense>
        )
      },
      {
        path: "history",
        element: (
          <CustomSuspense>
            <SaleHistoryComponent />
          </CustomSuspense>
        )
      }
    ]
  },
  {
    path: "sales/add",
    element: (
      <ProtectedRoute
        requiredAccessKey="groups.sales"
        element={
          <CustomSuspense>
            <SaleEditComponent />
          </CustomSuspense>
        }
      />
    ),
    children: [
      {path: "", element: <SaleInventoryList />},
      {path: "form", element: <SaleFormComponent />},
      {path: "*", element: <Navigate to="" replace={true} />}
    ]
  },
  {
    path: "sales/:id",
    element: (
      <ProtectedRoute
        requiredAccessKey="groups.sales"
        element={
          <CustomSuspense>
            <SaleEditComponent />
          </CustomSuspense>
        }
      />
    ),
    children: [
      {path: "", element: <SaleInventoryList />},
      {path: "form", element: <SaleFormComponent />},
      {path: "*", element: <Navigate to="" replace={true} />}
    ]
  },
  {
    path: "groups/:id/inventories/add",
    element: (
      <ProtectedRoute
        requiredAccessKey="groups"
        element={
          <CustomSuspense>
            <InventoryGroupInventoriesBulkCreate />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "groups/:id/inventories/:inventoryId/edit",
    element: (
      <ProtectedRoute
        requiredAccessKey="groups"
        element={
          <CustomSuspense>
            <InventoryEditComponent />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "groups/:id/inventories/:inventoryId",
    element: (
      <ProtectedRoute
        requiredAccessKey="groups"
        element={
          <CustomSuspense>
            <InventoryDetailComponent />
          </CustomSuspense>
        }
      />
    ),
    children: [
      {path: "", element: <Navigate to="rents" replace={true} />},
      {
        path: "rents",
        element: (
          <CustomSuspense>
            <InventoryRentListComponent />
          </CustomSuspense>
        )
      },
      {
        path: "tarifs",
        element: (
          <CustomSuspense>
            <InventoryTarifListComponent />
          </CustomSuspense>
        )
      },
      {
        path: "inventorization",
        element: (
          <CustomSuspense>
            <InventoryInventorizationListComponent />
          </CustomSuspense>
        )
      }
    ]
  },
  {
    path: "inventory-sets",
    element: <ProtectedRoute requiredAccessKey="groups.sets" element={<ProductSetListComponent needActions={true} />} />
  },
  {
    path: "inventory-sets/add",
    element: <ProtectedRoute requiredAccessKey="groups.sets" element={<ProductSetEditComponent mode="create" />} />
  },
  {
    path: "inventory-sets/:id",
    element: <ProtectedRoute requiredAccessKey="groups.sets" element={<ProductSetEditComponent mode="edit" />} />
  }
]
