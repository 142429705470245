import {InventoryStateStatus} from "src/models/manager/constants"
import {useTranslation} from "react-i18next"
import {DiscountModel} from "src/models/manager/discount/discount.model"
import {useContext, useMemo} from "react"
import {ConfigContext} from "src/app"
import FormSelect, {FormSelectConfig} from "../../inputs/form-select"
import {useFormContext} from "react-hook-form"
import {CalculationType, DiscountType} from "src/pages/orders/constants"

type RemovalKeys =
  | "listOptions"
  | "getValue"
  | "getOptionLabel"
  | "getOptionValue"
  | "placeholder"
  | "options"
  | "isSearchable"

export function SharedDiscountSelect(
  config: Omit<FormSelectConfig<InventoryStateStatus>, RemovalKeys> & {
    type?: undefined | "discount" | "promocode"
  }
) {
  const form = useFormContext()
  const {t} = useTranslation()
  const {settings, discounts, discountsMap} = useContext(ConfigContext)
  const value = form.watch(config.name)

  const options = useMemo(() => {
    if (config.type === "discount") return discounts.filter((d) => d.type === DiscountType.PERCENT)
    if (config.type === "promocode") return discounts.filter((d) => d.type === DiscountType.PROMOCODE)
    return discounts
  }, [config.type, discounts])

  const getOptionLabel = (option: DiscountModel) => {
    if (!option) return null
    if (!settings) return null
    if (option.discount_type === CalculationType.PRICE) {
      return `${option.name} (${option.discount} ${settings.currency})`
    }
    if (option.discount_type === CalculationType.PERCENTAGE) {
      return `${option.name} (${option.discount}%)`
    }
  }

  return (
    <FormSelect<DiscountModel>
      isSearchable={true}
      {...config}
      placeholder={t("common.select.placeholder.discount")}
      options={options}
      value={discountsMap[+value]}
      getOptionLabel={getOptionLabel}
      getOptionValue={(option: DiscountModel) => (option ? String(option.id) : null)}
    />
  )
}
