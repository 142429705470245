import Icon from "src/components/shared/components/material-icon"
import {useNavigate} from "react-router-dom"
import {useTranslation} from "react-i18next"

const TransferCities = ({cities}) => {
  const navigate = useNavigate()
  const {t} = useTranslation()

  return (
    <div className="m-auto max-w-[720px] flex-col flex gap-0.5">
      {cities.map((city) => (
        <div
          key={city.id}
          className="flex justify-between items-center gap-3 bg-white flex-1 p-4 md:first:rounded-t-lg md:[&:nth-last-child(2)]:rounded-b-lg"
          onClick={() => navigate(`${city.id}`)}>
          <div className="flex flex-col gap-1">
            <div className="font-medium">{city.name}</div>
            <div className="text-primary">
              {city?.address_count
                ? `${city.address_count} ${city.address_count > 1 ? t("transfers.form.directions") : t("transfers.form.direction")}`
                : ""}
            </div>
          </div>
          <div>
            <Icon icon="chevron_right" />
          </div>
        </div>
      ))}
    </div>
  )
}

export default TransferCities
