import React, {useContext} from "react"
import Icon from "src/components/shared/components/material-icon"
import {ConfigContext} from "src/app"
import {useNavigate, useParams} from "react-router"
import {Dropdown, MenuProps} from "antd"
import transportationService from "src/services/transportation.service"
import {useFormContext} from "react-hook-form"
import {GroupType} from "src/services/auth.service"
import modalService from "src/components/modal/global/modal.service"
import {ConfirmModal} from "src/components/modal/global/confirmModal"
import {useTranslation} from "react-i18next"

interface TransportationHeaderProps {
  onSubmit: () => void
}
const TransportationHeader = ({onSubmit}: TransportationHeaderProps) => {
  const {isMobile, profile} = useContext(ConfigContext)
  const {id} = useParams()
  const {t} = useTranslation()
  const form = useFormContext()
  const isAdmin = profile && profile.group_type === GroupType.ADMIN
  const isValid = isAdmin ? form.watch("client") && form.watch("driver") : form.watch("client")

  const navigate = useNavigate()

  const onDelete = () => {
    const onConfirm = () => transportationService.delete(+id).then(() => navigate("../"))

    modalService.open({
      component: (
        <ConfirmModal
          message={t("transfers.action.are_you_sure_delete")}
          confirm_text={t("transfers.action.yes_delete")}
          onConfirm={onConfirm}
          delete={true}
        />
      )
    })
  }

  const items: MenuProps["items"] = [
    {
      key: "delete",
      onClick: onDelete,
      label: (
        <div className="flex items-center gap-2">
          <Icon className="text-alert" icon="delete" /> <span>{t("common.delete")}</span>
        </div>
      )
    }
  ]
  return (
    <div className="flex flex-col justify-between header-content p-0">
      <div className="flex items-center justify-between gap-2 w-full px-4 py-3">
        {isMobile && <Icon icon="chevron_left" className="text-2xl" onClick={() => navigate(-1)} />}
        <div className="text-lg font-semibold md:text-2xl">{id ? `№ ${id}` : t("transfers.statuses.new_order")}</div>
        {isMobile ? (
          <Dropdown menu={{items}} placement="bottomLeft" trigger={["click", "click"]} arrow={false}>
            <Icon icon="more_horiz" />
          </Dropdown>
        ) : (
          <div className="flex gap-4 flex-row w-full md:w-auto px-4 py-2">
            <button
              disabled={!isValid}
              className="btn btn-primary btn-color-white text-base font-medium leading-5"
              onClick={onSubmit}>
              {t("common.save")}
            </button>
            <button className="btn btn-red btn-color-white gap-2" onClick={onDelete}>
              <Icon icon="delete" /> <span>{t("common.delete")}</span>
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default TransportationHeader
