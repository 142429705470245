import {createContext, useEffect, useState} from "react"
import {useParams} from "react-router"
import {Drivers, MetricsUserDriver} from "src/models/manager/transfer/drivers"
import driverService from "src/services/driver.service"
import {useCurrentRoute} from "src/hooks/useCurrentRoute"
import metricsService from "src/services/metrics/products-parts.service"
import {ListModel} from "src/models/common"

export interface DriverContextType {
  driver: Drivers
  driverId: string
  activeRoute: string
  getDriver: () => void
  metricDriver: MetricsUserDriver
  getMetricDriver: () => void
  getDriverDetail: (params: any) => Promise<ListModel<any>>
  getDriverSummary: () => Promise<{
    ride_amount: number
    ride_count: number
    ride_duration: string
    ride_duration_avg: string
    transfer_count: number
  }>
}

export const DriverContext = createContext<DriverContextType>(undefined)

const DriverProvider = ({children}) => {
  const {driverId} = useParams<{driverId: string}>()
  const [driver, setDriver] = useState<Drivers>()
  const [metricDriver, setMetricDriver] = useState<MetricsUserDriver>()
  const activeRoute = useCurrentRoute()
  function getDriver() {
    driverService.getDriver(+driverId).then(setDriver)
  }

  function getMetricDriver() {
    metricsService.getDriverDetail(+driverId).then(setMetricDriver)
  }

  function getDriverDetail(params: any): Promise<ListModel<any>> {
    return metricsService.listRidesList({driver__user: driverId, ...params})
  }

  function getDriverSummary() {
    return metricsService.listRidesList({driver__user: driverId}).then((response) => ({
      ride_amount: response.ride_amount,
      ride_count: response.ride_count,
      ride_duration: response.ride_duration,
      ride_duration_avg: response.ride_duration_avg,
      transfer_count: response.transfer_count
    }))
  }

  useEffect(() => {
    getDriver()

    return () => setDriver(undefined)
  }, [driverId])

  return (
    <DriverContext.Provider
      value={{
        driver,
        driverId,
        activeRoute,
        getDriver,
        metricDriver,
        getMetricDriver,
        getDriverDetail,
        getDriverSummary
      }}>
      {driver && children}
    </DriverContext.Provider>
  )
}
export default DriverProvider
