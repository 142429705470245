import {lazy} from "react"
import {Navigate, Outlet, RouteObject} from "react-router-dom"
import CustomSuspense from "src/components/custom-suspense"
import DeliveryWrapperComponent from "src/pages/deliveries/list"
import DeliveryHistoryComponent from "src/pages/deliveries/list/history"
import DeliveryListComponent from "src/pages/deliveries/list/list"
import {OrderDetailProvider} from "src/pages/orders/details/context/order-context"
import OrderListTabFilterComponent from "src/pages/orders/list/order-tab.filter"
import OrderInventoryAddList from "../pages/orders/details/inventories/inventory-list/add"
import ProductSetList from "../pages/set/list"
import OrderServicesAddModal from "../pages/orders/details/services/add"
import StateCheckComponent from "../pages/orders/details/order-media/state-check"
import {OrderInventorizationRouteWrapper} from "../pages/orders/details/side-menu/inventorization-modal"
import {DeliveryDetailComponent} from "src/pages/deliveries/detail"
import {DeliveryDetailMainComponent} from "src/pages/deliveries/detail/main"
import {DeliveryCheckoutComponent} from "src/pages/deliveries/detail/checkout"

import OrderHeaderComponent from "src/pages/orders/list/orders.header"
import OrderListProviderComponent from "src/pages/orders/list/orders.context"
import {ProtectedRoute} from "./guards/protected-route"

const DocumentTemplateEntity = lazy(() => import("src/pages/config/documents/entity"))
const DocumentTemplateGeneration = lazy(() => import("src/pages/config/documents/generation"))
const OrderDetailGroupList = lazy(() => import("src/pages/orders/details/inventories/inventory-list/group-list"))
const OrderDetailSetList = lazy(() => import("src/pages/orders/details/inventories/inventory-set-list/list"))
const OrderDetailComponent = lazy(() => import("src/pages/orders/details/order"))
const OrderDetailServiceList = lazy(() => import("src/pages/orders/details/services/services"))
const OrderListComponent = lazy(() => import("src/pages/orders/list/orders"))

const filterRequest = {status: 0, grant: false}
const filterReserve = {status: 1}
const filterInrent = {status: 2}
const filterCompleted = {status: 4, time_exceed: false}
const filterExpired = {time_exceed: true}
const filterDebtor = {status: 5, payment_status__in: "0,2", is_debtor: true}
const filterDeleted = {deleted: true}
const filterCancelled = {status: 3}

const orders: RouteObject = {
  path: "orders",
  element: (
    <ProtectedRoute
      requiredAccessKey="orders"
      element={
        <OrderListProviderComponent>
          <CustomSuspense>
            <OrderListComponent header={<OrderHeaderComponent />} />
          </CustomSuspense>
        </OrderListProviderComponent>
      }
    />
  ),
  children: [
    {
      path: "",
      element: <Navigate to="all" relative="route" replace={false} />
    },
    {
      path: "all",
      element: <OrderListTabFilterComponent filter={{}} />
    },
    {
      path: "requests",
      element: <OrderListTabFilterComponent filter={filterRequest} />
    },
    {
      path: "reserved",
      element: <OrderListTabFilterComponent filter={filterReserve} />
    },
    {
      path: "inrent",
      element: <OrderListTabFilterComponent filter={filterInrent} />
    },
    {
      path: "completed",
      element: <OrderListTabFilterComponent filter={filterCompleted} />
    },
    {
      path: "expired",
      element: <OrderListTabFilterComponent filter={filterExpired} />
    },
    {
      path: "debtor",
      element: <OrderListTabFilterComponent filter={filterDebtor} />
    },
    {
      path: "canceled",
      element: <OrderListTabFilterComponent filter={filterCancelled} />
    },
    {
      path: "deleted",
      element: <OrderListTabFilterComponent filter={filterDeleted} />
    }
  ]
}

const plus_orders: RouteObject = {
  path: "plus_orders",
  element: (
    <ProtectedRoute
      requiredAccessKey="orders"
      element={
        <OrderListProviderComponent>
          <CustomSuspense>
            <OrderListComponent type="yume plus" />
          </CustomSuspense>
        </OrderListProviderComponent>
      }
    />
  ),
  children: orders.children
}

export const orderRoutes: RouteObject[] = [
  orders,
  plus_orders,
  {
    path: "orders/:id",
    element: (
      <ProtectedRoute
        requiredAccessKey="orders"
        element={
          <OrderDetailProvider>
            <Outlet />
          </OrderDetailProvider>
        }
      />
    ),
    children: [
      {
        path: "",
        element: (
          <CustomSuspense>
            <OrderDetailComponent />
          </CustomSuspense>
        ),
        children: [
          {path: "", element: <Navigate to="all" replace={false} />},
          {
            path: "all",
            element: (
              <>
                <CustomSuspense>
                  <OrderDetailGroupList />
                </CustomSuspense>
                <CustomSuspense>
                  <OrderDetailSetList />
                </CustomSuspense>
                <CustomSuspense>
                  <OrderDetailServiceList />
                </CustomSuspense>
              </>
            )
          },
          {
            path: "inventories",
            element: (
              <CustomSuspense>
                <OrderDetailGroupList />
              </CustomSuspense>
            )
          },
          {
            path: "sets",
            element: (
              <CustomSuspense>
                <OrderDetailSetList />
              </CustomSuspense>
            )
          },
          {
            path: "services",
            element: (
              <CustomSuspense>
                <OrderDetailServiceList />
              </CustomSuspense>
            )
          }
        ]
      },
      {
        path: "add_product",
        element: <OrderInventoryAddList />
      },
      {
        path: "add_set",
        element: <ProductSetList />
      },
      {
        path: "add_service",
        element: <OrderServicesAddModal />
      },
      {
        path: "media",
        element: <StateCheckComponent />
      },
      {
        path: "media/:mediaId",
        element: <StateCheckComponent />
      },
      {
        path: "inventorization",
        element: <OrderInventorizationRouteWrapper />
      }
    ]
  },
  {
    path: "deliveries",
    element: <DeliveryWrapperComponent />,
    children: [
      {path: "", element: <Navigate to="pending" replace={false} />},
      {path: "pending", element: <DeliveryListComponent />},
      {path: "inprocess", element: <DeliveryListComponent />},
      {path: "done", element: <DeliveryHistoryComponent />}
    ]
  },
  {
    path: "orders/:orderId/deliveries/:id",
    element: <DeliveryDetailComponent />,
    children: [
      {path: "", element: <DeliveryDetailMainComponent />},
      {path: "checkout", element: <DeliveryCheckoutComponent />}
    ]
  },
  {
    path: "documents/:id",
    element: (
      <CustomSuspense>
        <DocumentTemplateGeneration />
      </CustomSuspense>
    )
  },
  {
    path: "documents/:id/entity/:documentId",
    element: (
      <CustomSuspense>
        <DocumentTemplateEntity />
      </CustomSuspense>
    )
  }
]
